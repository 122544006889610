/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * logitech.com
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */

@font-face {
    font-family: "Brown-Pro-Italic";
    src:url("/asset/fonts/lineto-brown-pro-Italic.eot");
    src:url("/asset/fonts/lineto-brown-pro-Italic.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-Italic.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-Italic.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-Italic.svg#lineto-brown-pro-Italic") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Bold";
    src:url("/asset/fonts/lineto-brown-pro-bold.eot");
    src:url("/asset/fonts/lineto-brown-pro-bold.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-bold.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-bold.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-bold.svg#lineto-brown-pro-bold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Thinitalic";
    src:url("/asset/fonts/lineto-brown-pro-thinItalic.eot");
    src:url("/asset/fonts/lineto-brown-pro-thinItalic.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-thinItalic.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-thinItalic.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-thinItalic.svg#lineto-brown-pro-thinItalic") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Bolditalicalt";
    src:url("/asset/fonts/lineto-brown-pro-bolditalicalt.eot");
    src:url("/asset/fonts/lineto-brown-pro-bolditalicalt.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-bolditalicalt.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-bolditalicalt.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-bolditalicalt.svg#lineto-brown-pro-bolditalicalt") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Lightitalic";
    src:url("/asset/fonts/lineto-brown-pro-lightItalic.eot");
    src:url("/asset/fonts/lineto-brown-pro-lightItalic.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-lightItalic.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-lightItalic.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-lightItalic.svg#lineto-brown-pro-lightItalic") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Thin";
    src:url("/asset/fonts/lineto-brown-pro-thin.eot");
    src:url("/asset/fonts/lineto-brown-pro-thin.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-thin.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-thin.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-thin.svg#lineto-brown-pro-thin") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Light";
    src:url("/asset/fonts/lineto-brown-pro-light.eot");
    src:url("/asset/fonts/lineto-brown-pro-light.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-light.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-light.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-light.svg#lineto-brown-pro-light") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Brown-Pro-Regular";
    src:url("/asset/fonts/lineto-brown-pro-regular.eot");
    src:url("/asset/fonts/lineto-brown-pro-regular.eot?#iefix") format("embedded-opentype"),
        url("/asset/fonts/lineto-brown-pro-regular.woff") format("woff"),
        url("/asset/fonts/lineto-brown-pro-regular.ttf") format("truetype"),
        url("/asset/fonts/lineto-brown-pro-regular.svg#lineto-brown-pro-regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

// Global Typography
@mixin headline-uppercase {
  @include points("font-size", 36);
  font-family: "Brown-Pro-Bold";
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin label-small-uppercase {
  @include points("font-size", 12);
  font-family: "Brown-Pro-Bold";
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin body-copy {
  @include points("font-size", 16);
  font-family: "Helvetica", Arial, sans-serif;
  line-height: 26px;
  //letter-spacing: 1px;
}

@mixin styled-copy {
  @include points("font-size", 16);
  font-family: $font-family-sans-serif;
  line-height: 1.42857;
}

@mixin styled-global {
  @include points("font-size", 16);
  font-family: $font-family-sans-serif;
  line-height: 1.42857;
  font-size:.975em;
}
