
.text-opens-vidplayer {		// * div contains text. click to open and play video.

}

.has-more-content,
.image-opens-vidplayer {	// * div contains video still image. click to open and play video.
	position:relative;
	display:block;
	cursor: pointer;
	outline: none;

	&:before {		// * play arrow/triangle icon.
		content: " ";
		display: block;
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
		border-left: 12px solid $black;
		z-index: 1;
		padding: 0;
		margin: -8px 0 0 -5px;
		position: absolute;
		top: 50%;
		left: 50%;
	}
	&:after {		// * play arrow/triangle icon.
		content: "";
		display: block;
		width: 46px;
		height: 46px;
		background-color: $white;
		border-radius: 50%;
		margin: -23px 0 0 -23px;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transition(background-color 500ms ease-in-out);
	}

	.no-touch & {	// * hover effect for desktop browser.
		&:hover {
			&:after {
				background-color: $yellow;
				@include transition(background-color 500ms ease-in-out);
			}
		}
	}

	&.square {
		&:before {

		}
		&:after {
			border-radius:0;
			width:86px;
			height:86px;
			margin: -43px 0 0 -43px;
		}
	}

	&.invert {
		&:before {
			border-left-color:$white;
			@include transition(border-color 500ms ease-in-out);
		}
		&:after {
			background-color: rgba($yellow,0.8);

		}

		&:hover {
			&:before {
				border-left-color:$black;
			}

			&:after {
				background-color: rgba($yellow,0.9);

			}
		}


	}

	@media (min-width: $screen-lg-min) {
		&:before {
			border-top: 11px solid transparent;
			border-bottom: 11px solid transparent;
			border-left: 15px solid $black;
			margin: -10px 0 0 -5px;
		}
		&:after {
			width: 60px;
			height: 60px;
			margin: -30px 0 0 -30px;
		}


	}
}

.modal.modal-video {	// * bootstrap modal to play video in lightbox.
	// .close {
	// 	margin-top: -20px;	// override _modal.scss
	// }
	height:100%;
	.modal-dialog {

		&.no-center {
			top:30px;
			-webkit-transform: translateY(0%);
			-ms-transform: translateY(0%);
			transform: translateY(0%);
		}

		@media (min-width: $screen-sm-min) {
			width: 740px;
			margin: 30px auto;	// override _modal.scss
		}
		@media (min-width: $screen-md-min) {
			width: 960px;
			padding: 0;
			margin: 0 auto;
			position: relative;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
		@media (min-width: $screen-lg-min) {
			width:80%;
			//padding: 0;
		}




	}

	button.close {
		span {
			font-size:32px;
			color:$yellow;
			font-family: "Brown-Pro-Thin";
		}
	}

	.modal-content {
		padding:0;
		// max-width: 1240px;
	}
	.modal-body {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 30px; //25px;
		height: 0;
		background-color:#000000;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
