$circle-shelf-height: 792px;
.circle-security-color {
	background-color: $light-gray;
	&#subnav{
		.links > li > a {
			color: black;
		}
	}

}
@mixin circle-bg-layout{
		div.background img {
			width: 100%;
			margin: 0 auto;
		}
		div.foreground {
			position: absolute;
		    width: 100%;
		    height: 100%;
		    z-index: 2;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		}
}
@mixin circle-center-layout {
		.container, .row-fluid, .row-fluid > div, .content {
			height: 100%;
		}
		.row-fluid div[class*="col-"] {
			display: table;
		}
		.content {
			display: table-cell;
			vertical-align: middle;
		}
}
body.circle-security.circle-landing {
	.search-widget {
		border: 1px solid #C3C6C8;
	}
	#mobile-nav:not(.open) #nav-icon-mobile .hamburger-icon rect,
	.search-icon path,
	.close-icon path,
	.account-icon path,
	.utility-nav .cart-icon polygon, .utility-nav .cart-icon circle,
	.hamburger-icon rect {
		fill: #2F3132 !important;
	}
	#mobile-nav.open #nav-icon-mobile .hamburger-icon rect{
		fill: #FFFFFF !important;
	}
	.close-icon line {
		stroke: #2F3132 !important;
	}
	.animating.logo-ani {
		background: url("/images/log-logo-sprite-r-black.png") top center;
	}
	.animating.logo-ani.stoppedAnimation {
		background-position: 0 -1440px;
	}
	h1.alt {
		font-size: 56px;
		line-height: 50px;
		padding-bottom: 15px;
		letter-spacing: -4px;
	}
	h1.alt + p {
		font-family: "Brown-Pro-Regular";
		font-size:  1.125em;
	}
	main {
		height: initial;
		background-color: $light-gray;
	}
	.top-panel {
		z-index: 1990;
		margin-top: 0;
		&.lock-nav-bar {
			padding-top:0;
		}
	}
	
	
	main a.action span.dot {
		width: 50px;
		height: 50px;
		line-height: 48px;
	}
	/* Module 1 section*/
	
	#circle-shelfs {
		position: relative;
		
		a.action span.glyphicon + span:after {
			    content: " ";
			    position: relative;
			    padding: 15px 0 15px;
			    border-right: 1px solid rgba(128, 128, 128, 0.65);
			    margin: 0 20px;
		}
		
		a.action {
			display: inline;
		}
		.anchor-buy-now {
			position: relative;
			line-height: 0;
			
			svg {
				vertical-align: middle;
			}
			span + span + span {
				position: absolute;
				left: 60px;
				bottom: -12px;
				text-transform: none;
				display: block;	
				width: 250px;	
				pointer-events: none;
				cursor: default;
				color: #000;
			}
		}
		
		.background {
			min-height: $circle-shelf-height;

			background-color: $light-gray;
			.left {
				  background: url(/images/circle/circle-shelf-security-left.png) left center no-repeat;
				  position: relative;
				  z-index: 1;
				  display: inline-block;
				  height: $circle-shelf-height;
			}
			.center {
				  background-image: url(/images/circle/circle-shelf-security-center.png);
				  background-position: right center;
				  background-repeat: no-repeat;
				  position: relative;
				  z-index: 1;
				  display: inline-block;
				  height: $circle-shelf-height*0.7;
				  	@media (max-width: 767px) {
						background-position: top center;
					}
			}
			.right {
				  background-image: url(/images/circle/circle-shelf-security-right.png);
				  background-position: right center;
				  background-repeat: no-repeat;
				  position: relative;
				  z-index: 1;
				  display: inline-block;
				  height: $circle-shelf-height;
				  @media (max-width: 1440px) {
					background-position: 0 center;
			      }
			      @media (max-width: 1400px) {
			      	background-position: 0 center;
			      }
			      @media (max-width: 1366px) {
			      	background-position: 0 center;
			      }
			      @media (max-width: 1259px) {
			      	display: none;
			      }
			}
		}
		
		.container {
			position: relative;	
		}
		
		@include circle-bg-layout;
		@include circle-center-layout;
	}
	#circle-get-to-know {
		min-height: 0;
		.carousel {
			min-height: 470px;
		}
		h2 {
			color: black;
		}
		.slide {			
		
			div.info {
				width: auto;
				text-align: initial;
				a {
					position: relative;
					color: black;
					text-align: left;
					display: inline-block;
					width: 270px;
					padding-left: 5px;
					cursor: default;
					pointer-events: none;
					h3 {
						font-size: 1.125em;
						padding-bottom: 10px;
					}
					p {
						line-height: 18px;
						font-size: 16px;
						font-family: "Brown-Pro-Regular";
					}
				}
				.circle-sprite {
					display: inline-block;
					margin: 10px 0 0 0; 
					vertical-align: top;   
				}				
			}
		
			&.slide-left:nth-of-type(1) .info,
			&.slide-right:nth-of-type(4) .info{
				top: 100px;
			}
			&.slide-left:nth-of-type(2) .info,
			&.slide-right:nth-of-type(5) .info{
				top: 233.33px	
			}
			&.slide-left:nth-of-type(3) .info,
			&.slide-right:nth-of-type(6) .info{
				top: 366.66px;	
			}
			&.slide-left .info{
				left: 50px;
			}
			&.slide-right .info{
				right: 30px;
				left: initial;
			}
			&.real-time {
				.icon {
					background: url(/images/circle/get-to-know-circle-icons.png) no-repeat;
				}
			}
			&.intel-software {
				.icon {
					background: url(/images/circle/get-to-know-circle-icons.png) no-repeat;
				}
			}
			&.thirty-sec-summaries {
				.icon {
					background: url(/images/circle/get-to-know-circle-icons.png) no-repeat;
				}
			}
			&.two-way-communication {
				.icon {
					background: url(/images/circle/get-to-know-circle-icons.png) no-repeat;
				}
			}
			&.auto-night-vision {
				.icon {
					background: url(/images/circle/get-to-know-circle-icons.png) no-repeat;
				}
			}
			&.portable-rechargeable {
				.icon {
					background: url(/images/circle/get-to-know-circle-icons.png) no-repeat;
				}
			}
		}
	}
	#subnav.black {
		.links > li > a {
			color: #444;
		}
	}
	#product-hero {
		.product-hero-container {
			    background: #814efa;
		}
	}
	#circle-get-started {
		min-height: 692px;
		background-color:$light-gray;
		h4 {
			font-weight: bold;
			font-size: 25px;
			line-height: 25px;
		}
		p {
			font-size: 16px;
			margin-bottom: 40px;
			font-family: "Brown-Pro-Bold";
		}
		div.background img{
			width: auto;
			float: right;
			margin: 0;
		}
		ul{
			list-style-position: outside;
			 li {
				line-height: 16px;
				padding: 10px 0;
				font-family: "Brown-pro-light";
			}
		}
	}
	#circle-pdp-alt {
		position: relative;
		@include circle-bg-layout;
		color: white;
		@include circle-center-layout;
		.price {
			line-height: 42px;
			font-size: 32px;
		}
		p {
			padding: 20px 0;
		}

	}
	/*<=992*/
	@media (max-width: $screen-sm-max) {
		#circle-shelfs {
			.content {
				
			}
			.background {
				.left {
				
				}
				.center {
				
				}
				.right {
				
				}
			}
			
		}
		#circle-get-to-know {
			.slide div.info {
				text-align: center;
			}
			.circle-sprite {
				vertical-align: initial !important;
			}
		}
		
		#circle-get-started {
			min-height: 400px;
			div.foreground {
				padding: 85px 0;
			}
		} 
	}
	/*<= 1260*/
	@media (max-width: $screen-lg-min) {
		 .top-panel{
		 	margin-top: 0;
		 	&.lock-nav-bar {
		 		padding-top: 0;
		 	}
		 }
		 
	}
	@media (max-width: 1400px) {
		#circle-shelfs .background .left {
			visibility: hidden;
		}
	}
	@media (max-width: 767px) {
		#circle-shelfs {
			.content {
				vertical-align: bottom;
				padding-bottom: 20px;
			}
			.background {
				min-height: 692px;
			}
		}
		#circle-get-to-know {
			.carousel {
				min-height: 0;
			}
		}
		
		#circle-get-started{
			min-height: 0;
			div.background {
				position: relative;
				
				img {
					float: none;
					position: static;
					width: 100%;
					right: 0;				
				}
			}
		}
		
		#circle-get-started {
			div.foreground {
				padding: 30px 0;
			}
		} 
		
		    
	}
  @media (max-width: 600px) {
		#circle-shelfs {
	 		.background {
				min-height: 792px;
			}
	 	}
	}
   @media (max-width: 520px) {
	   	#circle-shelfs {
	   		.background {
				min-height: 892px;
			}
	   	 a.action span.glyphicon + span:after{
	   	 	display: none;
	   	 }
	   	 .anchor-buy-now {
	   	 	display: block;
	   	 	padding: 25px 0;
	   	 	span + span + span {
	   	 		top:70px;
	   	 		width: initial;
	   	 	}
	   	 } 
	   	} 	 
	   	 
	}
	 @media (max-width: 400px) {
	    h2.alt {
   			font-size: 3.35em;
   		}
	 }
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:landscape){
			#circle-shelfs {			
				.background {
					min-height: 627px;
					.left {
						display: none;
					}
					.center.col-sm-10 {
						width: 100%;
					}
				}
			}
			 #circle-get-to-know{
			 	.slide.slide-left .info {
			 		left: 0;
			 	}
			 	.slide.slide-right .info {
			 		right: 0;
			 	}
			 } 
			#circle-get-started {

				div.background {
					img{
						   width: 1400px;
						   float: none;
					}
				}
				.foreground .col-md-7 {
					  width: 50%;
				}
				a {
					margin-top: 25px;
				}
			}
	}
	
	@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
		#circle-shelfs {
				.background {
				min-height: 692px;
					.left {
						display: none;
					}
					.center.col-sm-10 {
						width: 100%;
					}
				}
			}
			
			
		#circle-get-started {				
			.foreground .col-sm-5 {
				width: 80%;
			}
		}
	}
	
	@media only screen and (min-device-width: 320px) 
	and (max-device-height: 568px) 
	and (orientation: landscape) 
	and (-webkit-device-pixel-ratio: 2)  {
		 #circle-shelfs{
		 	  .background {
		 	  	min-height: 692px;
		 	  }
		}
	}
	@media only screen and (min-device-width: 320px) 
	and (max-device-height: 568px) 
	and (orientation: portrait) 
	and (-webkit-device-pixel-ratio: 2) {
	
	}
}
/*circle-sprite*/
.circle-sprite {
    background-image: url(/images/circle/circle_security_main_sprite.png);
    background-repeat: no-repeat;
    display: block;
}

.auto-night-black {
    width: 56px;
    height: 56px;
    background-position: -5px -5px;
}

.auto-night-white {
    width: 56px;
    height: 56px;
    background-position: -71px -5px;
}

.daily-briefs-black {
    width: 56px;
    height: 55px;
    background-position: -137px -5px;
}

.daily-briefs-white {
    width: 56px;
    height: 55px;
    background-position: -137px -70px;
}

.portable-black {
    width: 57px;
    height: 57px;
    background-position: -5px -135px;
}

.portable-white {
    width: 57px;
    height: 57px;
    background-position: -72px -135px;
}

.real-time-black {
    width: 56px;
    height: 55px;
    background-position: -139px -135px;
}

.real-time-white {
    width: 56px;
    height: 55px;
    background-position: -203px -5px;
}

.scene-intution-black {
    width: 56px;
    height: 55px;
    background-position: -203px -70px;
}

.scene-intution-white {
    width: 56px;
    height: 55px;
    background-position: -205px -135px;
}

.two-way-black {
    width: 56px;
    height: 56px;
    background-position: -139px -200px;
}

.two-way-white {
    width: 56px;
    height: 56px;
    background-position: -205px -200px;
}
