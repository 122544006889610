.promo-products {
  margin: 23px auto 33px;
  // Overrides
  .owl-carousel {
    display:block;
  }


  .owl-theme .owl-dots span,
  .owl-theme .owl-dots .owl-dot:hover span {
    border: 2px solid $gray;
    background: $white;
  }
  .owl-theme .owl-dots .owl-dot.active span {
    border: 2px solid $black;
    background: $black;
  }
  .owl-theme .owl-dots .owl-dot:hover span {
    .no-touch & {
      border: 2px solid $black;
      background: $black;
    }
  }

  .owl-carousel a {
    display: inline-block;
  }

  // incase this issue gets resolved
  // https://github.com/smashingboxes/OwlCarousel2/issues/892
  .owl-theme .owl-controls {
    margin-top: 0;
  }
  .owl-theme .owl-dots {
    margin-top: 30px;
    text-align: center;
  }
  // End Overrides
  h3 {
    margin-bottom: 25px;
   // font-size: 1.625em;
    text-align:center;
    text-transform: uppercase;
  }
  .promo-tile {
    padding-left: 0;
    padding-right: 0;
  }
  @media (min-width: $screen-sm-min) {
    margin: 0 auto 90px;
    h3 {
      //font-size: 2em;
    }
  }
  @media (max-width: $screen-sm-max) {
    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
