#circle-phone {
		padding: 0;
		overflow: hidden;
		position: relative;
		height: calc(100% - 80px);
		background-color: #F1F5F8;
		video::-webkit-media-controls {
		    display:none !important;
		}
		a.play {
			display: none;
		}
		.mobile {
			display: none;
		}
		div.phone-video {
			margin-top:10px;
			.video-wrapper {
				width: 533px;
			    height: 229px;
			    top: 39%;
			    
			    video {
			    	width: 533px;
			    	height: 301px;
			    }
			}
		}
		h2{
			color: black;
			
			& + p {
				margin:0;
			}
		}
		/*div.phone-video img.phone {
		    width: 655px;
    		height: 317px;
		}*/
		.background {
			position: absolute;
			width: 100%;
			height: 100%;
			.circle-phone-back {
				background: url(http://www.logitech.com/assets/64228/11/circle-pdp-2016.jpg) center center no-repeat;	
				background-size: cover;
    			height: 100%;	
			}	
	
		}
		.container {
			position: relative;
			top: 50%;
			-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		    transform: translateY(-50%);
		    text-align: center;
			
			.content {
				padding: 30px 20px 20px 20px;
				p {
					color: black;
				}
			}
			
			div.phone-video {
				float: none;

				video.no-mask {
					opacity: 1;
				}
				
				video.live-hd,
				video.smart-filters,
				video.daily-summary,
				video.two-way,
				video.night-vision,
				video.download-share {
					display: none;
					&.active {
					display: block;
					}
				}
				.video-wrapper {
					&.pause{
						.video-overlay {						
	  					  opacity: 0.5;
	  					  
						}
					}
				}

				.video-overlay {
		    		width: 533px;
		    		height: 301px;
				    position: absolute;
				    top: 0;
				    background-color: rgb(24, 25, 25);
				    z-index: 2;
				     opacity: 0;
				     transition: opacity 1s ease-in-out; 
				     @media (max-width: 991px){
				     	width: 100%;
				     	height: 100%;
				     }
				}
				

			}
			.legend-notes{
				position: relative;	
				text-align: center;
				width: 90%;
				display: inline-block;
				&:before {
					content: "";
					width: 100%;
					border-top: 1px solid #d9dadc;
					position: absolute;
					height: 1px;
					height:100%;
					transform: translateY(50%);
					z-index: 1;
					left: 0;
				}			
				.legend{
					position: relative;
					font-size: 16px;
					text-align: center;
					padding: 0 20px;
					margin: 0;
					display: none;
					transition: 1s ease-in-out left;
					z-index: 2;
					background-image: none !important;
					&.active {
						display: inline-block;
						left: 0;
						span {
							background-color: #F1F5F8;
							padding: 0 10px;
							display: inline-block;
						}
						@media (max-width: 1024px){
							width: 90%;
						}
					}				
				} 
			}				

		 @media (max-width: 768px) {
		 	top: 0;
		 	-webkit-transform: none;
		    -ms-transform: none;
		    transform: none;
		 }
			
		}
		div.features.ie10 {
			display: block;
			.col-sm-6 {
				width: (100% / 6);
			}
		}
/* 		div.features > div {
			border-left: 1px solid #d9dadc;
			padding: 0;
			background-color: white;
		}
		div.features > div:first-child {
			border-left: none;
		}
		div.features .feature{
		
			height: 75px;
			float: none;
			padding: 0;
			
			div.info {
				padding: 5px;
				display: table;
			    width: 100%;
			    height: 100%;
				h3 {
					text-transform: capitalize;
				}
			}
			dev notes language alert	
			h3 {
			    display: table-cell;
   				 text-align: center;
   				 vertical-align: middle;
				margin-top: 0;
			}
			&.on {
				background-color: $teal;
				color: black;
				position: relative;
				
				&:after {
					bottom: 100%;
					left: 50%;
					border: solid transparent;
					content: " ";
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
				    border-color: transparent transparent $teal transparent;
					border-width: 15px;
					margin-left: -15px;
				}
			}
		} */
		div.features {
			& > div {
				text-align: center;
			}
			a.feature {
				text-align: center;
				display: inline-block;
				float: none;
				
				div:first-child {
					width: 65px;
					height: 65px;
					border-radius: 100%;
					background-color: white;
					position: relative;
					margin:auto;
				
					.icon {
						top: 0;
					    bottom: 0;
					    left: 0;
					    right: 0;
					    margin: auto;	
					    position: absolute;				   
					}
				}
				
				div.info {
					display: inline-block;
					padding: 20px 0;
					h3 {
						text-transform: none;
						color: black;
						font-family: "Brown-Pro-Regular";
					}
				}
				
				div:first-child:hover {
					@extend %circle-get-toknow;
				}
				 &.on {
				 	> div:first-child {
				 		@extend %circle-get-toknow;
				 	} 
				 }								
			}
		}
		 @media (max-width: 768px) {
		 	overflow: visible;
		 }
		 
		 
		 
		 //sprite sheet
		 span.icon {
		    background-image: url(http://www.logitech.com/assets/64228/27/circle-pdp-2016.png);
		    background-repeat: no-repeat;
		    display: block;
		    &.icon-daily-summary-gray {
			    width: 34px;
			    height: 37px;
			    background-position: -5px -5px; 
			    &:hover,.active {
			    	width: 34px;
			    	height: 37px;
			    	background-position: -49px -5px;
			    }
			}
			&.icon-download-btn-gray {
			    width: 22px;
			    height: 27px;
			    background-position: -93px -5px;
			    &:hover,.active {
			       width: 22px;
			  	   height: 27px;
			       background-position: -93px -42px;
				}
			}
			&.icon-hd-gray {
			    width: 28px;
			    height: 30px;
			    background-position: -5px -79px;
			    &:hover,.active {
			   	 width: 28px;
			   	 height: 30px;
			     background-position: -43px -79px;
				}
			}
			
			&.icon-night-vision-gray {
			    width: 24px;
			    height: 24px;
			    background-position: -81px -79px;
			    &:hover,.active {
			   		width: 24px;
			    	height: 24px;
			    	background-position: -125px -5px;
				}
			}
		
			&.icon-smart-filter-gray {
			    width: 32px;
			    height: 40px;
			    background-position: -125px -39px;
			   	&:hover,.active {
			    	width: 32px;
			    	height: 40px;
			    	background-position: -115px -89px;
				}
			}
			&.icon-two-way-gray {
			    width: 30px;
			    height: 26px;
			    background-position: -5px -139px;
			    &:hover,.active {
			    	width: 30px;
			    	height: 26px;
			    	background-position: -45px -139px;
				}
			}
		}

	}
	
	@media (max-width: 767px) {
		#circle-phone {
			height: auto;
			min-height: 600px;
			h2 {
				font-size: 2.5em;
				line-height: 34px;
				margin-bottom: 11px;
			}
			.desktop-only {
				display: none;
			}
			.mobile-only {
				display: block;
			}
			.background .circle-feature-bar {
				display: none;
			}
			.container {
				margin: 0 10px;
				padding: 0 0 20px 0;
			}
			div.features .feature.on:after{
			
			}
			.legend-notes {
				display: none !important;
			}
			.legend {
				background-image: none;
			}
			.mobile-only.owl-carousel{
				h3 {
					font-size: 18px;
					font-family: "Brown-Pro-Regular";
				}
				p {
					font-size: 16px;
				}
			}
			.vid-wrapper {
				position: relative;
				a.play {
					display: block;
				}
				&.playing {
					a.play {
						display: none;
					}
				} 
				
			}
			div.phone-video {
				margin-bottom: 10px;
				position: relative;
				top: 0;
				transform: none;
				left: auto;
				 img.phone {
					display: none;
				}
				div.video-wrapper {
					  width: 100% !important;
					  height: auto;
					  position: relative;
					  top:0 !important;
					  left: 0 !important;
					  transform: none;
/* 					  video {
					  	opacity: 1;
					  	position: relative;
					  } */
				}
				.item-video{height:300px;}
			}
		}
	}
	
	
	//low desktop resolution
	
	#circle-phone.low-resolution {
		 height: auto;
			.container {
				transform: none;
				top: auto;
				padding: 50px 0;
			}
			
	}
	.top-panel.lock-nav-bar #circle-phone.low-resolution {
		height: auto;
	}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:portrait){
	#circle-phone {
		.container {
			 top: 50%;
		    -webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		    transform: translateY(-50%);
		}
		.feature-wrap {
			position: absolute;
		}
	}
}