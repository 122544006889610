
.product-features-scrollable-container {
  margin: 50px auto 1px;

  @media (min-width: $screen-lg-min) {
    max-width:1240px;
  }

  .container {
    background-color: $dark-gray;
    position:relative;
    padding: 0;
    overflow:hidden;

    @media (min-width: $screen-md-min) {
      margin-right: 10px;
      margin-left: 10px;
      width:auto;
    }
  }
}

.product-features-scrollable {
  // NOTE: at desktop size, this is element is reduced in
  // width to ONLY fit the description list.  Images are
  // absolute positioned off to the right of this element,
  // even though they sit in the markup within it

  padding: 25px 15px;
  color: $white;
  overflow:hidden;

  .feature-scroll-button {
    // Hidden for mobile view
    display:none;

    background:#40494d;
    text-align:center;
    height:50px;
    padding:10px;
    font-size:24px;
    cursor:pointer;

    @include transition(background-color 200ms ease-in-out);

    &:hover {
        background:#505b60;
    }

    span {
        @include transform(scale(1.5,1));
    }

    &.disabled {
        background:$gray;
        cursor:default;
    }

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }


  .features-list {
    list-style-type:none;
    margin: 0;
    padding: 10px 0 10px;
  }

  .features-list-item {
    margin-bottom: 2px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media (min-width: $screen-lg-min) {
      margin-bottom: 6px;
    }
  }
  .feature-title {
    position: relative;
    font-size: 1.625em;
    color: $yellow;
    &:before {
        content: " ";
        display: block;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 8px solid $yellow;
        z-index: 1;
        padding: 0;
        margin: -6px 0 0 -22px;
        position: absolute;
        top: .6em;
        left: 0;
    }
    @media (min-width: $screen-md-min) {
      font-size: 2em;
    }
  }
  .feature-description {
    margin-bottom: 34px;
    font-size: 0.875em;
    line-height: 1.714285714em;
    color: $gray;

    .additional-feature-footnote {
        font-size: .9em;
        font-style:italic;
        line-height: 1.3em;
    }
  }
  .feature-image {
    width:100%;
    height:100%;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    // NOTE: when only 1 image is provided, it is treated as a 'Legacy' block, meaning
    // that the same image is shown for all bullet items.  We 'hard-code' the image
    // in place, and also (via js) will bump it down in the dom, outside of the
    // bullet item wrapper, so it is always visible, even in the mobile layout.
    // A 'static-placement' class is assigned to the image wrapper in this case.
    &.static-placement {

        @media (min-width: $screen-md-min) {
            top:10% !important;
            left: 42%;
            right: 0;
            margin: 0 auto;
            opacity:1 !important;
            width:auto !important;
            height:auto !important;
            z-index:10 !important;
            text-align:center;
        }

        img {
          display: block;
          width: 90%;
          height: auto;
          margin: 25px auto;
          max-width: 500px;

          // Legacy image is treated as 'responsive' at mobile size, but as it gets
          // to larger screen sizes, we want to limit the scaling.
          @media (min-width: $screen-md-min) {
            display:inline-block;
            width: auto !important;    // Set to 100% for full width mobile image
            height: auto !important;
            max-height: 364px;  // 80% x 455px
            margin: auto;
          }
          @media (min-width: $screen-lg-min) {
            max-height: 472px;  // 80% x 590px
            margin: auto;
          }
        }



    }
  }

  .image-shield {
    @media (min-width: $screen-md-min) {
      position:absolute;
      top:0;
      right:0;
      background:transparent;
      width:58%;
      height:100%;
      z-index:20;
    }
  }


  &.initialized {   // .product-features-scrollable.initialized
    .feature-title {
      cursor: pointer;
    }
    @media (max-width: $screen-md-min) {

      // MOBILE EXPAND/COLLAPSE FUNCTIONALITY

      .feature-content {
        display: none;
      }
      .features-list-item.open {
        .feature-content {
          display: block;
        }
        .feature-title {
          color: $white;
          &:before {
            border-left: 8px solid $white;
            @include transform(rotate(90deg));
          }
        }
      }
    }
    @media (min-width: $screen-md-min) {

      // DESKTOP EXPAND/COLLAPSE FUNCTIONALITY

      .feature-description {
        // display: none;
        overflow:hidden;
        margin-bottom:0;
        height:0px;

        @include transition(height 500ms ease-in-out);

        &.skip-animation {
            @include transition(height 0 ease-in-out);
        }

        .feature-description-inner {
            /*
            margin-top:-1000%;
            @include transition(margin-top 500ms ease-in-out);
            */
        }

      }
      .feature-image {
        width: 58%;
        top:-100%;
        position: absolute;
        overflow:hidden;
        opacity: .5;
        @include gpu();
        @include transition(opacity 500ms ease, top 50ms ease 500ms);
        z-index: 1;
      }
      .features-list-item {

        overflow:hidden;
        padding-left: 12.5%;
        padding-right: 9.5%;
      }
      .features-list-item.active {
        .feature-title {
          color: $white;
          cursor: default;
          &:before {
            border-left: 8px solid $white;
            @include transform(rotate(90deg));
          }
        }
        .feature-description {
          // display: block;

          .feature-description-inner {
            // margin-top:0;
          }
        }
        .feature-image {
          top:0;
          opacity: 1;
          @include transition(top 500ms ease-in-out);
          z-index: 10;
        }
      }
      .features-list-item.active ~ .features-list-item {
        .feature-image {
          top:100%;
        }
      }
    }
  }


  @media (max-width: $screen-md-min) {  // MOBILE
    .feature-title,
    .feature-description {
      margin-left: 24px;
    }
  }
  @media (min-width: $screen-md-min) {   // DESKTOP - 992px
    width:42%;
    height: 455px;

    // margin: 100px auto 0;
    padding:0;

    .feature-scroll-button.up  {
      margin-top:-50px;

      @include transition(margin-top 500ms ease-in-out);
    }

    .features-list-copy-container {
      // position:relative;
      height:455px;

      @include transition(height 500ms ease-in-out);
    }

    .scrollable .feature-scroll-button.up  {
      top:0;
    }

    .feature-image {
      position:absolute;
      top:0;
      right:0;

      &.static-placement {
        margin-top:0;
      }
    }



    &.scrollable {
      .features-list-copy-container {
        height:355px;
        overflow-y:scroll;
        border-right:1px solid $medium-gray;
      }

      .feature-scroll-button.up {
        margin-top:0;
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    height: 590px;
    // padding-top: 165px;
    // padding-bottom: 0px;

    .features-list-copy-container {
      height: 590px;
    }

    &.scrollable {
      .features-list-copy-container {
        height:490px;
      }
    }
  }


}



