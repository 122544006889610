$circle-green: #42c1bd;

html#circle-landing, body.circle-landing {
	height:100%;
}
body.circle-landing {
	.modal {
		height: 100%;
	}
	main {
		height:100%;
		position:relative;
	}

	sup {
		line-height:1.7;
		top:0;
	}

	a, a:hover, a:visited, a:focus {
		text-decoration: none;
	}

	.center-horizontal {
		position:relative;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.center-vertical {
		position:absolute;
		top: 45%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}


	h1 {
		font-size: 7.5em;
		font-size: 4.6vw;
		margin-top:0;
		margin-left:-5px;
		line-height:1;
		letter-spacing: -4px;
	}

	h2 {
		font-size:5em;
		font-size:6.4vw;
		text-transform:uppercase;
		font-family: "Brown-Pro-Bold";
		letter-spacing:-4px;
		line-height:1.2;
		// min-width: 1260px;
		@media (min-width: $screen-lg-min) {
			font-size: 6.4vw;
		}
		// min-width: 1600px;
		@media (min-width: 1600px) {
			font-size: 6.7em;
		}

		&.alt {
			font-size:2.25em;
			font-size: 2.6vw;
		}
	}

	h3 {
		letter-spacing: 1.1px;
	}

	p {

		font-size:1.4em;
		//font-size:1.7vw;
		text-transform:none;
		font-family: "Brown-Pro-Light";
	}
	p.browserupgrade.upgrade {
		font-size: 1em;
	}
	main a {
		font-size:0.875em;
		//font-size:1vw;
	}

	main a.action {
		//line-height:0px;
		color:$blue;
		text-decoration: none;
		text-transform: uppercase;
		display: flex;
		align-items:center;


		span {
			vertical-align: middle;
			text-transform: uppercase;
		}

		span.dot {
			display: inline-block;
			background:$blue;
			width:60px;
			height:60px;
			@include border-radius(100%);
			text-align: center;
			line-height: 58px;
			color:$white;
			margin-right:10px;
			background-clip: padding-box;
			flex: 0 0 60px;
		}
	}

	main a.play {
		width: 60px;
		height: 60px;
		display: block;
		position:absolute;
		left: 50%;
		top:50%;
		-webkit-transform:  translate(-50%, -50%);
		-ms-transform:  translate(-50%, -50%);
		transform:  translate(-50%, -50%);
		z-index: 2;
		//pointer-events:none;
		span {
			display: inline-block;
			background:$blue;
			width:60px;
			height:60px;
			@include border-radius(100%);
			text-align: center;
			line-height: 58px;
			color:$white;
			margin-right:10px;
			background-clip: padding-box;
		}
	}



	video {
		display: block;
		padding:0;
		margin:0;
		width:100%;
		height:auto;
	}

	.video-wrapper {
		video {
			z-index: 1;
			top:0;
			left:0;
			width:100%;
			position:absolute;
			opacity: 0;
			//display:none;
		}

		img {
			position: relative;
			z-index:2;
			width:100%;
			height:auto;
		}

		a.play {
			z-index:3;
		}

		a.close {
			position: absolute;
			top:40px;
			right:40px;
			color:$yellow-green ;
			z-index: 10;
			text-transform: uppercase;
			opacity: 1;
			text-shadow: none;
			display: none;
		}

		&.playing {

			video {
				z-index: 5;
				opacity: 1;
			}

			img {
				opacity: 0;
			}

			a.play {
				display: none !important;
			}

			a.close {
				display: block;
			}
		}
	}



	.navbar-static-top {
		margin-bottom:0;
		//margin: -80px 0 0 0;
		min-height: 80px;
		padding-top:14px;
		padding-bottom:14px;
		background:$purple;
		.navbar {
			border:none;
			background: $purple;
			color:$white;
			min-height: 0px;
			margin-bottom:0;

			a {
				color:$white;
				font-size:0.875em;
				//font-size:1vw;

				&.navbar-brand {
					font-size:1.75em;
					line-height: 0.8;
					font-family: 'Brown-Pro-Bold';
					text-transform: uppercase;
				}

				&.btn-purple {

					font-size:0.75em;
					//font-size:0.86vw;
					line-height: 1.4;
					background:$purple;
					border:2px solid $white;
					@include border-radius(0);
					text-transform:uppercase;
					background-clip: padding-box;

					&:hover {
						background:rgba($white, 0.1);
					}
				}

				&.disable {
					pointer-events:none;
					opacity: 0.2;

				}
			}

			button.navbar-toggle {
				text-transform: uppercase;

				&:hover {
					background:none;
				}
			}

			.navbar-collapse {
				border-color:$purple;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
			}

			ul.nav {
				li {
					text-align: center;
				}
			}
		}
	}

	#circle-shelf {
		position: fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index: 1000;
		min-height: 650px;
		background: $teal;
		overflow: hidden;

		div.background {
			position: absolute;
			left:0;
			bottom:80px;
			height:665px;
			width:100%;
			z-index: 1;

			div.left {
				background: url(/images/circle/circle-shelf-left.jpg) left bottom repeat-x;
				width:50%;
				height:100%;
				display: block;
				float:left;
				position: relative;
				z-index:1;
			}

			div.right {
				background: url(/images/circle/circle-shelf-right.jpg) left bottom repeat-x;
				display: block;
				height:100%;
				width:50%;
				float:right;
				position: relative;
				z-index:1;
			}

			div.center {
				background:url(/images/circle/circle-shelf.jpg) center bottom no-repeat;
				width:100%;
				height:100%;
				position: relative;
				z-index:2;
			}
		}

		div.container {
			top:0;
			bottom:0px;
			height:100%;
			position:absolute;
			left:0;
			width:100%;
			z-index: 2;
		}

		img {
			display: block;

			&.circle {
				position: absolute;
				z-index: 3;
				left:50%;
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				bottom:129px;
			}

			&.cow {
				position:absolute;
				right:105px;
				bottom:129px;
				z-index: 2;
			}

			&.flower {
				position:absolute;
				left:53px;
				bottom:127px;
				z-index: 2;

			}
		}

		.content {
			position: absolute;
			z-index: 10;
			bottom:390px;
			left:60%;
			width: 35%;


			h1 {
				margin-top:0;
				margin-left:-5px;
				line-height:1;
			}

			p {
				margin:0;
			}
		}
	}

	.top-panel {
		position:relative;
		top:100%;
		width:100%;
		left:0;
		right:0;
		z-index: 4000;
		background:$white;
		padding-bottom:1px;
		margin-top:-80px;
		overflow-x:hidden;

		&.lock-nav-bar {
			padding-top:80px;
			.navbar-static-top {
				position: fixed;
				top:0;
				left:0;
				width:100%;
			}
		}
	}

	#circle-home {
		position:relative;
		background:$light-gray;



		h2 {
			line-height: .9;
		}

		.owl-carousel {
			z-index: 10;

			&.playing {
				.owl-dots {
					display: none;
				}
			}
		}

		.owl-dots {
			margin-top:0;
			position:absolute;
			width: 100%;
			left:0;
			bottom:10%;
			z-index: 2;
		}

		img {
			display: block;
			position:relative;
			z-index: 2;
			width:100%;
			height:auto;
		}

		div.background {
			position: relative;
			z-index: 1;

			.video-wrapper {
				a.play {
					display: none;
				}
			}
		}

		div.foreground {
			position:absolute;
			width:100%;
			z-index: 2;
			top:0;
			left:0;
		}

		div.container {
			position: relative;
		}

		div.content {
			position:absolute;
			z-index: 3;
			left:105px;
			top:75px;
			width:30%;
			color:$white;
		}

		.slide {
			&.playing {
				div.background {
					z-index: 3;
					.video-wrapper {
						z-index: 2;
						//position:absolute;
					}

					img {
						z-index: 1;
					}
				}

				//a.close {
				//	display: none;
				//}
			}
		}
	}

	#circle-phone {
		height:100%;
		padding: 20px 0;

		div.phone-video {
			margin: 20px auto 30px;
			float:left;
			position:relative;
			left:50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);

			img.phone {
				display: block;
				margin:0 auto;
				&.small {
					display: none;
				}
			}

			.video-wrapper {
				width: 500px;
				height: 285px;
				position:absolute;
				left: 50%;
				top:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);

				video {
					width:100%;
					height:100%;
				}


			}

			/*span {
				display: block;
				position:absolute;
				right:0;
				bottom: -25px;
				font-family: "Brown-Pro-Thinitalic";
				font-size: 0.875em;
				color:$white;
			}*/
		}

		div.content {
			color:$white;
			text-align: center;
		}

		div.features {
			display: flex;

			> div {
				border-left: 1px solid rgba(#ebebeb, 0.3);

				&:first-child {
					border-left:none;
				}
			}

			.feature {
				display: block;
				float:left;
				position:relative;
				padding: 18px 0 0 0;
				color:$text-gray;

				&:hover, &:active, &:focus {
					text-decoration: none;
				}

				div.icon {
					position: absolute;
					top:22px;
					left:55px;
				}

				div.info {
					display: block;
					padding-left:120px;
					padding-right:55px;

					h3 {
						margin-top:0;
						font-size:1em;
						//font-size:1.14vw;
						text-transform: uppercase;
						font-family: 'Brown-Pro-Bold';
					}

					p {
						font-size:inherit;
						line-height: 1.2;
					}
				}

				&.on {
					color:$white;
				}
			}
		}

		span.icon {
			display: block;
			width:45px;
			height:45px;
			background:url(/images/circle/phone-video-icons.png) no-repeat;

			&.icon-hd {
				background-position:right top;

				&.on {
					background-position:left top;
				}
			}

			&.icon-replay {
				background-position: right -46px;
				&.on {
					background-position:left -46px;
				}
			}

			&.icon-learn {
				background-position: right -92px;
				&.on {
					background-position:left -92px;
				}
			}
		}

		&.playing {
			a.play {
				display: none !important;
			}
		}


	}

	#circle-be-home {
		position:relative;
		height:100%;

		img {
			display: block;
			position:relative;
			z-index: 1;
		}

		div.container {
			position:absolute;
			z-index: 10;
			width:100%;
		}

		div.video-wrapper {
			position: relative;
			z-index: 1;

			a.play {
				top:65%;
			}
		}


		div.content {
			position:absolute;
			z-index: 2;
			left:0;
			width:100%;
			top:100px;
			margin:0 auto;
			text-align: center;
		}

		p {

			line-height: 1.2;
			font-family: 'Brown-Pro-Bold';
			margin-bottom:0;
			padding: 0 23%;
			color:$white;
		}

		span.icon {
			display: inline-block;
			width:45px;
			height:45px;
			background:url(/images/circle/be-home-icons.png) no-repeat;

			&.icon-see {
				background-position:left top;
			}

			&.icon-say {
				background-position: left -46px;
			}

			&.icon-view {
				background-position: left -92px;
			}
		}

		ul.info {
			list-style:none;
			position:relative;
			margin:0;
			width: 100%;
			li {
				visibility: hidden;
				display: block;
				float:left;
				position:absolute;
				top:0;
				left:0;
				width:100%;
				padding: 0 23%;
				margin: 0;

				&.on {
					visibility: visible;
				}
			}

		}

		ul.links {
			list-style: none;
			display: inline-block;
			margin-top:40px;
			padding:0;

			li {
				display: block;
				float:left;
				margin-left:28px;
				margin-right:28px;

				a {
					&:hover {
						text-decoration: none;
					}
				}

				span.icon {
					vertical-align: middle;
				}

				span.label {
					text-transform: uppercase;
					color:$blue;
					font-size:0.875em;
					//font-size:1vw;
					line-height:1.1;
					text-align:left;
					display: inline-block;
					vertical-align: middle;
				}

			}
		}

		.owl-dots {
			margin-top:0;
			position:absolute;
			width: 100%;
			left:0;
			bottom:10%;
		}
	}

	#circle-get-to-know {
		height:100%;
		min-height: 700px;
		padding-top:40px;
		padding-bottom:40px;

		div.container {
			position:relative;

			div.row {
				margin-top:0;
			}
		}

		h2 {

			color:$white;
			text-align: center;
			top:-10px;
			position:relative;



			// controls min-width: 992px;
			@media (min-width: $screen-md-min) {
				font-size: 6.4vw;
			}
			// min-width: 1260px;
			@media (min-width: $screen-lg-min) {
				font-size: 6.4vw;
			}
			// min-width: 1600px;
			//@media (min-width: 1600px) {
			//	font-size: 6.7em;
			//}
			@media (min-width: 1400px){
				font-size:5.625em;
			}

		}

		.carousel {
			position:relative;
			top:-30px;
			.slide {
				width:100%;
				height:100%;
				top:0;
				left:0;
				right:0;
				bottom:0;
				position:absolute;

				img {
					position:relative;
					left: 50%;
					-webkit-transform: translateX(-50%) scale(0.8);
					-ms-transform: translateX(-50%) scale(0.8);
					transform: translateX(-50%) scale(0.8);
					opacity: 0;
					top:-70px;

					&.default {
						display: none;
					}
				}

				div.info {
					width:270px; //230
					text-align: center;
					position:absolute;
					z-index: 10;
					ul {

					 li {
							text-align: left;
						}
					}
				}

				h3, li {
					font-size:0.875em;
					line-height: 1.375em;
				}

				p {
					font-size:inherit;
				}

				a {
					//text-align: center;
					color:$text-gray;
					display: block;
					width:100%;
					//width:230px;
					//position: absolute;

					&:hover {
						text-decoration: none;
					}
				}



				ul {
					//list-style: disc;
					li {
						display: list-item;
						text-align: center;
						font-family: 'Brown-Pro-Light';
						list-style: disc outside none;

						a {
							display: inline;
							width:auto;
							position: relative;
							font-size:inherit;
						}

					}
				}



				h3 {
					text-transform: uppercase;
					font-family: 'Brown-Pro-Bold';
					margin-bottom:0;
					margin-top:8px;
				}

				div.line {
					position: absolute;
					top:22px;
				}

				&.on {
					img {
						opacity: 1;
					}

					img.default {
						display: none;
					}
				}

				&.default {
					img.default {
						opacity: 1;
						display: block;
						position:absolute;

					}
				}

				&.activity-light {
					div.info {
						left:0;
						top:38px;
					}

					div.line {
						left:170px;
						right: 50%;
						height:40px;
						top:20px;
						border-bottom:1px solid #ffffff;
						border-right:1px solid #ffffff;
					}

					img {

					}
				}

				&.mic {
					div.info {
						right:0;
						top:105px;
					}

					div.line {
						top:125px;
						right:170px;
						left: 50%;
						border-bottom:1px solid #ffffff;
						margin-left:150px;
					}
				}

				&.rechargable {
					div.info {
						left:0;
						top:260px;
					}

					div.line {
						top:280px;
						left:170px;
						right: 50%;
						border-bottom:1px solid #ffffff;
						margin-right:110px;
					}

					img {
						top:-82px;
					}
				}

				&.mount {
					div.info {
						right:0;
						top:300px;
					}

					div.line {
						top:320px;
						right:170px;
						left: 50%;
						border-bottom:1px solid #ffffff;
						margin-left:110px;
					}

					img {
						top:-65px;
					}

				}
			}
		}

		div.content {
			position: absolute;
			z-index:1;
			width:100%;

			&.features {
				z-index: 3;
			}
		}

		div.product {
			z-index:2;
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;

			.feature {
				opacity: 0;
				width: 455px;
				top:160px;
				@include horizontally-centered;

				div {
					background: url(/images/circle/circle-parts.png) no-repeat;
					position:absolute;
					top:0;
					left:0;
					width:455px;
				}

				&.on {
					opacity: 1;
				}
			}

			 .feature-light, .feature-mic, .feature-default {
				div.circle {
					width: 455px;
					height: 561px;
				}

				div.light {
					background-position: left -564px;
					height:18px;
					top: 14px;
				}
			}

			.feature-portable {

				div.circle {
					background-position: left -590px;
					width: 455px;
					height: 535px;
				}

				div.base {
					background-position: left -1135px;
					width: 455px;
					height:323px;
					top: 300px;
				}
			}

			.feature-mount {
				div.circle {
					width: 455px;
					height: 445px;
					background-position: left -1466px;
				}

				div.mount {
					background-position: left -1915px;
					width: 455px;
					height: 214px;
					top: 300px;
				}
			}
		}

		span.icon {
			display: block;
			margin:0 auto;
			width:45px;
			height:45px;
			background:url(/images/circle/get-to-know-circle-icons.png) no-repeat;

			&.icon-activity {
				background-position:right top;
				&.on {
					background-position:left top;
				}
			}

			&.icon-portable {
				background-position: right -46px;
				&.on {
					background-position:left -46px;
				}
			}

			&.icon-mic {
				background-position: right -92px;
				&.on {
					background-position:left -92px;
				}
			}

			&.icon-mount {
				background-position: right -138px;
				&.on {
					background-position:left -138px;
				}
			}
		}
	}

	#circle-get-started {
		position:relative;

		div.background {
			position: relative;
			z-index: 1;

			img {
				display: block;
				width:100%;
				margin:0 auto;
			}

			div {
				display: none;
				background-position:right bottom;
			}
		}


		div.foreground {
			position:absolute;
			width:100%;
			height:100%;
			z-index: 2;
			top:0;
			left:0;
			bottom:0;
			right:0;

			div.container, div.row, div.row > div {
				height:100%;
			}
		}

		div.row > div {
			position: relative;
		}

		h2 {
			//font-size:2.25em;
			//font-size: 2.6vw;
			text-transform: none;
			font-family: 'Brown-Pro-Bold';
			margin-bottom:30px;
			letter-spacing: -1px;
		}

		a {
			display: inline-block;
			margin-right: 20px;
			margin-top:60px;
		}

		div.right {
			text-align: center;
		}
	}

	#product-hero {
		background:$purple;
		.owl-carousel .owl-item img {
		    max-width: 100%;
		    width: auto;
	    }

	    ul.product-view {
	    	li {
	    		margin:0;
	    	}
	    	a#product-view-specs {
	    		font-size: inherit;
	    	}
	    	a#product-360-view {
	    		display: none;
	    	}
	    	& + .notes {
	    		font-size: 1em;
	    	}
	    }
	    .shipping-info, .product-configurables-label {
	    	font-family: "Brown-Pro-Regular";
	    	&.disclaimer {
	    		font-family: "Brown-Pro-Italic";
	    	}
	    }
	    .product-configurables-label, .product-view p  {
	    	font-family: "Helvetica", Arial, sans-serif;
	    }
	}
	.tech-specs-body p {
		font-family: "Helvetica", Arial, sans-serif;
	}
	footer {
		position: relative;

		.footer #footer-links a {
			font-size: 1em;
		}
		.copyright p {
    		font-size: 12px;
    		font-family: "Brown-Pro-Regular";
		}
	}
	.footer-lite a {
		font-size: 1em;
	}
	div.cookie-disclaimer {
		z-index: 5000;
	}

	.product-hero-container .product-view {
	    margin-top: 0;
	}


}



/**
 * iPad with landscape orientation fix for virtual keyboard height
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
  #circle-shelf {
  	div.container {
    	max-height: 672px;
    }
  }
}
@media only screen and (device-height: 768px){
		body.circle-landing #circle-shelf .content{
		bottom: 275px;
	}
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
	body.circle-landing #circle-get-to-know .carousel .slide div.info ul{
		margin-left: auto;
    	margin-right: auto;
    	width: 284px;
    	li{
			text-align: left;
		}
	}
}

//large <= 1260px
@media (max-width: $screen-lg-min) {
	body.circle-landing {

		nav.navbar {
			.container {
				width:100%;
				padding-left:20px;
				padding-right:20px;
			}
		}
		#circle-shelf {
			img {

				&.cow {
					right:auto;
					left:50%;
					margin-left:140px;
				}

				&.flower {
					left:auto;
					right:50%;
					margin-right:50px;
				}
			}
		}

		#circle-home {
			div.foreground, div.container {
				height:100%;
			}
		}

		#circle-phone {
			div.features {
				.feature {

					padding-bottom:18px;

					div.icon {
						width:100%;
						float:none;
						position:relative;
						top:0;
						left:0;
						text-align: center;
						margin-bottom:14px;

						span.icon {
							margin:0 auto;
						}
					}

					div.info {
						width:100%;
						float:none;
						padding:0;
						text-align: center;

					}
				}
			}
		}
	}
}



//medium - 992
@media (max-width: $screen-sm-max) {
//@media (max-width: $screen-md-min) {
	body.circle-landing {
		h1 {
			letter-spacing: -6px;
			font-size: 50px;
		}

		p {
			font-size:1.1em;
		}

		a {
			font-size:0.875em;
		}

		nav.navbar {
			.btn {
				width:auto;
			}
		}

		&.no-scroll {
			.navbar-static-top {
				opacity: 0.25;
				a {
					pointer-events:none !important;
				}
			}
		}

		main {
			a.action {
				justify-content: center;
			}
		}

		#circle-home {

			div.content {

				text-align: center;
				left:0;
				top: 80px;
				//bottom:80px;
				width:100%;
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
			}


		}
		#circle-phone {
			div.phone-video {
				img.phone {
					width:90%;
				}

				div.video-wrapper {
					width:64%;
					height:auto;
				}
			}

		}

		#circle-be-home {
			ul.links {
				li {
					span.label {
						white-space: normal;
					}
				}
			}
		}

		#circle-get-to-know {
			height: auto;
			min-height: 0;
			padding-top:40px;
			padding-bottom:40px;

			.container {
				width:100%;
			}

			h2 {
				//margin-top:0px;
				//font-size: 2.25em;
				line-height: 1;
			}
			.carousel {
				top:0;
				.slide {
					width:100%;
					height:100%;
					top:0;
					left:0;
					right:0;
					bottom:0;
					position:relative;



					h3 {
						font-size:0.875em;
						line-height: 1.375em;
					}

					h3 {
						text-transform: uppercase;
						font-family: 'Brown-Pro-Bold';
						margin-bottom:0;
						margin-top:25px;
					}

					img {
						opacity: 1;

						width:50%;

						-webkit-transform: none;
						-ms-transform: none;
						transform: none;
						left:auto;
						top:0 !important;

						&.default{
							display: none !important;
						}
					}

					div.info {
						text-align: center;
						color:$text-gray;
						display: block;
						width:100%;
						top:auto !important;
						left:auto !important;
						right:auto !important;
						bottom:auto !important;
						position: relative;
						pointer-events:none;

						a {
							pointer-events:none;
						}


					}

					div.line {
						display: none;
					}

					span.icon {
						display: none;
					}


				}
			}
		}

		#circle-get-started {
			overflow: hidden;

			div.background {
				 position:absolute;
				 top:0;
				 left:0;
				 right:0;
				 bottom:0;
				 width:100%;
				 height:100%;


				img {
					width:1200px;
					height:auto;
					float:right;
					position:relative;
					right:-20%;
				}
			}

			 div.foreground {
			 	position: relative;
			 	padding-top:120px;
			 	padding-bottom:160px;

			 	div.content {
			 		-webkit-transform: none;
					-ms-transform: none;
					transform: none;
					left: auto;
					top: 0 !important;
					position:relative;
			 	}
			 }

			 a {
			 	margin-top:20px;
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
				position: relative;
				top: 0;
			 }

		}

	}

}

@media (min-width: 768px) and  (max-width: 991px) {
	body.circle-landing {
		#circle-be-home {
			ul.links {
				li {
					&:nth-child(3) {
						span.label {
							max-width: 150px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	#circle-be-home {
		.video-wrapper {
			img {
				&.visible-sm {
					display: none !important;
				}
				&.visible-xs {
					display: block !important;
				}
			}
		}
	}

}

//767
@media (max-width: $screen-xs-max) {
	body.circle-landing {
		h1 {
			font-size: 3.375em;
			font-size: 14vw;
		}

		h2 {
			font-size:2.25em;
			font-size:8vw;

			&.alt {
				font-size: 2.25em;
				font-size:5vw;
			}
		}

		p {
			font-size:1.125em;
		}

		a.action {
			span.dot {
				width:46px;
				height:46px;
				line-height: 44px;
			}
		}

		.navbar-static-top {
			min-height: 60px;
			padding-top:1px;
			padding-bottom:1px;
			//background:$purple;

			.navbar {
				button.navbar-toggle {
					border:none;
					i.closed {
						display: none;
					}

					&.collapsed {
						i.closed {
							display: inline;
						}

						i.open {
							display: none;
						}
					}
				}

				a {
					&.btn-purple {
						height:30px;
						width:auto;
						font-size:12px;
						line-height: 1;
						background:$purple;
						border:2px solid $white;
						padding: 6px 10px;
						@include border-radius(0);
						text-transform:uppercase;

						&:hover {
							background:rgba($white, 0.1);
						}
					}
				}
			}
		}

		.video-wrapper {
			a.close {
				top:20px;
				right:20px;
			}
		}

		#circle-shelf {
			position: relative;
			min-height: 570px;

			div.background {
				bottom:60px;
				div.left, div.right {
					display: none;
				}
				div.center {
					background:url(/images/circle/circle-shelf-small.jpg) center bottom no-repeat;
					width:100%;
					height:100%;
					position: relative;
					z-index:2;
				}
			}

			img {
				&.circle {
					position: absolute;
					bottom:93px;
					width:149px;
					height:auto;
				}

				&.cow {
					margin-left:80px;
					bottom:93px;
					width: 264px;
					height:auto;
				}

				&.flower {
					margin-right:10px;
					bottom:92px;
					width:366px;
					height:auto;
				}
			}

			div.container {
				//bottom:0px;
			}

			div.content {
				position:relative;
				top:0;
				left:0;
				width:100%;
				padding:0 40px;
				margin-top:135px;
				margin-left:0;
				text-align: center;

			}
		}

		.top-panel {
			top:0;
			margin-top:-60px;
		}

		#circle-home {
			padding-top:0;

			div.foreground {
				position: relative;
			}

			div.background {
				position:relative;
				overflow: hidden;

				a.play {
					display: block;
				}

				img {
					//display: none;
					position: relative;
					top:0;
					//left:0;
					//z-index: 1;
					pointer-events:none;
				}

				.video-wrapper {
					a.play {
						display: block;
					}
				}
			}

			div.content {
				position:relative;
				z-index: 3;
				left:0;
				top:0;
				width:100%;
				text-align: center;
				padding: 40px 40px 20px 40px;
				color:$black;

				a.action {
					display: none;
				}
			}

			.container .row > div {
				text-align: center;
				padding: 0 40px;
			}

			.slide {
				overflow: hidden;
			}

			.owl-dots {
				bottom:5px;
			}
		}

		#circle-phone {
			div.phone-video {
				margin-bottom:20px;

				img.phone {
					width:100%;
				}

				div.video-wrapper {
					width:70%;
					height:auto;
				}
			}

			div.features {
				display: block;

				> div {
					border:none;
				}

				.feature {
					display: block;
					float:none;
					padding: 20px 40px;
				}
			}
		}

		#circle-be-home {
			padding-top:0px;

			p  {
				font-size: 1.125em;
				line-height: 1.625em;
				padding: 0 40px;
				color:$black;
			}

			div.container {
				position:relative;
			}

			div.content {
				top:0px;
				padding-top:40px;
				padding-bottom:40px;
				position:relative;
			}

			.slide:first-child {
				ul.links {
					margin:0;
					padding:0;
					li {
						display: none;

						&:first-child {
							display: inline-block;
						}

						a {
							pointer-events: none;
							span.icon {
								display: block;
								margin:0 auto 14px auto;
							}

							span.label {
								display: block;
								margin: 0 auto;
								text-align: center;
							}
						}
					}
				}
			}

			p {
				font-family: "Brown-Pro-Light";
			}

			.owl-dots {
				bottom:5px;
			}

			div.slide {
				overflow: hidden;
			}


			div.video-wrapper {
				a.play {
					-ms-transform: translateY(-50%);
				    top: 50%;
				}
			}
		}

		#circle-get-started {

			div.background {
				img {
					right:0%;
				}
			}

			div.foreground {
				padding-top:40px;
				padding-bottom:40px;
			}
		}
	}
	div.slide .info ul {
		margin-left: auto;
    	margin-right: auto;
  		width: 275px;
	}
}
@media (max-width: 700px){
	body.circle-landing {
		h1 {
			font-size: 3.375em;
			font-size:50px;
			line-height: 0.85 !important;
		}
		#circle-shelf .container div.content {
			margin-top: 60px;
		}
	}
}
@media (max-width: 600px){
	body.circle-landing {
		h1 {
			font-size: 3.375em;
			font-size:50px;
			line-height: 0.85 !important;
		}

		h2 {
			font-size:2.25em;
			font-size:11.25vw;

			&.alt {
				font-size:2.25em;
				font-size: 6vw;
			}
		}

		p {
			font-size:1.125em;

		}
		#circle-get-started {
			div.background {
				img {
					right:-20%;
				}
			}
		}
	}
}


@media (max-width: 400px){
	body.circle-landing {
		#circle-shelf {
			div.content {
				margin-top: 58px;
				padding:0 10px;
			}
			p {
				line-height: 18px;
				padding-top: 5px;
			}
		}
	
		#circle-phone {
			div.phone-video {
				img.phone {
					&.large {
						display: none;
					}

					&.small {
						display: block;
						width:100%;
					}
				}
				div.video-wrapper {
					width:210px;
					height:124px;
				}
			}
		}
	}
}

$circle-green: #42c1bd;

html#circle-landing, body.circle-landing {
	height:100%;
}
body.circle-landing {
	.modal {
		height: 100%;
	}
	main {
		height:100%;
		position:relative;
	}

	sup {
		line-height:1.7;
		top:0;
	}

	a, a:hover, a:visited, a:focus {
		text-decoration: none;
	}

	.center-horizontal {
		position:relative;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	.center-vertical {
		position:absolute;
		top: 45%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}


	h1 {
		font-size: 7.5em;
		font-size: 4.6vw;
		margin-top:0;
		margin-left:-5px;
		line-height:1;
		letter-spacing: -4px;
	}

	h2 {
		font-size:5em;
		font-size:6.4vw;
		text-transform:uppercase;
		font-family: "Brown-Pro-Bold";
		letter-spacing:-4px;
		line-height:1.2;
		// min-width: 1260px;
		@media (min-width: $screen-lg-min) {
			font-size: 6.4vw;
		}
		// min-width: 1600px;
		@media (min-width: 1600px) {
			font-size: 6.7em;
		}

		&.alt {
			font-size:2.25em;
			font-size: 2.6vw;
		}
	}

	h3 {
		letter-spacing: 1.1px;
	}

	p {

		font-size:1.4em;
		//font-size:1.7vw;
		text-transform:none;
		font-family: "Brown-Pro-Light";
	}

	main a {
		font-size:0.875em;
		//font-size:1vw;
	}

	main a.action {
		//line-height:0px;
		color:$blue;
		text-decoration: none;
		text-transform: uppercase;
		display: flex;
		align-items:center;


		span {
			vertical-align: middle;
			text-transform: uppercase;
		}

		span.dot {
			display: inline-block;
			background:$blue;
			width:60px;
			height:60px;
			@include border-radius(100%);
			text-align: center;
			line-height: 58px;
			color:$white;
			margin-right:10px;
			background-clip: padding-box;
			flex: 0 0 60px;
		}
	}

	main a.play {
		width: 60px;
		height: 60px;
		display: block;
		position:absolute;
		left: 50%;
		top:50%;
		-webkit-transform:  translate(-50%, -50%);
		-ms-transform:  translate(-50%, -50%);
		transform:  translate(-50%, -50%);
		z-index: 2;
		//pointer-events:none;
		span {
			display: inline-block;
			background:$blue;
			width:60px;
			height:60px;
			@include border-radius(100%);
			text-align: center;
			line-height: 58px;
			color:$white;
			margin-right:10px;
			background-clip: padding-box;
		}
	}



	video {
		display: block;
		padding:0;
		margin:0;
		width:100%;
		height:auto;
	}

	.video-wrapper {
		video {
			z-index: 1;
			top:0;
			left:0;
			width:100%;
			position:absolute;
			opacity: 0;
			//display:none;
		}

		img {
			position: relative;
			z-index:2;
			width:100%;
			height:auto;
		}

		a.play {
			z-index:3;
		}

		a.close {
			position: absolute;
			top:40px;
			right:40px;
			color:$yellow-green ;
			z-index: 10;
			text-transform: uppercase;
			opacity: 1;
			text-shadow: none;
			display: none;
		}

		&.playing {

			video {
				z-index: 5;
				opacity: 1;
			}

			img {
				opacity: 0;
			}

			a.play {
				display: none !important;
			}

			a.close {
				display: block;
			}
		}
	}



	.navbar-static-top {
		margin-bottom:0;
		//margin: -80px 0 0 0;
		min-height: 80px;
		padding-top:14px;
		padding-bottom:14px;
		background:$purple;
		.navbar {
			border:none;
			background: $purple;
			color:$white;
			min-height: 0px;
			margin-bottom:0;

			a {
				color:$white;
				font-size:0.875em;
				//font-size:1vw;

				&.navbar-brand {
					font-size:1.75em;
					line-height: 0.8;
					font-family: 'Brown-Pro-Bold';
					text-transform: uppercase;
				}

				&.btn-purple {

					font-size:0.75em;
					//font-size:0.86vw;
					line-height: 1.4;
					background:$purple;
					border:2px solid $white;
					@include border-radius(0);
					text-transform:uppercase;
					background-clip: padding-box;

					&:hover {
						background:rgba($white, 0.1);
					}
				}

				&.disable {
					pointer-events:none;
					opacity: 0.2;

				}
			}

			button.navbar-toggle {
				text-transform: uppercase;

				&:hover {
					background:none;
				}
			}

			.navbar-collapse {
				border-color:$purple;
				-webkit-box-shadow: none;
				-moz-box-shadow: none;
				box-shadow: none;
			}

			ul.nav {
				li {
					text-align: center;
				}
			}
		}
	}

	#circle-shelf {
		position: fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index: 1000;
		min-height: 650px;
		background: $teal;
		overflow: hidden;

		div.background {
			position: absolute;
			left:0;
			bottom:80px;
			height:665px;
			width:100%;
			z-index: 1;

			div.left {
				background: url(/images/circle/circle-shelf-left.jpg) left bottom repeat-x;
				width:50%;
				height:100%;
				display: block;
				float:left;
				position: relative;
				z-index:1;
			}

			div.right {
				background: url(/images/circle/circle-shelf-right.jpg) left bottom repeat-x;
				display: block;
				height:100%;
				width:50%;
				float:right;
				position: relative;
				z-index:1;
			}

			div.center {
				background:url(/images/circle/circle-shelf.jpg) center bottom no-repeat;
				width:100%;
				height:100%;
				position: relative;
				z-index:2;
			}
		}

		div.container {
			top:0;
			bottom:0px;
			height:100%;
			position:absolute;
			left:0;
			width:100%;
			z-index: 2;
		}

		img {
			display: block;

			&.circle {
				position: absolute;
				z-index: 3;
				left:50%;
				-webkit-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				transform: translateX(-50%);
				bottom:129px;
			}

			&.cow {
				position:absolute;
				right:105px;
				bottom:129px;
				z-index: 2;
			}

			&.flower {
				position:absolute;
				left:53px;
				bottom:127px;
				z-index: 2;

			}
		}

		.content {
			position: absolute;
			z-index: 10;
			bottom:390px;
			left:60%;
			width: 35%;


			h1 {
				margin-top:0;
				margin-left:-5px;
				line-height:1;
			}

			p {
				margin:0;
			}
		}
	}

	.top-panel {
		position:relative;
		top:100%;
		width:100%;
		left:0;
		right:0;
		z-index: 4000;
		background:$white;
		padding-bottom:1px;
		margin-top:-80px;
		overflow-x:hidden;

		&.lock-nav-bar {
			padding-top:80px;
			.navbar-static-top {
				position: fixed;
				top:0;
				left:0;
				width:100%;
			}
		}
	}

	#circle-home {
		position:relative;
		background:$light-gray;



		h2 {
			line-height: .9;
		}

		.owl-carousel {
			z-index: 10;

			&.playing {
				.owl-dots {
					display: none;
				}
			}
		}

		.owl-dots {
			margin-top:0;
			position:absolute;
			width: 100%;
			left:0;
			bottom:10%;
			z-index: 2;
		}

		img {
			display: block;
			position:relative;
			z-index: 2;
			width:100%;
			height:auto;
		}

		div.background {
			position: relative;
			z-index: 1;

			.video-wrapper {
				a.play {
					display: none;
				}
			}
		}

		div.foreground {
			position:absolute;
			width:100%;
			z-index: 2;
			top:0;
			left:0;
		}

		div.container {
			position: relative;
		}

		div.content {
			position:absolute;
			z-index: 3;
			left:105px;
			top:75px;
			width:30%;
			color:$white;
		}

		.slide {
			&.playing {
				div.background {
					z-index: 3;
					.video-wrapper {
						z-index: 2;
						//position:absolute;
					}

					img {
						z-index: 1;
					}
				}

				//a.close {
				//	display: none;
				//}
			}
		}
	}

	#circle-phone {
		height:100%;
		padding: 20px 0;

		div.phone-video {
			margin: 20px auto 30px;
			float:left;
			position:relative;
			left:50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);

			img.phone {
				display: block;
				margin:0 auto;
				&.small {
					display: none;
				}
			}

			.video-wrapper {
				width: 500px;
				height: 285px;
				position:absolute;
				left: 50%;
				top:50%;
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);

				video {
					width:100%;
					height:100%;
				}


			}

			/*span {
				display: block;
				position:absolute;
				right:0;
				bottom: -25px;
				font-family: "Brown-Pro-Thinitalic";
				font-size: 0.875em;
				color:$white;
			}*/
		}

		div.content {
			color:$white;
			text-align: center;
		}

		div.features {
			display: flex;

			> div {
				border-left: 1px solid rgba(#ebebeb, 0.3);

				&:first-child {
					border-left:none;
				}
			}

			.feature {
				display: block;
				float:left;
				position:relative;
				padding: 18px 0 0 0;
				color:$text-gray;

				&:hover, &:active, &:focus {
					text-decoration: none;
				}

				div.icon {
					position: absolute;
					top:22px;
					left:55px;
				}

				div.info {
					display: block;
					padding-left:120px;
					padding-right:55px;

					h3 {
						margin-top:0;
						font-size:1em;
						//font-size:1.14vw;
						text-transform: uppercase;
						font-family: 'Brown-Pro-Bold';
					}

					p {
						font-size:inherit;
						line-height: 1.2;
					}
				}

				&.on {
					color:$white;
				}
			}
		}

		span.icon {
			display: block;
			width:45px;
			height:45px;
			background:url(/images/circle/phone-video-icons.png) no-repeat;

			&.icon-hd {
				background-position:right top;

				&.on {
					background-position:left top;
				}
			}

			&.icon-replay {
				background-position: right -46px;
				&.on {
					background-position:left -46px;
				}
			}

			&.icon-learn {
				background-position: right -92px;
				&.on {
					background-position:left -92px;
				}
			}
		}

		&.playing {
			a.play {
				display: none !important;
			}
		}


	}

	#circle-be-home {
		position:relative;
		height:100%;

		img {
			display: block;
			position:relative;
			z-index: 1;
		}

		div.container {
			position:absolute;
			z-index: 10;
			width:100%;
		}

		div.video-wrapper {
			position: relative;
			z-index: 1;

			a.play {
				top:65%;
			}
		}


		div.content {
			position:absolute;
			z-index: 2;
			left:0;
			width:100%;
			top:100px;
			margin:0 auto;
			text-align: center;
		}

		p {

			line-height: 1.2;
			font-family: 'Brown-Pro-Bold';
			margin-bottom:0;
			padding: 0 23%;
			color:$white;
		}

		span.icon {
			display: inline-block;
			width:45px;
			height:45px;
			background:url(/images/circle/be-home-icons.png) no-repeat;

			&.icon-see {
				background-position:left top;
			}

			&.icon-say {
				background-position: left -46px;
			}

			&.icon-view {
				background-position: left -92px;
			}
		}

		ul.info {
			list-style:none;
			position:relative;
			margin:0;
			width: 100%;
			li {
				visibility: hidden;
				display: block;
				float:left;
				position:absolute;
				top:0;
				left:0;
				width:100%;
				padding: 0 23%;
				margin: 0;

				&.on {
					visibility: visible;
				}
			}

		}

		ul.links {
			list-style: none;
			display: inline-block;
			margin-top:40px;
			padding:0;

			li {
				display: block;
				float:left;
				margin-left:28px;
				margin-right:28px;

				a {
					&:hover {
						text-decoration: none;
					}
				}

				span.icon {
					vertical-align: middle;
				}

				span.label {
					text-transform: uppercase;
					color:$blue;
					font-size:0.875em;
					//font-size:1vw;
					line-height:1.1;
					text-align:left;
					display: inline-block;
					vertical-align: middle;
				}

			}
		}

		.owl-dots {
			margin-top:0;
			position:absolute;
			width: 100%;
			left:0;
			bottom:10%;
		}
	}

	#circle-get-to-know {
		height:100%;
		min-height: 700px;
		padding-top:40px;
		padding-bottom:40px;

		div.container {
			position:relative;

			div.row {
				margin-top:0;
			}
		}

		h2 {

			color:$white;
			text-align: center;
			top:-10px;
			position:relative;



			// controls min-width: 992px;
			@media (min-width: $screen-md-min) {
				font-size: 6.4vw;
			}
			// min-width: 1260px;
			@media (min-width: $screen-lg-min) {
				font-size: 6.4vw;
			}
			// min-width: 1600px;
			//@media (min-width: 1600px) {
			//	font-size: 6.7em;
			//}
			@media (min-width: 1400px){
				font-size:5.625em;
			}

		}

		.carousel {
			position:relative;
			top:-30px;
			.slide {
				width:100%;
				height:100%;
				top:0;
				left:0;
				right:0;
				bottom:0;
				position:absolute;

				img {
					position:relative;
					left: 50%;
					-webkit-transform: translateX(-50%) scale(0.8);
					-ms-transform: translateX(-50%) scale(0.8);
					transform: translateX(-50%) scale(0.8);
					opacity: 0;
					top:-70px;

					&.default {
						display: none;
					}
				}

				div.info {
					width:270px; //230
					text-align: center;
					position:absolute;
					z-index: 10;
					ul {

					 li {
							text-align: left;
						}
					}
				}

				h3, li {
					font-size:0.875em;
					line-height: 1.375em;
				}

				p {
					font-size:inherit;
				}

				a {
					//text-align: center;
					color:$text-gray;
					display: block;
					width:100%;
					//width:230px;
					//position: absolute;

					&:hover {
						text-decoration: none;
					}
				}



				ul {
					//list-style: disc;
					li {
						display: list-item;
						text-align: center;
						font-family: 'Brown-Pro-Light';
						list-style: disc outside none;

						a {
							display: inline;
							width:auto;
							position: relative;
							font-size:inherit;
						}

					}
				}



				h3 {
					text-transform: uppercase;
					font-family: 'Brown-Pro-Bold';
					margin-bottom:0;
					margin-top:8px;
				}

				div.line {
					position: absolute;
					top:22px;
				}

				&.on {
					img {
						opacity: 1;
					}

					img.default {
						display: none;
					}
				}

				&.default {
					img.default {
						opacity: 1;
						display: block;
						position:absolute;

					}
				}

				&.activity-light {
					div.info {
						left:0;
						top:38px;
					}

					div.line {
						left:170px;
						right: 50%;
						height:40px;
						top:20px;
						border-bottom:1px solid #ffffff;
						border-right:1px solid #ffffff;
					}

					img {

					}
				}

				&.mic {
					div.info {
						right:0;
						top:105px;
					}

					div.line {
						top:125px;
						right:170px;
						left: 50%;
						border-bottom:1px solid #ffffff;
						margin-left:150px;
					}
				}

				&.rechargable {
					div.info {
						left:0;
						top:260px;
					}

					div.line {
						top:280px;
						left:170px;
						right: 50%;
						border-bottom:1px solid #ffffff;
						margin-right:110px;
					}

					img {
						top:-82px;
					}
				}

				&.mount {
					div.info {
						right:0;
						top:300px;
					}

					div.line {
						top:320px;
						right:170px;
						left: 50%;
						border-bottom:1px solid #ffffff;
						margin-left:110px;
					}

					img {
						top:-65px;
					}

				}
			}
		}

		div.content {
			position: absolute;
			z-index:1;
			width:100%;

			&.features {
				z-index: 3;
			}
		}

		div.product {
			z-index:2;
			position: absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;

			.feature {
				opacity: 0;
				width: 455px;
				top:160px;
				@include horizontally-centered;

				div {
					background: url(/images/circle/circle-parts.png) no-repeat;
					position:absolute;
					top:0;
					left:0;
					width:455px;
				}

				&.on {
					opacity: 1;
				}
			}

			 .feature-light, .feature-mic, .feature-default {
				div.circle {
					width: 455px;
					height: 561px;
				}

				div.light {
					background-position: left -564px;
					height:18px;
					top: 14px;
				}
			}

			.feature-portable {

				div.circle {
					background-position: left -590px;
					width: 455px;
					height: 535px;
				}

				div.base {
					background-position: left -1135px;
					width: 455px;
					height:323px;
					top: 300px;
				}
			}

			.feature-mount {
				div.circle {
					width: 455px;
					height: 445px;
					background-position: left -1466px;
				}

				div.mount {
					background-position: left -1915px;
					width: 455px;
					height: 214px;
					top: 300px;
				}
			}
		}

		span.icon {
			display: block;
			margin:0 auto;
			width:45px;
			height:45px;
			background:url(/images/circle/get-to-know-circle-icons.png) no-repeat;

			&.icon-activity {
				background-position:right top;
				&.on {
					background-position:left top;
				}
			}

			&.icon-portable {
				background-position: right -46px;
				&.on {
					background-position:left -46px;
				}
			}

			&.icon-mic {
				background-position: right -92px;
				&.on {
					background-position:left -92px;
				}
			}

			&.icon-mount {
				background-position: right -138px;
				&.on {
					background-position:left -138px;
				}
			}
		}
	}

	#circle-get-started {
		position:relative;

		div.background {
			position: relative;
			z-index: 1;

			img {
				display: block;
				width:100%;
				margin:0 auto;
			}

			div {
				display: none;
				background-position:right bottom;
			}
		}


		div.foreground {
			position:absolute;
			width:100%;
			height:100%;
			z-index: 2;
			top:0;
			left:0;
			bottom:0;
			right:0;

			div.container, div.row, div.row > div {
				height:100%;
			}
		}

		div.row > div {
			position: relative;
		}

		h2 {
			//font-size:2.25em;
			//font-size: 2.6vw;
			text-transform: none;
			font-family: 'Brown-Pro-Bold';
			margin-bottom:30px;
			letter-spacing: -1px;
		}

		a {
			display: inline-block;
			margin-right: 20px;
			margin-top:60px;
		}

		div.right {
			text-align: center;
		}
	}

	#product-hero {
		background:$purple;
		.owl-carousel .owl-item img {
		    max-width: 100%;
		    width: auto;
	    }

	    ul.product-view {
	    	li {
	    		margin:0;
	    	}
	    	a#product-view-specs {
	    		font-size: inherit;
	    	}
	    	a#product-360-view {
	    		display: none;
	    	}
	    	& + .notes {
	    		font-size: 1em;
	    	}
	    }
	    .shipping-info, .product-configurables-label {
	    	font-family: "Brown-Pro-Regular";
	    	&.disclaimer {
	    		font-family: "Brown-Pro-Italic";
	    	}
	    }
	    .product-configurables-label, .product-view p  {
	    	font-family: "Helvetica", Arial, sans-serif;
	    }
	}
	.tech-specs-body p {
		font-family: "Helvetica", Arial, sans-serif;
	}
	footer {
		position: relative;

		.footer #footer-links a {
			font-size: 1em;
		}
		.copyright p {
    		font-size: 12px;
    		font-family: "Brown-Pro-Regular";
		}
	}
	.footer-lite a {
		font-size: 1em;
	}
	div.cookie-disclaimer {
		z-index: 5000;
	}

	.product-hero-container .product-view {
	    margin-top: 0;
	}


}



/**
 * iPad with landscape orientation fix for virtual keyboard height
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
  #circle-shelf {
  	div.container {
    	max-height: 672px;
    }
  }
}
@media only screen and (device-height: 768px){
		body.circle-landing #circle-shelf .content{
		bottom: 275px;
	}
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
	body.circle-landing #circle-get-to-know .carousel .slide div.info ul{
		margin-left: auto;
    	margin-right: auto;
    	width: 284px;
    	li{
			text-align: left;
		}
	}
}

//large <= 1260px
@media (max-width: $screen-lg-min) {
	body.circle-landing {

		nav.navbar {
			.container {
				width:100%;
				padding-left:20px;
				padding-right:20px;
			}
		}
		#circle-shelf {
			img {

				&.cow {
					right:auto;
					left:50%;
					margin-left:140px;
				}

				&.flower {
					left:auto;
					right:50%;
					margin-right:50px;
				}
			}
		}

		#circle-home {
			div.foreground, div.container {
				height:100%;
			}
		}

		#circle-phone {
			div.features {
				.feature {

					padding-bottom:18px;

					div.icon {
						width:100%;
						float:none;
						position:relative;
						top:0;
						left:0;
						text-align: center;
						margin-bottom:14px;

						span.icon {
							margin:0 auto;
						}
					}

					div.info {
						width:100%;
						float:none;
						padding:0;
						text-align: center;

					}
				}
			}
		}
	}
}



//medium - 992
@media (max-width: $screen-sm-max) {
//@media (max-width: $screen-md-min) {
	body.circle-landing {
		h1 {
			letter-spacing: -6px;
			font-size: 50px;
		}

		p {
			font-size:1.1em;
		}

		a {
			font-size:0.875em;
		}

		nav.navbar {
			.btn {
				width:auto;
			}
		}

		&.no-scroll {
			.navbar-static-top {
				opacity: 0.25;
				a {
					pointer-events:none !important;
				}
			}
		}

		#circle-home {

			div.content {

				text-align: center;
				left:0;
				top: 80px;
				//bottom:80px;
				width:100%;
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
			}


		}
		#circle-phone {
			div.phone-video {
				img.phone {
					width:90%;
				}

				div.video-wrapper {
					width:64%;
					height:auto;
				}
			}

		}

		#circle-be-home {
			ul.links {
				li {
					span.label {
						white-space: normal;
					}
				}
			}
		}

		#circle-get-to-know {
			height: auto;
			min-height: 0;
			padding-top:40px;
			padding-bottom:40px;

			.container {
				width:100%;
			}

			h2 {
				//margin-top:0px;
				//font-size: 2.25em;
				line-height: 1;
			}
			.carousel {
				top:0;
				.slide {
					width:100%;
					height:100%;
					top:0;
					left:0;
					right:0;
					bottom:0;
					position:relative;



					h3 {
						font-size:0.875em;
						line-height: 1.375em;
					}

					h3 {
						text-transform: uppercase;
						font-family: 'Brown-Pro-Bold';
						margin-bottom:0;
						margin-top:25px;
					}

					img {
						opacity: 1;

						width:50%;

						-webkit-transform: none;
						-ms-transform: none;
						transform: none;
						left:auto;
						top:0 !important;

						&.default{
							display: none !important;
						}
					}

					div.info {
						text-align: center;
						color:$text-gray;
						display: block;
						width:100%;
						top:auto !important;
						left:auto !important;
						right:auto !important;
						bottom:auto !important;
						position: relative;
						pointer-events:none;

						a {
							pointer-events:none;
						}


					}

					div.line {
						display: none;
					}

					span.icon {
						display: none;
					}


				}
			}
		}

		#circle-get-started {
			overflow: hidden;

			div.background {
				 position:absolute;
				 top:0;
				 left:0;
				 right:0;
				 bottom:0;
				 width:100%;
				 height:100%;


				img {
					width:1200px;
					height:auto;
					float:right;
					position:relative;
					right:-20%;
				}
			}

			 div.foreground {
			 	position: relative;
			 	padding-top:120px;
			 	padding-bottom:160px;

			 	div.content {
			 		-webkit-transform: none;
					-ms-transform: none;
					transform: none;
					left: auto;
					top: 0 !important;
					position:relative;
			 	}
			 }

			 a {
			 	margin-top:20px;
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
				position: relative;
				top: 0;
			 }

		}

	}

}

@media (min-width: 768px) and  (max-width: 991px) {
	body.circle-landing {
		#circle-be-home {
			ul.links {
				li {
					&:nth-child(3) {
						span.label {
							max-width: 150px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	#circle-be-home {
		.video-wrapper {
			img {
				&.visible-sm {
					display: none !important;
				}
				&.visible-xs {
					display: block !important;
				}
			}
		}
	}

}

//767
@media (max-width: $screen-xs-max) {
	body.circle-landing {
		h1 {
			font-size: 3.375em;
			font-size: 14vw;
		}

		h2 {
			font-size:2.25em;
			font-size:8vw;

			&.alt {
				font-size: 2.25em;
				font-size:5vw;
			}
		}

		p {
			font-size:1.125em;
		}

		a.action {
			span.dot {
				width:46px;
				height:46px;
				line-height: 44px;
			}
		}

		.navbar-static-top {
			min-height: 60px;
			padding-top:1px;
			padding-bottom:1px;
			//background:$purple;

			.navbar {
				button.navbar-toggle {
					border:none;
					i.closed {
						display: none;
					}

					&.collapsed {
						i.closed {
							display: inline;
						}

						i.open {
							display: none;
						}
					}
				}

				a {
					&.btn-purple {
						height:30px;
						width:auto;
						font-size:12px;
						line-height: 1;
						background:$purple;
						border:2px solid $white;
						padding: 6px 10px;
						@include border-radius(0);
						text-transform:uppercase;

						&:hover {
							background:rgba($white, 0.1);
						}
					}
				}
			}
		}

		.video-wrapper {
			a.close {
				top:20px;
				right:20px;
			}
		}

		#circle-shelf {
			position: relative;
			min-height: 570px;

			div.background {
				bottom:60px;
				div.left, div.right {
					display: none;
				}
				div.center {
					background:url(/images/circle/circle-shelf-small.jpg) center bottom no-repeat;
					width:100%;
					height:100%;
					position: relative;
					z-index:2;
				}
			}

			img {
				&.circle {
					position: absolute;
					bottom:93px;
					width:149px;
					height:auto;
				}

				&.cow {
					margin-left:80px;
					bottom:93px;
					width: 264px;
					height:auto;
				}

				&.flower {
					margin-right:10px;
					bottom:92px;
					width:366px;
					height:auto;
				}
			}

			div.container {
				//bottom:0px;
			}

			div.content {
				position:relative;
				top:0;
				left:0;
				width:100%;
				padding:0 40px;
				margin-top:135px;
				margin-left:0;
				text-align: center;

			}
		}

		.top-panel {
			top:0;
			margin-top:-60px;
		}

		#circle-home {
			padding-top:0;

			div.foreground {
				position: relative;
			}

			div.background {
				position:relative;
				overflow: hidden;

				a.play {
					display: block;
				}

				img {
					//display: none;
					position: relative;
					top:0;
					//left:0;
					//z-index: 1;
					pointer-events:none;
				}

				.video-wrapper {
					a.play {
						display: block;
					}
				}
			}

			div.content {
				position:relative;
				z-index: 3;
				left:0;
				top:0;
				width:100%;
				text-align: center;
				padding: 40px 40px 20px 40px;
				color:$black;

				a.action {
					display: none;
				}
			}

			.container .row > div {
				text-align: center;
				padding: 0 40px;
			}

			.slide {
				overflow: hidden;
			}

			.owl-dots {
				bottom:5px;
			}
		}

		#circle-phone {
			div.phone-video {
				margin-bottom:20px;

				img.phone {
					width:100%;
				}

				div.video-wrapper {
					width:70%;
					height:auto;
				}
			}

			div.features {
				display: block;

				> div {
					border:none;
				}

				.feature {
					display: block;
					float:none;
					padding: 20px 40px;
				}
			}
		}

		#circle-be-home {
			padding-top:0px;

			p  {
				font-size: 1.125em;
				line-height: 1.625em;
				padding: 0 40px;
				color:$black;
			}

			div.container {
				position:relative;
			}

			div.content {
				top:0px;
				padding-top:40px;
				padding-bottom:40px;
				position:relative;
			}

			.slide:first-child {
				ul.links {
					margin:0;
					padding:0;
					li {
						display: none;

						&:first-child {
							display: inline-block;
						}

						a {
							pointer-events: none;
							span.icon {
								display: block;
								margin:0 auto 14px auto;
							}

							span.label {
								display: block;
								margin: 0 auto;
								text-align: center;
							}
						}
					}
				}
			}

			p {
				font-family: "Brown-Pro-Light";
			}

			.owl-dots {
				bottom:5px;
			}

			div.slide {
				overflow: hidden;
			}


			div.video-wrapper {
				a.play {
					-ms-transform: translateY(-50%);
				    top: 50%;
				}
			}
		}

		#circle-get-started {

			div.background {
				img {
					right:0%;
				}
			}

			div.foreground {
				padding-top:40px;
				padding-bottom:40px;
			}
		}
	}
	div.slide .info ul {
		margin-left: auto;
    	margin-right: auto;
  		width: 275px;
	}
}
@media (max-width: 700px){
	body.circle-landing {
		h1 {
			font-size: 3.375em;
			font-size:50px;
			line-height: 0.85 !important;
		}
		#circle-shelf .container div.content {
			margin-top: 60px;
		}
	}
}
@media (max-width: 600px){
	body.circle-landing {
		h1 {
			font-size: 3.375em;
			font-size:50px;
			line-height: 0.85 !important;
		}

		h2 {
			font-size:2.25em;
			font-size:11.25vw;

			&.alt {
				font-size:2.25em;
				font-size: 6vw;
			}
		}

		p {
			font-size:1.125em;

		}
		#circle-get-started {
			div.background {
				img {
					right:-20%;
				}
			}
		}
	}
}


@media (max-width: 400px){
	body.circle-landing {
		#circle-shelf {
			div.content {
				margin-top: 58px;
				padding:0 10px;
			}
			p {
				line-height: 18px;
				padding-top: 5px;
			}
		}
	
		#circle-phone {
			div.phone-video {
				img.phone {
					&.large {
						display: none;
					}

					&.small {
						display: block;
						width:100%;
					}
				}
				div.video-wrapper {
					width:210px;
					height:124px;
				}
			}
		}
	}
}

