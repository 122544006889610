/* ==Careers */
.careers {
  p.lead {
    font-weight: normal;
  }
  h2.title {
    font-size: 28px;
  }
  .copy h2 {
    margin-top: 0;
  }

  .grid .thumbnail.active {
    background: #0088cc;
    color: #ffffff;
  }
  .btn-primary.btn-sm {
    width: 100px;
  }
  .grid {
    .thumbnail.active * {
      color: #ffffff;
    }
    .thumbnail img {
      max-width: 64px;
      width: 100%;
    }    
    
    &.people div[class*="col-"] {
		padding-left: 5px;
		padding-right: 5px;
		max-width: 82px;
		min-width: 82px;
		width: 82px;
    }
    .owl-stage-outer {
	    .thumbnail{
		    &.active * {
		      color: #ffffff;
		    }
		    img {
		      max-width: 100%;
		    } 
		    span {
			    font-size: 14px;
			    color: #000;
			    padding: 10px;
			    display: block;
			    height: 60px;
			    overflow: hidden;
			    margin-bottom: 5px;			    
		    } 
		}
    }
    
    
    
    
  }
  .videos div[class*="col-"] .thumbnail h6 {
    display: block;
    height: 65px;
    padding: 5px;
    overflow: hidden;
    font-size: 14px;
  }
  .owl-prev {
    left: 8px;
    position: absolute;
    top: 0;
  }
  .owl-theme .owl-controls {
    .owl-buttons div {
      background-color: #08c;
    }
    margin-top: 0 !important;
  }
  .owl-next {
    position: absolute;
    right: 8px;
    top: 0;
  }
	a.videoref {
	    display: block;
	    margin: 0 auto;
	    min-height: 340px;
	}  
	.c-promos {
		min-height: 280px;
		display: block;
	}
  
}

@media only screen and (max-width: 1024px) {
  .careers {
		a.videoref {
		    min-height: 270px;
		} 
		.c-promos {
			min-height: 380px;
			display: block;
		}
	}	
	 
}
@media only screen and (max-width: 599px) {
  .careers {
    .grid {
      .col-xs-3 {
        width: 25%;
        float: left;
        clear: none;
      }
      .col-xs-4 {
        width: 33.3333%;
        float: left;
        clear: none;
      }
      .col-xs-6 {
        width: 50%;
        float: left;
        clear: none;
      }

	 }       
		a.videoref {
			img {
				max-width: 240px;
			}
		}       
    }
    .videos div[class*="col-"] .thumbnail h6 {
      display: block;
      height: 65px;
      overflow: hidden;
      font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
  .careers .grid .col-xs-3 {
    width: 33.3333%;
    float: left;
    clear: none;
  }
}
@media (min-width: $screen-sm-max) {
   .careers .videos div[class*="col-"] .thumbnail h6 {
		height: 55px;
	}
}
/* ==End Careers */