.subnav-grid-tiles {
	position: relative;
	@media (min-width: $screen-sm-min) {
		margin-bottom: 4%;
		margin-top: 4%;
	}

	.subnav-color-tile{
		@include points("font-size", 13);
		position: absolute;
		height: 150px;
		color: $white;
		text-transform: uppercase;
		position: relative;
		overflow: hidden;
		display: block;
		transition: all 0.5s linear;
		background-color: #1194f6;

		.subnav-title {
			margin: 30px 0;
		}


	}
}
