body.plp{
	.content.container {
		margin-bottom:0;
	}

	.content.container.wide {
		//max-width:2000px;
		width:100%;
		padding:0;

		&.hero {
			overflow:hidden;

			.background-image {
				height:560px;
				background-size:cover;
				background-position:center center;
				background-repeat:no-repeat;

			}
		}
	}

	.content.container.hero {
		margin-bottom:55px;
	}

	p {
		font-size:1.125em;
		line-height: 1.5;
		font-family: "Brown-Pro-Light";
	}

	.icon-circle {
		.icon-awards{
			width: 37.5%;
		}

		.icon-house {
			width:57.5%

		}

		.icon-pie-chart {
			width:53.75%

		}
	}

	div.feature-grid {
		border-top:1px solid $light-gray;
		border-bottom:1px solid $light-gray;
		display: flex;
		align-items:stretch;
		margin-top:80px;


		> div {
			padding:50px 50px 80px 50px;
			border-right:1px solid $light-gray;
			position:relative;
			&:last-child {
				border-right:none;
			}
		}

		div.link {
			position:absolute;
			bottom:45px;
			left:0;
			right:0;
			text-align: center;
		}

		a {
			color:$blue;
			font-size:1.125em;
			font-family: "Brown-Pro-Light";

			&:hover, &:active, &:focus {
				text-decoration: none;
				border-bottom:1px solid $blue;
			}
		}

		h4 {
			text-transform: uppercase;
			font-size:1.125em;
			font-family: "Brown-Pro-Bold";
			margin-top:24px;
		}

	}

	div.links {
		padding:48px 0 24px 0;
		margin-top:0;
	}

	ul.link-list {
		margin:0;
		padding:0;
		li {
			margin-bottom:0 !important;
		}

		a {
			margin:6px 45px;
			color:$blue;
			display: inline-block;
			text-decoration: none;

			&:hover, &:focus, &:active {
				text-decoration: none;
				span {

					text-decoration: none;
					border-bottom:1px solid $blue;
				}
			}

			span {
				text-transform: uppercase;
				color:inherit;
				line-height: 1;
				font-family: "Brown-Pro-Bold";
				vertical-align: middle;
				border-bottom:1px solid transparent;
			}

			.newsletter-icon,
			.contact-icon,
			img, svg {
				display: inline-block;
				margin-right:14px;
			}
		}
	}

	h2 {
		text-transform: none;
	}

	.products-container {
		margin-top:30px;
	}
}

@media (max-width: $screen-sm-max) {
 	body.plp {
 		div.feature-grid {

 			display: block;
 			border-bottom:none;
 			> div {
 				border:none;
 				border-bottom:1px solid $light-gray;
 				padding:50px;

 				div.link {
					position:relative;
					bottom:auto;
					left:auto;
					right:auto;
					width:100%;

				}

 			}

 		}
 	}
 }

@media (max-width: $screen-xs-max) {
 	body.plp {



 		.content.container.hero {
			margin-bottom:15px;
		}

 		div.feature-grid {
 			> div {
 				padding:20px 0;

 			}

 		}

 		div.links {
			padding:42px 0 18px 0;
		}

 		ul.link-list {
 			li {
 				width:100%;
 				text-align: center;
 			}
 			a {
 				margin: 12px 12px;
 				justify-content: center;



 			}
 		}
 	}
 }

 @media (max-width: 480px) {
 	body.plp {
 		h1 {
 			font-size:32px;
 			font-size:10vw;

 		}

 		ul.link-list {
 			li {
 				display: block;
 				width:100%;
 			}
 		}
 	}
 }
