#seven {
  transform: translate3d(0, 0, 0); //hardware accelerate for iOS7
  position: relative;
}

body.home {
  opacity: 0;
}
.home .section {
  opacity: 0;
  filter: alpha(opacity=0);
}
