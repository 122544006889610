//Module 1
	#circle-shelf {
		background: white;
		section {
			position: static;
			height: auto;
			overflow: visible;	
			p{
				font-size: 18px;
				padding-right: 25px;
				@media (max-width: 767px) {
					padding-right: 0;
				}
			}		
		}
		h1 {
			@media (min-width: 768px) {
				padding-bottom: 20px;
				letter-spacing: -1px;	
				    width: 100%;	
			}
		}
		.container {
			left: auto;
			position: relative;
			width: 1240px;
		}
		.content {
			position: relative;
			top: 50%;
			-webkit-transform: translateY(-50%);
		    -ms-transform: translateY(-50%);
		    transform: translateY(-50%);
		    left: auto;
			color: white;
			padding-left: 20px;
			width: 410px;
			
			a.action {
   				position: relative;
   				display: inline-block;
   				padding-top: 5px;
   				span:first-child {
   					background: #00ead0;
   					width: 50px;
   					height: 50px;
   					-webkit-flex: 0 0 55px;
				    -ms-flex: 0 0 55px;
					flex: 0 0 55px;
				    line-height: 49px;
   					&:before {
   						color: white;
   					}
   				}
   				span + span {
   					color: white;
   				}   				
			}
			
			
		}
		div.background {
			height: 100%;
			div.center {
				background: url(http://www.logitech.com/assets/64228/10/circle-pdp-2016.jpg) 50% 0% no-repeat;
				background-size: cover;
				@media only screen and (min-device-width: 320px) 
				and (max-device-height: 568px) 
				and (orientation: portrait) 
				and (-webkit-device-pixel-ratio: 2) {
					 background: url(http://www.logitech.com/assets/64228/25/circle-pdp-2016.jpg) no-repeat !important;
					 background-size: cover !important; 					

				}
				 @media (max-width: 767px) and (orientation: landscape) {
				 	 background: url(http://www.logitech.com/assets/64228/25/circle-pdp-2016.jpg) center no-repeat; 
				 	 background-size: cover; 
				 }
				 @media (max-width: 767px) and (orientation: portrait) {
				 	 background: url(http://www.logitech.com/assets/64228/25/circle-pdp-2016.jpg) no-repeat; 
				 	 background-size: cover;
				 }
			}
		}
		
		div.buy-now {
			position: relative;
			padding: 20px 0;		
			
			span {
				font-size: 26px;
				line-height: 48px;
			    font-family: "Brown-Pro-Light";
				sup {
					font-size: 18px;
					line-height: 47px;
				}
				&.original-price {
					position: relative;
					padding: 0 5px 0 10px;
					
				&:after {
					content: "";
					border-top: 1px solid white;
					width: 90%;
					position: absolute;
					z-index: 1;
				     right: 7%;
   					 top: 14px;
					} 
				}
				&.current-price {
					padding: 0 5px;
					sup + sup {
						padding-left: 5px;
					}
				}

			}
		}
		div.watch-video {
			position: relative;
			display: none;
			a.action {
				margin-right: 20px;
				font-family: "Brown-Pro-Regular";
				letter-spacing: 1px;
				.dot.glyphicon {
					padding-left:5px;
				}
			}
			a.action + a.action{
				position: absolute;
				right: 50px;
				.dot {
					margin-right: 15px;
					svg {
						margin-top: 3px;
						vertical-align: middle;
					}
				}
			}
			
		}
	}

	@media (max-width: 767px) {
		#circle-shelf {
			min-height: auto;

			div.background {
				top: 0;
				bottom: 0;
			}
			.container {
				width: 100%;
				
				div.content {
					margin: 0;
					top: 0;
					width: 100%;
					height: 100%;
					padding: 0;
					text-align: center;
					position: relative;
					transform: none;
					a.action, .buy-now {
						top: 10px
					}
					h1 {
						padding: 70px 0;
						font-size: 2.5em;
						text-align: center;
						letter-spacing: -1px;
						display: inline-block;
						width: 100%;
					}
					section {
					    bottom: 10%;
   						transform: translateY(10%);
   						position: absolute;
					}

				}
			}
			
			div.buy-now {
				padding: 5px 0;
			}	
			
			
		}
	}
	@media (min-width: 991px) and (max-height: 600px) {
		#circle-shelf {
			div.container div.content {
				div.buy-now {
					padding: 5px 0;
					top: 0;
				}
			}
		}
	}
	@media (min-width: 320px) and (max-height: 650px) and (orientation: portrait) {
		#circle-shelf {
			min-height: 650px;
			.container {
				width: 100%;
				
				div.content {
					section {
					    bottom: 8%;
   						transform: translateY(10%);
   						position: absolute;
					}

				}
			}
		}
	}
	
	
	@media only screen and (max-device-width: 600px) 
	and (orientation: portrait) {
		#circle-shelf {
			div.container div.content {
				div.buy-now {
					padding: 5px 0;
					top: 0;
				}
			}
		}
	}
	@media only screen and (max-device-width: 400px) 
	and (orientation: portrait) {
		#circle-shelf {
			div.watch-video a.action {
				margin-right: 0;
			}
			div.watch-video  a.action span.dot {
				margin-right: 5px;
			}
			div.watch-video  a.action.buy-now span.dot {
				margin-right: 10px;
			}
		}
	}
	@media (max-width: 767px) and (orientation: landscape) {
		 #circle-shelf {
		 	min-height: 768px;
		 }
	}