#circle-get-the-app {
	background-color: $teal;
	
	.container {
		padding: 50px 0 20px 0;
		text-align: center;
		h2 {
			color: white;
			margin: 0 0 30px 0;
		}
		
		>p {
			text-align: center;
			width: 80%;
			display: inline-block;
		}
		h3 {
			font-size: 20px;
			font-family: "Brown-Pro-Bold";
			margin: 0 0 10px 0;
			& + p {
				font-size: 16px;
			}
		}
		>div.row {
			position: relative;
			overflow: hidden;	
		}
		div.col-xs-12 {
			position: static;
			padding: 0 20px;
			p {
				margin: 0;
			}
		}
		
		div.col-xs-12:nth-of-type(1):after, 
		div.col-xs-12:nth-of-type(2):after, 
		div.col-xs-12:nth-of-type(3):after {
			content: '';
			position: absolute;
			height: 100%;
			top:0;
			width: 33.33%;	
			@media (max-width: 767px){
				display: none;
			}		
		}
		div.col-xs-12:nth-of-type(1):after {
			left:0;	
			border-right: 1px solid #d9dadc;		
		}
		div.col-xs-12:nth-of-type(2):after {
			left: 33.33%;
			border-right: 1px solid #d9dadc;
		}
		.row.text-center {
			padding: 0 0 20px 0;
			 p:first-child {
				font-size: 16px;
				font-family: "Brown-Pro-Bold";
			}
			 p:last-child {
				font-size: 16px;
				padding-top: 30px;
				margin: 0;
			}
		}
	}
	
	#downloads {
		margin: 40px auto 80px;
		.badges{
			max-width: 100%;
			width:auto;
			height: auto;
		}
		
	}	
}	
@media (max-width: 767px) {
	
	#circle-get-the-app {
		.container {
			padding: 25px 0;			
			h3 {
				line-height: 34px;
				margin: 0;
			}
			div.col-xs-12 {
				padding: 15px 0;
			}
			div.col-xs-12:nth-of-type(1) {
				border-right: none;
			}
			
			div.col-xs-12:nth-of-type(3) {
				border-left: none;
			}
		}	
	}	
}