.hero-tile {
	position: relative;
	color: $dark-gray;
	background-color: $white;
	display: block;
	&:hover {
		color: $dark-gray !important;
		text-decoration: none;
	}
	border-top: 2px solid $light-gray;
	padding-top: 30px;
	height: 520px;


	@media (min-width: $screen-sm-min) {
		padding-top: 90px;
		border-top: none;
		height: 435px;
	}

	/* Move featured tile below first three tiles at smallest breakpoint */
	/* TODO */

	/* Move featured tile below first row of tiles*/
	@media (min-width: $screen-sm-min) {
		z-index: 2;
		height: 370px;
	}
	@media (min-width: $screen-md-min) {
		height: 460px;
	}
	@media (min-width: $screen-lg-min) {
		height: 520px;
	}

	.hero-image {
		position: absolute;
		bottom: 0;
		width: auto;
		@media (max-width: $screen-xs-max) {
			bottom: 0;
			max-height: 310px;
			max-width: 100%;
		}

		@media (min-width: $screen-sm-min) {
			top: auto;
			bottom: 0;
			right: -10px;
			max-height: 400px;
		}

		@media (min-width: $screen-md-min) {
			top: auto;
			bottom: 0;
			max-height: 510px;
		}

		@media (min-width: $screen-lg-min) {
			top: -30px;
			right: 50px;
			bottom: auto;
			max-height: 550px;
		}
	}

	.hero-metadata {
		margin: 0 20px 0 0;
		position: absolute;
		top: 0;
		@media (min-width: $screen-sm-min) {
		  top: 30px;
		  width: 420px;
		}
		@media (min-width: $screen-md-min) {
		  top: 25px;
		}
		@media (min-width: $screen-lg-min) {
		  top: 60px;
		}
	}

	@media (max-width: $screen-xs-max) {
		.hero-name-prefix {
			margin: 16px 0 0 0;
		}
	}

	.hero-name {
		font-family: "Brown-Pro-Bold";
		text-transform: uppercase;
		@include points("font-size", 40);
		@include points("line-height", 18);

		@media (min-width: $screen-md-min) {
			@include points("font-size", 40);
			@include points("line-height", 18);
		}

		@media (min-width: $screen-lg-min) {
			@include points("font-size", 60);
			line-height: 65px;
		}
	}

	.learn-more {
		background-color: $blue;
		border: none;
		box-shadow: none;
		color: $white;
		display: inline-block;
		text-decoration: none;
		text-transform: uppercase;
		@include points("font-size", 12);
		padding: 20px 30px;
	}

	.price {
		display: inline-block;
		padding-left: 15px;
	}
}


.touch .hero-tile {

	top: 1650px; /* Make room for product-info on touch */
	@media (min-width: $screen-sm-min){
		top: 615px;
	}
}
