.additional-features {
    margin:0 auto;

    @media (min-width: $screen-lg-min) {
        max-width:1240px;
    }

    .container {
        color:$white;
        background-color: $dark-gray;
        margin:0;
        padding:0;

        @media (min-width: $screen-md-min) {
            margin-right: 10px;
            margin-left: 10px;
            width:auto;
        }
    }

    // Header
    .additional-features-header {
        padding: 12px;
        text-align: center;
        cursor: pointer;

        h6 {
            position: relative;
            text-transform: uppercase;

            // Carrat
            &:after {
                content: " ";
                display: inline-block;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 8px solid $white;
                z-index: 1;
                padding: 0;
                vertical-align: top;
                margin: 4px 0 0 11px;
            }
        }
    }

    .additional-features-body {
        padding-bottom: 50px;
    }

    .additional-features-list {
        margin:0 39px;
        padding:0;
        list-style:none;

        @media (min-width: $screen-md-min) {
            margin:0 5.2%;
            columns:2;
            column-gap:12em;
        }

        li {
            padding-bottom:1.5em;

            overflow: hidden;                   /* Fix for firefox and IE 10-11  */
            -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
            page-break-inside: avoid;           /* Deprecated Firefox */
            -moz-column-break-inside: avoid;    /* Current Firefox */
            column-break-inside: avoid;
            break-inside: avoid;                /* IE 10+ */

            h3 {
                font-size: 1.625em;
                color: $white;

                @media (min-width: $screen-md-min) {
                    font-size: 2em;
                }
            }

            .feature-content {
                font-size: 0.875em;
                line-height: 1.71429em;
                color: $gray;
                img {
	                display: none;
                }
                table {
	                center {
		                display: none;
	                }
                }

                .additional-feature-footnote {
                    font-size: .9em;
                    font-style:italic;
                    line-height: 1.3em;
                }
            }
        }
    }



    &.open {
        .additional-features-header {
            h6 {
                // Carrat
                &:after {
                    border-top: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 8px solid $white;
                    margin: 5px 0 0 11px;
                }
            }
        }
    }
}
