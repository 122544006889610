
.link-bar {
	padding:48px 0 24px 0;
	margin-top:0;

	ul.link-list {
		margin:0;
		padding:0;
		li {
			margin-bottom:0 !important;
		}

		a {
			margin:6px 45px;
			color:$blue;
			display: inline-block;
			text-decoration: none;

			&:hover, &:focus, &:active {
				text-decoration: none;
				span {

					text-decoration: none;
					border-bottom:1px solid $blue;
				}
			}

			span {
				text-transform: uppercase;
				color:inherit;
				line-height: 1;
				font-family: "Brown-Pro-Bold";
				vertical-align: middle;
				border-bottom:1px solid transparent;
			}

			.newsletter-icon,
			.contact-icon,
			img, svg {
				display: inline-block;
				margin-right:14px;
			}
		}
	}
		
		
	@media (max-width: 480px) {
		ul.link-list {
			li {
				display: block;
				width:100%;
			}
		}
	}
		
		
		
}

