.searchresults-left-menus {
	margin: 5% auto;
	position: relative;
	@media (min-width: $screen-sm-min) {
		margin-bottom: 2%;
		margin-top: 5%;
		max-width: 300px;
		padding-left: 20px;
		padding-right: 0;
		float: left;
	}

	.searchresults-menu-item {
		@include points("font-size", 12);
		min-height: 50px;
		// max-height: 75px;
		background-color: #ededed;
		color: $dark-gray;
		text-transform: uppercase;
		position: relative;
		overflow: hidden;
		display: block;
		vertical-align: middle;
		transition: all 0.5s linear;
		border-bottom: 2px solid $white;
		@media (max-width: $screen-sm-max) {
			display: inline-block;
			margin: 0;
			float: left;
			width: 100%;
			max-width: 100%;
		}
		// @media (max-width: $screen-sm) {
		// 	max-width: 50%;
		// }						
		.arrow-right {
			width: 0; 
			height: 0; 
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-left: 6px solid black;
		    position: absolute;
		    top: 23px;
		    right: 28px;
		    opacity: 0;
			transition: opacity 0.5s linear;
		}

		&.active {
			background-color: $yellow;
			// &:after, 
			.arrow-right {
				opacity: 1;
				text-decoration: none!important;
			}
		}
		&:hover {
			background-color: $yellow;
			// text-decoration: none;
			// &:after, 
			.arrow-right {
				opacity: 1;
				text-decoration: none!important;
			}
		}
		// &:after {
		//     content: "\25B6";
		//     @include points("font-size", 12);
		//     position: absolute;
		//     top: 23px;
		//     right: 28px;
		//     opacity: 0;
		// 	transition: opacity 0.5s linear;
		// }

		.subnav-title {
			margin: 21px 28px;
			padding-right: 8px;
			display: block;
		}
	}
}

.touch {
	.searchresults-left-menus {
		.searchresults-menu-item {
			&:hover {
				background-color: #ededed;
				// &:after,
				.arrow-right {
					opacity: 0;
				}
			}
			&.active {
			background-color: $yellow;
				// &:after, 
				.arrow-right {
					opacity: 1;
					text-decoration: none!important;
				}
			}
			// &:after,
			.arrow-right {
			    // content: "\25B8";
			    // @include points("font-size", 18);
			    // top: 19px;
			}
		}
	}
}

.searchresultsMenuTitle {
	font-style:italic;
	@include points("font-size", 18);
//	font-style: italic;
//	font-weight: normal;
	color: #999999;
	margin: 0 0 21px 28px;
}