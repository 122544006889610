#hero-messaging {
	padding-top: 2%;
	p {
	    font-size: 1.125em;
	    line-height: 1.5;
	    font-family: "Brown-Pro-Light";		
	}
	> .row {
		margin: 0 auto 2%;
	}
	> .row:last-child {
		margin-bottom: 4%;
	}
	.logos-bar {
		img {
			margin: 0 12px;
		}
		
	}
	
	 @media (max-width: 480px) {
 		h1 {
 			font-size:32px;
 			font-size:10vw;

 		}
	 }
 }

