.main-hero {
	color: $dark-gray;
	z-index: 2;
	background-color: $white;
	display: block;
	position: relative;
	@media(max-width: $screen-xs-max) {
		margin-bottom: 10px;
		//height: 300px;
	}
	@media(min-width: $screen-sm-min) {
	}

	&:hover {
		color: $dark-gray;
		text-decoration: none;
	}

	.mhero-image {
		position: relative;
		width: 100%;
		//height: 500px;
		margin-top: 0;
		
		@media(min-width:1025px){
			//height: 460px;
		}		
		@media(max-width:1024px){
		}		
		img {
		    margin: 0 auto;
		    max-width: 1240px;
		    width: 100%;
			@media(max-width:768px){
			}		    
		}

		
		
	}
    @media (max-width: $screen-xs-max) {
      .mhero-image {
       // height: 335px;
      }
    }
 

	.mhero-meta {
			.mhero-info {
				top: 350px;
				max-width: 40%;
				padding: 0 10px;
				margin: 0;	
				position: absolute;
	
				&.topLeft, &.topRight {
					top: 4%;
					bottom: inherit;
				}
				&.bottomLeft, &.bottomRight {
					top: inherit;
					bottom: 4%;
				}				
				&.topLeft, &.bottomLeft {
				    left: 0;
				    right: inherit;
				}
				&.topRight, &.bottomRight {
				    left: inherit;
				    right: 0;
				 }			 
				@media(max-width: 1200px){
					max-width: 50%;
				}
				@media(max-width: 1024px){
					&.topLeft, &.bottomLeft {
						left: 0;
					}
					&.topRight, &.bottomRight {
						right: 0;
					}					
				}				
				
				@media(max-width:768px){
					top: 330px;
					left: 20px;
					position: relative;
					max-width: 100%;
					&.topLeft, &.bottomLeft,
					&.topRight, &.bottomRight {
						top: 0;
						bottom: inherit;
						left: inherit;
						right: inherit;
					}
				}
			    @media (max-width: $screen-xs-max) {
   
			    }

				.mhero-name,
				.mhero-description,
				.mhero-link {
					display: block;
				}

				.mhero-name {
					font-weight: bold;
					letter-spacing: 1px;
					text-transform: uppercase;
					line-height: 1.2;
					
					
					
					
					&.super-size {
						font-size: 7em;
						line-height: 1;
						white-space: normal;
						-ms-word-break: break-all; 
						word-break: break-all; 
						word-break: break-word; 
						-o-hyphens: auto; 
						-ms-hyphens: auto; 
						-moz-hyphens: auto; 
						-webkit-hyphens: auto; 
						hyphens: auto;           
						@media(max-width: $screen-sm-max){
	  					font-size: 2.5em;
						}
					}
					&.mini-size {
						font-size: 2.5em;
					}	
				    @media (max-width: $screen-xs-max) {
						font-size: 2.5em;
				    }	
					font-size: 40px;
					@media(min-width: $screen-sm-min) { 
						font-size: 3.8vw;
					}
					// controls min-width: 992px;	
					@media (min-width: $screen-md-min) {
						font-size: 4vw;
					}
					// min-width: 1260px;	
					@media (min-width: $screen-lg-min) {
						font-size: 3vw;
					}
					// min-width: 1600px;	
					@media (min-width: 1600px) {
						font-size: 50px;
					}					    
							    
				    
				    								
				}

				.mhero-description, 
				.mhero-link {
					font-family: "Brown-Pro-Regular";
					color: $inbetween-gray !important;
					@include points("font-size", 16);
				}
				.mhero-description{
					margin-top: 2px;
					color: $black !important;
				}
				
			}

		}

		.pre-order {
			color: $white !important;
			background-color: black;
			width: 100px;
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 10px;
			@include points("font-size", 10);
			text-align: center;
		}
	}

.no-touch {
	.mhero-description{
		display: block;
	}


	.force-white-text:not(.strikethrough) *{
		@media (max-width: $screen-xs-max){
			color: $dark-gray !important;
		}
	}
}


.touch .main-hero{
	//height: 620px;
	background: none;
	@media(max-width:768px){
		.mhero-image{
			max-height: 270px;
			overflow: hidden;
		}
	}
	
	.mhero-meta{
		.mhero-info{
			left: 8px;
				@media(max-width:768px){
					left: 20px;
					position: relative;
					max-width: 100%;
					&.topLeft, &.topRight, 
					&.bottomLeft, &.bottomRight,
					&.topLeft, &.bottomLeft,
					&.topRight, &.bottomRight {
						top: 0;
						bottom: inherit;
						left: inherit;
						right: inherit;
					}
				}			
			.mhero-description{
				display: block;
			}			
			
		}

	}

}

.touch {
	.microsite-template {
		.main-hero {
			.mhero-meta {
				.mhero-info {
					left: 0;
				}
			}
		}
	}
}
			
@media (max-width: $screen-xs-max) {
  .touch {
    .main-hero {
     // height: 550px;
    }
  }
}
