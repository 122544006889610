// Footer
.product-support {
  text-align:center;
  margin: 0 13.4375% 50px;
  h3 {
    font-family: "Brown-Pro-Lightitalic";
   // font-size: 1.625em;
    line-height: 1.307692308em;
    margin-bottom: 16px;
  }
  .btn-tech-support {
    @include btn-util($white, $blue);
    text-transform: uppercase;
    font-size: 0.75em;
  }
  @media (min-width: $screen-sm-min) {   
    h3 {
    //  font-size: 2em;
      line-height: 1.4375em;
      margin-bottom: 22px;
    }
  }
  @media (min-width: $screen-md-min) {
      margin: 0 13.4375% 85px;
  }
}