#mobile-nav{
    @include styled-global;
  	position: fixed;
	top: 0;
	width: 48px;
	height: 10%;
	z-index: 4000;

	&.open {
		height: 100%;
		width: 100%;
		z-index: 4001;
	}

	#nav-icon-mobile {
		position: absolute;
		display: block;
		height: 25px;
		top: 20px;
		left: 20px;
		cursor: pointer;
		z-index: 4000;
		@media (min-width: $screen-sm-min){
			top:26px;
		}		
		img {
			width: 85%;
		}
	}

	#mobile-nav-menu {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $dark-gray;
		z-index: 4000;

		@media (max-width: 736px)
		and (orientation: landscape){
			// overflow: scroll;

		ul.links{
			position: relative;
			left: 0;
			right: 0;
/*			top: 180px !important;
*/		}
		}
	}

	#utility-subnav {
		margin: 20px 20px 14px 0;
		@media (max-width:$screen-sm-max) and (orientation: landscape){
			margin-bottom: 0;
		}			
		ul{
			list-style-type: none;
			li {
				display: block;
				float: left;
				@include points("padding-left", 22);
			}
		}
	}

	img.logo {
		display: block;
		margin:65px auto 15px;
	}

	div.scroll-list {
		clear: both;
		//@include vertically-centered;
		//top: 45% !important; //override normal vertical 50% from include for this use.
		margin: 0;
		position:absolute;
		top:110px;
		bottom:0;
		left:0;
		right:0;
		width:100%;

		//max-height:80%;
		overflow-y:auto;
		-webkit-overflow-scrolling: touch;	// https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling

	}

	ul.links {
		padding: 0;
		list-style-type: none;
		li {
			padding: 15px 0;
			text-align: center;
			display: block;
			a {
				text-transform: uppercase;
				color: $yellow-green;
				text-decoration: none;
				padding: 0 15px;
				display: inline-block;
			}
		}

		> li {

			&.open {
				background-color: $medium-gray;
				padding-bottom: 0;
				div.sub {
					max-height:400px;
				}
			}

			div.sub {
				display: block;
				max-height:0;
				overflow: hidden;
				@include transition(all 250ms ease-in-out);

				ul {
					padding:0;
					margin:15px 0 0 0;
					background-color: $medium-gray;

					a {
						color:$white;
						&:hover {
							color:$yellow-green;
							text-decoration: none;
						}
					}
				}
			}

		}
	}
}

body.home #mobile-nav div.scroll-list {
	overflow-y: auto;
	-webkit-overflow-scrolling: auto;	// https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
}

#mobile-nav	.search-widget {
	width: 100%;
	// height: 48px;
	height: 100%;
	margin: 0;
	position: absolute;
	top: 59px;
	left: 0;
	// background-color: red;
//		overflow: hidden;

	.search-bg {
		background-color: #fff;
		width: 100%;
		height: 48px;
		display: block;
		position: absolute;
		top: 0;
	}
	.close-icon {
		@media (max-width: 736px) 
		and (orientation: portrait){
			margin: 7px 8px 0 0;
			position: absolute;
		}
		// @media (max-width: 736px) 
		// and (orientation: landscape){
		// 	margin: auto;
		// 	position: relative;
		// }
	}
	.search-icon {
		top: 8px;
	}
	form input {
		width: 80%;
	}
	form hr {
		color: black;
		// top: 24px;
	}
	.tt-menu {
		// width: 100%;
		// background-color: #fff;
	}
	.scroll-results {
		// margin-top: -7px;
		// height: 100%;
		// width: 100%;
		// background-color: #fff;
		// height: 370px;// visible height.
	}
	.results {
		height: 100%;
		overflow-y: scroll;
		max-width: 100%;
		-webkit-overflow-scrolling: touch;
		&.show {
			padding-top: 0;
		}
		.result {
			height: auto;
			.left {
				margin-left: 15px;
			}
			.right {
				width: auto;
				max-width: 180px;
				padding-left: 10px;
				// margin-right: 15px;
				.bundle {
					margin: 0;
				}
				p {
					opacity: 1;
				}
			}
		}
	}
}