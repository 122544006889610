.promos-container {
  position: relative;
}

#promo-grid {
  margin-bottom: 80px;
  position: relative;

 	@media(min-width: $screen-md-min) {
		&.uncategorized.unsorted {
			.promo-tile {
				z-index: 3;
				&:nth-child(2), &:nth-child(3) {
					z-index: 0;
				}
			}
		}
	}

  &.uncategorized.unsorted.featured {
		.promo-tile {
			z-index: 3;
		 	@media(min-width: $screen-sm-min) {
				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 370px; /* height of feature-tile */
					z-index: 1;
				}
		 	}
		 	@media(min-width: $screen-md-min) {
				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 460px; /* height of feature-tile */
					z-index: 1;
				}
		 	}
		 	@media(min-width: $screen-lg-min) {
				&:nth-child(2), &:nth-child(3){
					margin-bottom: 520px; /* height of feature-tile */
					z-index: 1;
				}
			}
			@media (min-width: 1260px) {
				&:nth-child(2),
				&:nth-child(3) {
					/* height of feature-tile */
					margin-bottom: 520px;
					z-index: 1;
				}
			}
		}
	}

 	@media(min-width: $screen-lg-min) {
		&.uncategorized.unsorted {
			.promo-tile {
				z-index: 3;
				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					z-index: 1;
				}
			}
		}
	}
}



.promo-tile {
	color: $dark-gray;
	z-index: 2;
	background-color: $white;


	@media(max-width: $screen-xs-max) {
		&:nth-child(4) {
			margin-bottom: 0;
		}
	}
	@media(max-width: $screen-xs-max) {
		min-height:600px;
		margin-bottom: 10px;
	}
	@media(min-width: $screen-sm-min) {
		height: 570px;
	}

	&:hover {
		color: $dark-gray;
		text-decoration: none;
	}

	.promo-image {
		position: relative;
		width: 100%;
		text-align: center;
		margin-top: 0;
	    @media (min-width: 768px) {
			//height: 570px;
			height:100%;
		}

		/* remove this when cfm file is complete*/
		& > img {
		    margin-top: 30px;
		    max-width: 280px;
		    max-height: 280px;
		}
		& > a img {
			margin-top: 30px;
		    max-width: 280px;
		    max-height: 280px;
		}
	}
    @media (max-width: $screen-xs-max) {
      .promo-image {
        height: 350px;
      }
    }

	ul.color-swatches {
		margin: 0;
		padding: 0;
		list-style: none;
		position: absolute;
		top: 15px;
		top:11px;
		right: 7px;
		right: -11px;
		bottom: auto;
		left: auto;
		width: 60px;
		display: none;
		text-align: left;


		li.color-swatch {
			display: inline-block;
			overflow: hidden;
			width: 18px;
			height: 18px;
			margin: 3px 0;
			border: 1px solid #565656;
			img {
				width: 40px;
				height: 40px;
				margin-top: -11px;
				margin-left: -11px;
			}
			&.disabled {
				opacity: 0.35;
				filter: alpha(opacity=35);
				cursor: not-allowed;
			}
		}



	}



	@media(max-width: 768px) {
		&.reverse-colors, {
			.promo-meta {
				.promo-info {
					a {
						@include btn-util;
						text-decoration: none;
					}
				}
			}

		}
	}


	.promo-meta {
		@media(max-width: $screen-xs-max) {
			padding: 20px;
		}


			.promo-info {
				top: 350px;
				right: 30px;
				left: 30px;

				a {
					@include btn-util;
					text-decoration: none;
					margin-top: 5px;
					@media(max-width: 767px) {
						width: 100%;
					}
				}
				a:hover {
					color: #aaaaaa;
				}

				@media(max-width: $screen-xs-max){
					top: 330px;
					left: 20px;
				}
				@media(min-width: $screen-sm-min) {
					position: absolute;
				}
				padding: 0;
				margin: 0;

				.promo-name,
				.promo-description,
				.price,
				.strikethrough,
				.disclaimer{
					display: block;
				}

				.price,
				.strikethrough,
				.disclaimer{
					float: left;
				}

				.promo-name {
					@include points("font-size", 18);
				}

				.price {
					font-family: "Brown-Pro-Light";
				}

				.disclaimer {
					margin-top: -8px;
					min-width: 100%;

					&:before{
						content: " ";
						display: block;
						clear: both;
					}
				}

				.promo-description {
					font-style: italic;
					display: none;
					@include points("font-size", 14);
					@media(min-width: $screen-md-min) {
						@include points("font-size", 16);
					}
					@media(max-width: $screen-xs-max) {
						font-style: italic;
					}
				}

			}

			.learn-more, .cta {
				padding: 10px 15px;
				clear: both;
			}


		 	ul.color-swatches-mobile {
				margin: 10px 0;
				min-height: 25px;
				padding: 0;
				display: block;
				list-style: none;
				bottom: 0;
				clear: both;
				@media (min-width: 768px){
					display: none;
				}

				&:before{
					content: " ";
					clear: both;
					display: block;
				}

				li.color-swatch {
					display: block;
					overflow: hidden;
					width: 18px;
					height: 18px;
					float: left;
					margin: 2px;
					border: 1px solid #565656;
					img {
						width: 40px;
						height: 40px;
						margin-top: -11px;
						margin-left: -11px;
					}

				}
			}
		}

		.pre-order {
			color: $white !important;
			background-color: black;
			width: 100px;
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 10px;
			@include points("font-size", 10);
			text-align: center;
		}
	}

.no-touch {

	.promo-tile {
		&.reverse-colors:not(.strikethrough) .btn{
			@media (max-width: $screen-xs-max){
				color: yellow !important;
				&:hover {
					color: $gray !important;
				}
			}
		}
	}
}



.no-touch .promo-tile {
	.promo-description {
		display: block !important;
	}

	.color-swatches{
		display: block;
	}


}

.touch .promo-tile{
	height: 620px;
	background: none;
	.promo-meta{

		.promo-info{
			top:350px;
			left: 8px;
		}
		.color-swatches{display: none;}
		.color-swatches-mobile{display: block;}

		.promo-description{
			display: block !important;

			.no-touch .promo-description{
				@media(max-width: $screen-xs-max){
					display: block !important;
				}
			}

			.no-touch .promo-tile:hover {
				.promo-image {
					transition: background-color $hover-delay, color $hover-delay;
					@media(min-width: $screen-sm-min) {
						background-color: $white;
					}
					cursor: pointer;
				}
			}
		}
	}

	.pre-order{
		top: 356px;
		bottom: auto;
		@media (min-width: $screen-sm-min){
			top:426px;
		}
	}
}

@media (max-width: $screen-xs-max) {
  .touch {
    .promo-tile {
      height: 550px;
      //height: 410px;
      &:nth-child(4){
        margin-bottom: 0;
      }
    }
  }
}
/*
.touch .promo-tile{
	height: 620px;
	background: none;
	.promo-meta{
		.promo-info{
			top:472px;
			left: 8px;
		}
		.color-swatches{display: none;}
		.color-swatches-mobile{display: block;}

		.promo-description, .no-touch .promo-description{
			display: block !important;


		}
	}

	.pre-order{
		top: 356px;
		bottom: auto;
		@media (min-width: $screen-sm-min){
			top:426px;
		}
	}
}
@media (max-width: $screen-xs-max) {
  .touch {
    .promo-tile {
      height: 550px;

      &:nth-child(4){
        margin-bottom: 0;
      }
    }
    .featured {
      .promo-tile {
        &:nth-child(4){
          margin-bottom: 0;
        }
      }
    }
  }
}
*/