
$pillBgColor: #b2b0b4;
$pillFgColor: #00ead0;

$Orange: #CC3300;
$pillContainerHeight: 100px;

$pillRadius: 20px;

.pill-nav {
	
	position: relative;
	top: 150px;
	
	div.pill {
		
		height: $pillContainerHeight;
		 
		input[type="radio"] {
			display:none;
		}
		input[type="radio"] + label {
			color: black;
			font-family:Arial, sans-serif;
			font-size:14px;
		}
		input[type="radio"] + label span {
			display:inline-block;
			width:$pillRadius;
			height:$pillRadius;
			margin:-1px 4px 0 0;
			vertical-align:middle;
			cursor:pointer;
			-moz-border-radius:  50%;
			border-radius:  50%;
			z-index: 2;
			position: relative;
		}

		input[type="radio"] + label span {
			 background-color:$pillBgColor;
		}

		input[type="radio"]:checked + label span{
			 background-color:$pillFgColor;
		}

		input[type="radio"] + label span,
		input[type="radio"]:checked + label span {
		  -webkit-transition:background-color 0.4s linear;
		  -o-transition:background-color 0.4s linear;
		  -moz-transition:background-color 0.4s linear;
		  transition:background-color 0.4s linear;
		}
		
		&:after, &:first-child:before {
			content: " ";
			border-left: 1px solid $pillBgColor;
			height: $pillContainerHeight;
			position: absolute;
			z-index: 1;
			left: $pillRadius / 2;
		}
		&:first-child:before {
			top: $pillRadius - $pillContainerHeight;
		}
	}
}
