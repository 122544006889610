.subnav-right-menus {
	margin: 5% auto;
	position: relative;
	@media (min-width: $screen-sm-min) {
		margin-bottom: 2%;
		margin-top: 2%;
		max-width: 300px;
		padding-left: 20px;
		padding-right: 0;
		float: right;
	}

	.subnav-menu-item {
		@include points("font-size", 13);
		max-height: 75px;
		color: $white;
		text-transform: uppercase;
		position: relative;
		overflow: hidden;
		display: block;
		transition: all 0.5s linear;
		@media (max-width: $screen-sm-max) {
			display: inline-block;
			margin: 0;
			float: left;
			width: 100%;
			max-width: 100%;
		}
		@media (max-width: 767px) {
			max-width: 50%;
			max-height: none;
		}						
		
		&.grey { 
			border: 1px solid rgba(120, 120, 120, 0.5);
			border-bottom: none;
			background-color: #a0a0a0;
			-webkit-transition: background 0.5s linear;
	        -moz-transition: background 0.5s linear;
	        -ms-transition: background 0.5s linear;
	        -o-transition: background 0.5s linear;
	        transition: background 0.5s linear;
			 }
		&:last-child { border-bottom: 1px solid #909090;}
		&:hover {background-color: #888888; }




		.subnav-title {
			margin: 15px;
			display: block;
		}
		


	}
}
