.grouping {
	span {
		strong {
			color: $medium-dark-gray;
			font-weight: normal;
		}
	}
	h5 {
		color: $medium-dark-gray;
	}
}