header {
  .subheader {
  	width: 100%;
  	min-height: 120px;
  	color: white;
  	display: block;
    position: relative;
    height: 120px;
    @media(min-width: $screen-sm-min) { 
      height: 340px;
    }
	@media(max-width: $screen-xs-max) {
		height: 230px;
	}    
    #inner {
      height: 230px;
	  max-width: 1260px;
	  margin: 0 auto;
	  position: relative; 
      .background {
	      background: url("/images/hero/keyboard.png") no-repeat;
          height: 215px;
	      @media(min-width: $screen-sm-min) { 
	        top: 125px;
	        background-position: 80% 0%;
	      }	      
	      @media(max-width: $screen-xs-max) {
	        background-size: 75%;
            height: 230px;
	        background-position: bottom right;
	      }

	   }

        .prefix {
          font-size: 2rem;
          display: block;

          @media(min-width: $screen-lg-min) {
            @include points("font-size", 14);
          }
          font-family: "Brown-Pro-Light";
        }
        .h1-main {
          font-size: 4rem;
          display: block;
          @media(min-width: $screen-lg-min) {
            @include points("font-size", 44);
          }
        }
      }
    }
  }
