#category-filter-sorter {
	& > div {
		background-color: $light-gray;
		height: 77px;
	  .shell {
		  max-width: 1240px;
		  margin: 0 auto;
			#category-filter {
				text-transform: uppercase;
				color: $text-gray;
				//background-color: $white;
				margin: 0 0 0;
				@media (min-width: $screen-md-min) {
					margin-bottom: 40px;
				}
				padding: 0;

				#category-tab {
					@include points("font-size", 14);
					text-align: center;
					background-color: $white;
					background-color: $light-gray;
					//border-bottom: 2px solid #ededed;
					border-left: 2px solid $light-gray;
					border-right: 2px solid $light-gray;
					width: 100%;
					height: 77px;
					display: block;
					margin: 0;
					padding: 30px;
					&:hover {
						cursor: pointer;
					}
					.tab-label {
						padding: 0 10px;
					}
					&.active {
						background-color: $white;
						//border-bottom: 2px solid #ededed;
					}


				}

				nav#category-list{
					opacity: 1; //for tweening
					height: 0; //for tweening
					overflow: hidden;
					position: absolute;
					z-index: 1000;
					width: 100%;
					margin: 0;
					padding: 0;
					background-color: #fff;
					border-bottom: 0;
					border-left: 2px solid $light-gray;
    				border-right: 2px solid $light-gray;
					ul {
						padding: 0;
						margin: 0;
						width: 100%;
						list-style: none;
						//border-left: 2px solid #ededed;
						//border-right: 2px solid #ededed;
						li {
							&.active {
								background-color: $light-gray !important;
							}
							@include points("font-size", 14);
							background-color: $white;
							text-transform: uppercase;
							color: #2f3132;
							display: block;
							width: 100%;
							text-align: center;
							padding: 20px;
							&:hover {
								background-color: $yellow;
								cursor: pointer;
							}
						}
					}
				}
			}

			#filter-by-filter {
				text-transform: uppercase;
				color: $text-gray;
				//background-color: $white;

				#filter-by-tab {
					text-transform: uppercase;
					@include points("font-size", 14);
					text-align: center;
					background-color: $light-gray;
					width: 100%;
					height: 77px;
					display: block;
					margin: 0;
					padding: 30px;
					&:hover {
						cursor: pointer;
					}
					.tab-label {
						padding: 0 10px;
					}
					&.active {
						background-color: $white;
						//border-bottom: 2px solid #ededed;
						border-left: 2px solid $light-gray;
						border-right: 2px solid $light-gray;

					}
				}

				.filter-list-title {
					text-transform: uppercase;
					font-weight: bold;
					padding: 10px 35px 0;
				}

				.clear-all {
			        font-family: Brown-Pro-Bold;
					text-transform: uppercase;
					padding: 0 31px;
					color: $blue;
					.glyphicon {
						//glyphicon overrides for specific use within clear all
						color: $blue !important;
						padding-left: 0px !important;
						padding-right: 8px;
					}
					&:hover {
						cursor: pointer;
					}
				}

				ul#filter-by-list {
					overflow: hidden;
					height: 0;
					position: absolute;
					z-index: 1000;
					width: 100%;
					list-style: none;
					margin: 0;
					padding: 0;
					border-bottom: 0;
					background-color: $white;
					border-left: 2px solid $light-gray;
					border-right: 2px solid $light-gray;
					li {
						@include points("font-size", 14);
						text-transform: capitalize;
						white-space: nowrap;
						color: #2f3132;
						display: block;
						width: 100%;
						text-align: left;
						padding: 7px 0 7px 0;
						.glyphicon {
							padding-left: 10px;
							top: 3px;
							color: $gray;
						}
					}
					label {
						font-family: "Brown-Pro-Bold";
						font-weight: normal;
					}
					ul {
						padding: 0;
						-webkit-padding-start:0;
						margin-left: 43px;
					}
					.feature_checkbox {
						width: 4px;
						-moz-appearance: none;
							 appearance: none;
					}
				}
			}

			#sort-by-filter {
				text-transform: uppercase;
				color: $text-gray;
				//background-color: $white;
				margin: 0;
				padding: 0;

				&.active{
					z-index: 5;
				}

				#sort-by-tab {
					@include points("font-size", 14);
					text-align: center;
					background-color: $light-gray;
					width: 100%;
					height: 77px;
					display: block;
					margin: 0;
					padding: 30px;
					&:hover {
						cursor: pointer;
					}
					.tab-label {
						padding: 0 10px;
					}
					&.active {
						background-color: $white;
						//border-bottom: 2px solid #ededed;
						border-left: 2px solid #ededed;
						border-right: 2px solid #ededed;
					}
				}

				ul#sort-by-list {
					//opacity: 0;
					height: 0;
					overflow: hidden;
					position: absolute;
					z-index: 1000;
					width: 100%;
					list-style: none;
					margin: 0;
					padding: 0;
					border-bottom: 0;
					background-color: $white;
					border-left: 2px solid #ededed;
					border-right: 2px solid #ededed;
					li {
						@include points("font-size", 14);
						background-color: $white;
						text-transform: uppercase;
						white-space: nowrap;
						color: #2f3132;
						display: block;
						width: 100%;
						text-align: center;
						padding: 20px;
						&:hover {
							background-color: $yellow;
							cursor: pointer;
						}
					}
				}
			}

			#num-showing {
				color: #999999;
				font-family: "Helvetica", sans-serif;
				@include points("font-size", 14);
				text-align: right;
				padding: 20px;
			}

			#no-results-found {
				text-align: left;
				position: relative;
				width: 100%;
				//height: 250px;
				//line-height: 250px;
			}
		}
	}
}
