.tabNav {
	margin-top: 2%;
	padding-left: 0;
	position: relative;
	border-bottom: 1px solid #c6c6c6;
    background-color: $light-gray;

	li.tabNav-item {
	    display: inline-block;
	    padding: 10px 15px;
	    max-height: 60px;
	    overflow: hidden;
	   //width: 100%;
	    //max-width: 15%;
	    text-align: center;
	    vertical-align: top;
	    margin-bottom: 0;
		@media(max-width: $screen-sm-min) {
			width: 100%;
			max-width: 48%;
		}
	
		&:last-child {  margin-bottom: 0;}


		.tabNav-title {
		    color: #000;
		    text-transform: uppercase;
		    font-size: 0.875em;
		    opacity: .7;
			&:hover {opacity: 1; }
		    
		}


	}
}
