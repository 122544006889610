/*
* Footer lite component
* extends some styles from the main footer component
*/
.footer-lite {
    @include styled-global;
	width: 100%;
	min-height: 80px;
	background-color: #444!important;
	a {
		@include footer-text;
	}
	.col-sm-10 {
		@media (min-width: $screen-sm-max) {
			@include remove-padding;
		}
	}
	.col-md-3 {
		display: inline-block;
	}	
	#footer-links {
		padding-top: 25px;
		.col-md-3 {
			@media (min-width: $screen-sm-max) {
				padding-left: 0;
				padding-right: 15px;
			}
		}
	}
}