.tabs {
	h4 {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 36px;
	}
	p {
		font-size: 18px;
		font-weight: 200;
	}
	.btn { 
		border-radius: 0;
		margin: 10px 0;
		width: 80%;
		@media (max-width: $screen-sm-min) {
			width: 80%;
			margin: 10px auto;
		}
	}
	.nav-tabs {
		border-bottom: none;
		> li {
			padding: 0;
			&.active {
				> a, 
				> a:hover,
				> a:focus {
				    background-color: #fff;
					
				}
			}
			a {
				font-size: 16px;
			    color: #555555;
			    background-color: #ededed;
			    border: none;
			    border-radius: 0;	
				min-height: 75px;
				margin-right: 0;
				padding: 25px 15px;
			}
		}
	}
	.tab-content {
		max-width: 1240px;
		width: 100%;
		margin: 5% auto;
	}
}