.icon-feature-grid {
	border-top:1px solid $light-gray;
	border-bottom:1px solid $light-gray;
	display: flex;
	align-items:stretch;
	
	.icon-circle {
		.icon-awards{
			width: 37.5%;
		}

		.icon-house {
			width:57.5%

		}

		.icon-pie-chart {
			width:53.75%

		}
	}

	> div {
		padding:50px 50px 80px 50px;
		border-right:1px solid $light-gray;
		position:relative;
		&:last-child {
			border-right:none;
		}
	}

	div.link {
		position:absolute;
		bottom:45px;
		left:0;
		right:0;
		text-align: center;
	}

	a {
		color:$blue;
		font-size:1.125em;
		font-family: "Brown-Pro-Light";

		&:hover, &:active, &:focus {
			text-decoration: none;
			border-bottom:1px solid $blue;
		}
	}

	h4 {
		text-transform: uppercase;
		font-size:1.125em;
		font-family: "Brown-Pro-Bold";
		margin-top:24px;
	}


	@media (max-width: $screen-sm-max) {
		display: block;
		border-bottom:none;
		> div {
			border:none;
			border-bottom:1px solid $light-gray;
			padding:50px;

			div.link {
				position:relative;
				bottom:auto;
				left:auto;
				right:auto;
				width:100%;
			}
		}	 		
	 }
	
	@media (max-width: $screen-xs-max) {
		> div {
			padding:20px 0;
		}	
 		div.links {
			padding:42px 0 18px 0;
		}
 		ul.link-list {
 			li {
 				width:100%;
 				text-align: center;
 			}
 			a {
 				margin: 12px 12px;
 				justify-content: center;
 			}
 		}
	 }
}