.tech-specs {
  margin: 40px auto;

  .container {
    padding: 0;
    @media (min-width: $screen-md-min) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  // Added to stop mobile Safari from auto styling the skus
  a[href^=tel]{
    color:inherit;
    text-decoration:none;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  // Header
  .tech-specs-header {
    padding: 12px 0;
    text-align: center;
    cursor: pointer;
    background-color: $grey;
    h6 {
      position: relative;
      text-transform: uppercase;
      // Carrat
      &:after {
        content: " ";
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid $black;
        z-index: 1;
        padding: 0;
        vertical-align: top;
        margin: 3px 0 0 11px;
      }
    }
  }

  // Detail
  .tech-specs-detail {
    margin: 12px 6.25%;
    border-bottom:2px solid $light-gray;
    .spec-label {
      font-size: 1.375em;
    }
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    @media (min-width: $screen-lg-min) {
      margin: 25px 8.467741935%;
      padding-bottom: 20px;
    }
  }
  .details-description {
    margin-left: 45px;
    ul, p {
      margin-bottom: 22px;
    }
    p, li {
      font-size: 14px;
      line-height: 1.25em;
    }
    li {
      margin-bottom: 7px;
    }
    .name {
      font-weight: bold;
    }
    @media (min-width: $screen-sm-min) {
      margin-left: 75px;
      margin-top: 14px;
      p, li {
        line-height: 1.7em;
      }
    }
  }
  //
  .modal-button {
    position: absolute;
    top: 5px;
    right: 0;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 0.75em;
    text-transform: uppercase;
    color: $blue;
    outline: none;
    @media (min-width: $screen-sm-min) {
      position:relative;
      top:0;
      left:0;
    }
  }

  // Details
  .tech-specs-details {
    margin: 0;
  }

  // Functionality
  &.initialized {
    .tech-specs-body {
      //display: none;
    }
  }

  &.open {
    .tech-specs-body {
      //display: block;
    }
    .tech-specs-header {

      h6 {
        // Carrat
        &:after {
          border-top: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 8px solid $black;
          margin: 4px 0 0 11px;
        }
      }
    }
  }

}

// Dimensions Modal
.modal.modal-dimensions {
  .modal-dialog {
    @media (min-width: $screen-sm-min) {
      width: 90%;
    }
    @media (min-width: $screen-lg-min) {
      width: 100%;
      max-width: 1030px;
    }
  }
  .modal-content {
    @media (min-width: $screen-sm-min) {
      padding-bottom: 100px;
    }
  }
  .images {
    @include clearfix;
    padding:0 10px;
    @media (min-width: $screen-md-min) {
      margin-top: 90px;
    }
  }
  .image {
    @media (min-width: $screen-sm-min) {
      float:left;
      width:50%;
      &:nth-child(2n) {
        float:right;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
