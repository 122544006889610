// Bootstrap resets
.modal-backdrop {
	z-index: 5000;
}
.modal {
	z-index: 5010;
  	.modal-content {
  		box-shadow: none;
  		border-radius: 0;
  		border: none;	
  	}
  	.close {
  		float:none;
  		text-shadow: none;
		opacity: 1;
  	}
}

.modal-backdrop {
	&.in {
		opacity: 0.8;
	}
}
// General Modal
.modal {
	.modal-content {
		padding: 42px 0;
	}
	.modal-dialog {
		margin: 30px 10px;
		@media (min-width: $screen-sm-min) {
			margin: 106px auto;
		}
	}
	.close {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: -25px;
		font-size: 0.875em;
		text-transform: uppercase;
		color: $yellow;
		filter: none;
		outline: none;
		span:first-of-type {
			font-family: "Brown-Pro-Boldalt", "Helvetica", sans-serif;
		}
		> i {
			top: 2px;
		}
	}
	.modal-headline {
		margin-top:0;
		font-size: 2.25em;
		text-transform: uppercase;
		text-align: center;
	}
}