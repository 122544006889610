.short-header {
  color: $white;
  max-height: 350px;
  padding-top: 100px;
	@media(max-width: $screen-sm-min) { 
	  padding-top: 75px;
	}    
  
  .title-block {
    padding-top: 8px;
    padding-bottom: 15px;
    text-transform: uppercase;
    font-family: "Brown-Pro-Bold";
    .col-xs-10,
    .col-xs-2 {
      @include remove-padding;
    }
	@media(max-width: $screen-sm-min) { 
	  padding-top: 0;
	}    
  }

  .hero-action {
    @include points("font-size", 14);
    padding-top: 0;
		@media(min-width: $screen-sm-min) { 
		  padding-top: 38px;
		} 
		@media(max-width: $screen-sm-min) { 
		  padding-top: 0;
		} 		

    a {
      float: right;
      color: $white;
      text-decoration: none;

      &:hover > .svg-rarr{
        transform: translateX(6px);
      }
		@media(max-width: $screen-sm-min) { 
		  float: left;
		} 	      
    }

    .svg-rarr {
      float: right;
      margin: 2px 0 0 5px;
      transition: all .1s ease-in;
		@media(max-width: $screen-sm-min) { 
		  float: none;
		  display: inline-block;
		} 	      
      
    }


  }
}
