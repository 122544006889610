/*
body.home {
  opacity: 0;
}
.home .section {
  opacity: 0;
  filter: alpha(opacity=0);
}
*/

// height: 100vh;
// width: 100vw;
//


$panelNudge: 80px;


body.piling-panels {
	height:100%;
}


div.piling-panels {
	position:absolute;
	height:100%;
	height: 100vh;
	width:100%;
	color: black;
	top:0;
	left:0;
	right:0;
	//bottom:0;
	z-index:2;

	@include transition(all 600ms);

	&.hide-pagepiling {
		height:0;
	}

	h1, h2 {
		font-size: 5.7vw;
		line-height: 5.2vw;
		margin:0;
		width:100%;
	}

	h3 {
		font-size: 2vw;
		line-height: 1.2;
		font-family: "Brown-Pro-Light";
		margin:0;
		width:100%;
	}

	a.learn-more, a.cta {
		display: inline-block
	}

	.panel {
		position:absolute;
		margin:0;
		border:0;
		padding:0;
		height:100vh;
		width:100%;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;

		.container {
			width:100%;
			max-width:1024px;
			position: relative;
		}

		.panel-text {
			@include transition(all 600ms );
			position:relative;
			opacity: 0;
			height:100vh;
			width:100%;
			top:0;
			left:0;
			z-index: 2;
			-webkit-transform: translate3D(0, -300px, 0);
			-ms-transform: translate3D(0, -300px, 0);
			transform: translate3D(0, -300px, 0);
			&.large {
				h1, h2 {
					font-size: 11.1vw;
					line-height: 10vw;
				}
			}

			&.medium {
				h1, h2 {
					font-size: 7.6vw;
					line-height: 7.9vw;
				}
			}
			&.small, &.medium, &.large {
				.cta, .ctas a, .ctas a + a, .learn-more, .watch-vid {
					font-size: 12px;
				}
			}
			.ctas {
				position:relative;
				z-index: 1;
				a {
					margin-left:1px;
					margin-right:1px;

				}
				a + a {
					margin-left: 9px;
				}
				&.stack {
					display: flex;
					flex-direction:column;
					align-items: center;
					justify-content: center;
					padding-top:10px;

					a {
						margin-left: 0;
						margin-right:0;
					}
				}
			}

			&.text-left {
				.ctas {
					&.stack {
						float:left;
					}
				}
			}

			&.text-right {
				.ctas {
					&.stack {
						float:right;
					}
				}
			}
		}

		.panel-image {
			@include transition(all 600ms);
			position: absolute;

			-webkit-transform: translate3D(0, 400px, 0);
			-ms-transform: translate3D(0, 400px, 0);
			transform: translate3D(0, 400px, 0);
			top:0;
			left:0;
			width:100%;
			height:100vh;
			opacity: 0;
			z-index: 3;
			pointer-events:none;

			img, a {
				position: absolute;
				display: block;
			}

			a {
				//width:100%;
				//height:100%;
				img {
					position: relative;

				}
			}

			&.has-links {
				pointer-events:auto;
			}

			&.behind {
				z-index: 1;
			}

			&.background-image {
				background-repeat:no-repeat;
				background-position: center center;
				background-size:cover;
				z-index:1;
			}
		}

		&.active {
			.panel-text, .panel-image {
				opacity: 1;
				-webkit-transform: translate3D(0, 0, 0);
				-ms-transform: translate3D(0, 0, 0);
				transform: translate3D(0, 0, 0);
			}
		}

		&.up {
			.panel-text {
				//top:300px;
				-webkit-transform: translate3D(0, 300px, 0);
				-ms-transform: translate3D(0, 300px, 0);
				transform: translate3D(0, 300px, 0);
			}

			.panel-image {
				//top:200px;
				-webkit-transform: translate3D(0, 400px, 0);
				-ms-transform: translate3D(0, 400px, 0);
				transform: translate3D(0, 400px, 0);
			}

		}

		&.down {
			.panel-text {
				-webkit-transform: translate3D(0, -300px, 0);
				-ms-transform: translate3D(0, -300px, 0);
				transform: translate3D(0, -300px, 0);
			}

			.panel-image {
				-webkit-transform: translate3D(0, 400px, 0);
				-ms-transform: translate3D(0, 400px, 0);
				transform: translate3D(0, 400px, 0);
			}

		}

		&.no-slide {
			&.down {
				.panel-text {
					//top:300px;
					-webkit-transform: translate3D(0, 0, 0);
					-ms-transform: translate3D(0, 0, 0);
					transform: translate3D(0, 0, 0);
				}

				.panel-image {
					//top:200px;
					-webkit-transform: translate3D(0, 0, 0);
					-ms-transform: translate3D(0, 0, 0);
					transform: translate3D(0, 0, 0);
				}
			}
		}

		&.last-panel {
			height:0;
		}

		.container {
			position:relative;
			z-index:2;
		}

		.vertical-top {
			top:0;
		}

		.vertical-third {
			position: relative;
			top: 33%;
			-webkit-transform: translateY(-33%);
			-ms-transform: translateY(-33%);
			transform: translateY(-33%);
		}

		.vertical-center {
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}

		.vertical-two-thirds {
			top: 66%;
		}

		.vertical-top, .vertical-third, .vertical-center, .vertical-two-thirds {
			&.nudge-up {
				margin-top:-$panelNudge;
			}

			&.nudge-down {
				margin-top:$panelNudge;
			}

			&.nudge-up-more {
				margin-top:-$panelNudge * 2;
			}

			&.nudge-down-more {
				margin-top:$panelNudge * 2;
			}
		}


		.vertical-bottom {
			top:auto;
			bottom:0;
			position: absolute;

			&.nudge-up {
				margin-bottom:$panelNudge;
			}

			&.nudge-down {
				margin-bottom:-$panelNudge;
			}

			&.nudge-up-more {
				margin-bottom:$panelNudge*2;
			}

			&.nudge-down-more {
				margin-bottom:-$panelNudge*2;
			}
		}

		.horizontal-center {
			@include horizontally-centered;
		}

		.horizontal-center-left {
			right:50%;
		}

		.horizontal-center-right {
			left:50%;
		}

		.horizontal-left {
			left:0;
		}

		.horizontal-right {
			right:0;
		}

		.horizontal-left, .horizontal-center, .horizontal-center-right {
			&.nudge-left {
				margin-left:-$panelNudge;
			}

			&.nudge-right {
				margin-left:$panelNudge;
			}

			&.nudge-left-more {
				margin-left:-$panelNudge*2;
			}

			&.nudge-right-more {
				margin-left:$panelNudge*2;
			}
		}

		.horizontal-right, .horizontal-center-left {
			&.nudge-left {
				margin-right:$panelNudge;
			}

			&.nudge-right {
				margin-right:-$panelNudge;
			}

			&.nudge-left-more {
				margin-right:$panelNudge*2;
			}

			&.nudge-right-more {
				margin-right:-$panelNudge*2;
			}
		}


	}
}

@media (min-width: 1600px){
	div.piling-panels {
		h1, h2 {
			font-size:91px;
			line-height: 83px;
		}

		h3 {
			font-size: 32px;
		}

		.panel {

			.container {
				max-width:1400px;
			}


			.panel-text {
				&.large {
					h1, h2 {
						font-size:184px;
						line-height: 169px;
					}
				}

				&.medium {
					h1, h2 {
						font-size: 125px;
						line-height: 115px;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-md-max){
	body.piling-panels {
		div.piling-panels {
			.panel {
				.panel-text {
					&.text-left {
						.container {
							padding-left:40px;
							padding-right:40px;
						}
					}
				}
			}
			h1, h2, h3 {
				margin-bottom: 5px;
			}
		}
	}

}

//medium - 992
@media (max-width: $screen-sm-max) {
	body.piling-panels {
		#pp-nav  {
			left:0px !important;
		}
	}
	div.piling-panels {
		h1, h2 {
			font-size:6.7vw;
			line-height: 6.5vw;
		}

		h3 {
			font-size: 22px;
		}

		.panel {
			.panel-text {
				&.large {
					h1, h2 {
						font-size:14.1vw;
						line-height: 12.7vw;
					}
				}

				&.medium {
					h1, h2 {
						font-size: 9.7vw;
						line-height: 9.5vw;
					}
				}

				&.text-left {
					.container {
						padding-left:20px;
						padding-right:20px;
					}

				}
			}
		}
	}

}
@media (max-width: 991px) and (min-width: 769px) {
	#panel-stack .visible-sm {
	    display: block !important;
	}

}

//768
@media (max-width: 768px) {
	#panel-stack .panel-text .col-sm-6 {
		width: 100% !important;
		margin-left: 0;
	}
	#panel-stack .visible-sm {
		display: none !important;
	}
	#panel-stack .visible-xs {
		display: block !important;
	}
	div.piling-panels {
		h1, h2 {
			font-size:60px;
			line-height: 7.6vw;
		}

		h3 {
			font-size: 22px;
		}



		.panel {
			padding-top:0px;
			padding-top:12vw;

			.panel-text {
				//margin-top:12vw;
				text-align: center;
				margin-bottom:40px;
				height:auto;

				.ctas.stack {
					float:none !important;
				}

				&.large {
					h1, h2 {
						font-size:115px;
						line-height: 14vw;
					}
				}

				&.medium {
					h1, h2 {
						font-size: 78px;
						line-height: 9.7vw;
					}
				}
			}

			.panel-image {
				position:relative;
				top:0;
				left:0;
				bottom:0;
				right:0;
				padding-bottom:20px;
				height:auto;

				img {
					position: relative;
					top: auto;
					bottom: auto;
					left: auto;
					right: auto;
					max-width:90%;
					margin: 0 auto;
				}

				a {
					display: block;
					width:100%;
				}

				&.background-image {
					position:absolute;
					padding-bottom:0;
					height:100vh;
					width:100%;
				}
			}



			.vertical-top, .vertical-third, .vertical-center, .vertical-two-thirds, .vertical-bottom {
				top:0;
				bottom:none;
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
				position:relative;


				&.nudge-up, &.nudge-down {
					top:auto;
					bottom:auto;
				}
			}

			.horizontal-center, .horizontal-center-left, .horizontal-center-right, .horizontal-left, .horizontal-right {
				right:auto;
				left:auto;
				-webkit-transform: none;
				-ms-transform: none;
				transform: none;
				position:relative;

				&.nudge-left, &.nudge-right {
					left:auto;
					right:auto;
					margin-left:auto;
					margin-right:auto;
				}


			}

			&.reverse-order {
				display: flex;
				flex-direction:column;
				padding-top:0;
				.panel-text {
					-webkit-order: 2;
					-moz-order:2;
					order: 2;
					top:0;


				}

				.panel-image {
					-webkit-order: 1;
					-moz-order:1;
					order: 1;

				}
			}
		}
	}




}


@media only screen and (max-width: 680px) {
	div.piling-panels {

		h1, h2 {
			font-size:36px;
			line-height: 7.6vw;

		}

		h3 {
			font-size: 22px;
		}

		.panel {
			//padding-top:60px !important;
			//padding-top:20vw !important;

			.panel-text {
				margin-bottom:20px;

				.ctas.stack {
					float:none !important;
				}

				&.large, &.medium {
					h1, h2 {
						font-size:36px;
						line-height: 7.6vw;
					}

					h3 {
						font-size: 22px;
					}
				}
			}

			/*&.reverse-order {
				display: block;;
			}*/

		}
	}

}


@media only screen and (max-width: 480px) {
	div.piling-panels {
		h1, h3 {
			line-height:1.2 !important;
		}
		h2 {
			line-height:1 !important;
		}

		h1, h2 {

			overflow-wrap: break-word;
			word-wrap: break-word;

		}

		&.no-piling {
			position:relative;
			height:auto;
			.panel {
				position:relative;
				height:auto;

				.panel-text, .panel-image {
					opacity: 1;
					-webkit-transform: translate3D(0, 0, 0);
					-ms-transform: translate3D(0, 0, 0);
					transform: translate3D(0, 0, 0);
				}

				.panel-image {
					a {
						position: relative;
					}

					&.background-image {

						position: relative;
						background-image:none !important;

					}
				}

				&.last-panel {
					height:0;
					display: none;
				}
			}
		}

		.panel {
			padding-top:0px;
			padding-top:6vw;

			&:first-child {
				padding-top:12vw;
			}

			&.reverse-order {
				padding-top:0;

			}
		}

	}

	body.piling-panels {

		.scrolling-page {
			.home-category-grid-tiles {
				margin-top:0;
			}
		}
	}

	#pp-nav {
		display: none;
	}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : landscape) {
	div.piling-panels {
		.panel {
			//padding-top:10vw !important;

			.panel-image {
				display: none;
			}
		}

	}
}
