.product-overview {
   margin-top: 50px;

   .overview-list.row {
      &:before, &:after {
         width: 0;
         content:'';
      }
   }

   ul {
      padding-left: 0;
      list-style-type: none;
      display: flex;
      align-items:stretch;
      flex-flow: row wrap;
   }

   .overview-item {
      padding: 30px 0;
      border-top: 2px solid #e0e2e4;
      text-align: center;


      p {
         font-size: 0.875em;
         line-height: 1.714285714em;
      }

      a {
         font-size: 0.75em;
         text-transform: uppercase;
         color: $blue;
      }

      @media (min-width: 480px) {
         text-align: left;
         p {
            max-width: 400px;
         }
      }

      @media (min-width: $screen-sm-min) {
         margin-right: 30px;
         min-height: 280px;
      }

      @media (min-width: $screen-md-min) {
         min-height: 0;
         padding-bottom: 10px;
      }
   }

   .more-content-list {
      .more-content-item {
         margin-bottom: 9px;
         display: block;
         width:100%;

         &:last-of-type {
            margin-bottom: 0;
         }
      }
   }

   @media (min-width: $screen-md-min) {
      margin-top: 80px;
      padding: 0;
   }
}