.media-hero {
	&.content.container {
		margin-bottom:0;
		&.wide {
			//max-width:2000px;
			width:100%;
			padding:0;
	
			&.hero {
				overflow:hidden;
				margin-bottom:0;
				.background-image {
					height:560px;
					background-size:cover;
					background-position:center center;
					background-repeat:no-repeat;
	
				}
			}
		}
	}
}


