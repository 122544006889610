.cookie-disclaimer {
  @include points('font-size',14);
  left: 0;
  bottom: 0;
  width: 100%;
  background: #101820;
  color: #fff;
  z-index: 999;
	border-top: 2px solid $light-gray;
  @media (min-width: $screen-lg-min){
    padding-left: 1.6em;
    padding-right: 1.6em;
  }

  &.initialized{
  	position: relative;
  }

  .disclaimer-message{
    padding: 1.25em 0;
  }

  .dismiss {
    position: relative;
    height: 48px;
    width: 48px;
    margin-top: 1.25em;
    opacity: .8;
    float:right;
    

    .svg-close.svg-close-dims{
      position: absolute;
      top: 5px; left: 0; right: 0;
      margin: auto;
    }

    &:hover{
      opacity: 1;
      cursor: pointer;
    }
    
  }

}
