#product-hero {
  padding-top: 90px;
  @media (min-width: $screen-md-min) {
    padding-top: 120px;
  }
  @media (min-width: $screen-lg-min) {
    padding-top: 100px;
  }
}

.product-hero-container {
  position: relative;
  overflow: hidden;

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .notes {
    position: relative;
    text-align: center;
    background: $white;
    margin: 30px 0;
    @media (min-width: $screen-md-min) {
      text-align: left;
      background: none;
      margin: 20px 0 30px;
    }
    em {
      display: block;
    }
    .disclaimer{
      @include points('font-size', 12);
      margin-bottom: .6em;
    }
    .product-hero-availability-message {
      padding-top: 0;
      font-size: 16px;
    }
    .product-hero-sku-message {
      font-family: 'Brown-Pro-Bold';
    }
  }

  &.invalid-sku  {
    .product-configurables {
      border: 3px solid $yellow-green;
    }
  }


  // disable owl carousel controls
  .product-hero-carousel .disabled {
    display:none;
  }

  .modal-360-view-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
  }

  .modal-360-view {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    .container {
      position: relative;
      height: 100%;
      padding-top: 25px;
    }
    .close-modal {
      @include label-small-uppercase;
      font-size: 14px;
      color: $white;
      position: absolute;
      right: 10px;
      top: 0;
      text-decoration: none;
    }
    .icon-close-x {
      margin-left: 5px;
    }
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .modal-360-frame {
    width: 100%;
    height: 100%;
  }

  .product-thumbnail {
    @media (min-width: $screen-sm-min) {
      margin-top: 10px;
    }
  }

  .product-thumbnail-image {
    display: none;
  }

  .owl-loaded .product-thumbnail-image {
    display: block;
  }

  .owl-dots {
    text-align: center;
    margin: .5em 0;
  }

  .owl-dot {
    display: inline-block;
    width: .75em;
    height: .75em;
    margin: 0 .375em;
    border: 2px solid $white;
    border-radius: 50%;
    &.active {
      background: $yellow-green;
    }
  }

  .owl-carousel .owl-item {
    padding: 0 20px;
    opacity: 0;
    @include gpu();
    @include transition(opacity 300ms);  /* just a little longer than owl's carousel transition */
    &.active {
      opacity: 1;
    }
  }

  @media (min-width: $screen-md-min) {
    .product-hero-carousel {
      position: relative;
    }
    .owl-stage-outer {
      width: 100%;
    }
    .owl-dots {
      width: 2 * 100% / 7;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      // Overridde defaults
      text-align: left;
      margin: 0 15px 0 0;
    }
    .owl-dot {
      // Overridde defaults
      display: block;
      width: auto;
      height: auto;
      margin: 1em 0;
      border: none;
      opacity: .7;
      &.active {
        background: none;
        opacity: 1;
      }
    }
    .owl-dot span {
      display: block;
      // width: 143px;
      // height: 123px;
      width: 50px;
      height: 50px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  #product-image-enlarged {
    width: 90%;
    margin: 0 auto;
    position: relative;
    display: block;
  }

  .product-thumbnail-image {
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
    @media (min-width: $screen-md-min) {
      cursor: auto;
    }
    width: 100%;
  }

  .owl-grab .product-thumbnail-image {
    cursor: pointer;
    cursor: -webkit-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
  }

  .product-view {
    position: relative;
    margin-top: 7%;
    
   .product-contact {
		img {
			 margin-right: 10px;  
	   }
	}
    
    
  }

  #product-images {
    .product-image {
      opacity: 0;
      top: 0;
      left: 0;
      height: 0;
      &.selected {
        @include animation(fadeIn 1s forwards);
      }
    }
  }

    .product-configurables-label {
    	font-size: 1em;
    	@media (min-width: $screen-md-min){
        	display: inline-block;
	    	color: #fff;
    		float: left;
    		padding: 1em 0.5em 0 0;
      	}
    } 

  .product-configurables {
    border: 3px solid transparent;
    display: block;
    box-sizing: border-box;

    @media (min-width: $screen-md-min) {
      float: left;
      width: auto;
    }

    &.no-purchase {
      border-bottom: 1px solid $gray;
      padding-bottom: 1em;
      @media (min-width: $screen-md-min){
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .product-controls-container {
    background: $white;
    margin-top: 20px;
    position: relative;
    z-index: 1;
    @media (min-width: $screen-md-min) {
      background: transparent;
      margin-top: 0;
      margin-bottom: 20px;
    }

    .product-controls-bg {
      width: 900%;
      height: 100%;
      background: white;
      left: -100%;
      position: absolute;
      @media (min-width: $screen-md-min) {
        position: relative;
      }
      z-index: 0;
    }

    em {
      margin-bottom: 10px;
      text-align: center;
      @media (min-width: $screen-md-min) {
        margin-top: 0;
        text-align: left;
      }
    }
  }

  .product-controls {
    display: block;
    width: 100%;
    position: relative;
    @media (min-width: $screen-md-min) {
      display: inline-block;
      float: right;
      width: auto;
    }
  }

  // buy button styles without dropdowns
  .no-config {
    margin-top: 20px;
    @media (min-width: $screen-md-min) {
      margin-top: 0;
    }
    .product-hero-buy-button {
      margin: 0;
      float: left;
    }
  }

  .product-hero-configurable {
    @include points('font-size', 12);
    display: block;
    position: relative;
    width: 100%;
    text-transform: uppercase;

    @media (min-width: $screen-md-min) {
      display: inline-block;
      float: left;
      width: auto;
      margin-right: -1px;
    }

    .dk-select {
      width: 100%;
      // Restore dropkick options and reduce width of color option
      @media (min-width: $screen-md-min) {
        width: 210px;
        &.color {
          max-width: 88px;
        }
      }
      &.color {
        .dk-selected {
          padding-left: 42px;
          span {
				&.dk-select-box {
			        text-indent: -999999999px;
					padding: 9px !important;
					border: 1px solid #565656;
					display: block;
					width: 18px;
					margin: 0px -19px;
			        background-size: 40px;
					background-position: center center;
			        background-clip: padding-box;
			        overflow: hidden;
				}
          }
          
        }
      .dk-selected {
        text-indent: -999999999px;
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: 25px center;
      }
      .dk-option {  
        text-indent: -999999999px;
        background-size: 40px;
        background-position: center center;
        overflow: hidden;
        padding: 9px!important;
        margin: 5px 23px!important;
        background-clip: padding-box;
        width: 18px;
        border: 1px solid #565656;
      }        
        
        
        

        .dk-select-options .dk-option {
          padding: 14px 8.3%;
          @media (min-width: $screen-md-min){
            padding: 14px 25px;
          }

        }
      }
    }
    @media (min-width: $screen-md-min) {
      .dk-select.color.large-color-box {
        .dk-select-options {
          left: auto;
          //width: 200px;
          width: 88px;
          .dk-option {
            float: left;
            background-position: center center;
          }
        }
      }
    }
    @media (min-width: $screen-xs-min) {
      .dk-select.color.large-color-box {
        .dk-select-options {
          .dk-option {
            float: left;
            background-position: center center;
          }
        }
      }
    }

    .dk-selected {
      box-sizing: border-box;
      background-color: $white;
      border-radius: 0;
      border: none;
      padding: 16px 45px 16px 10px;
      color: $black;
      width: auto;
      line-height: 18px;
      height: 50px;

      &:hover {
        background-color: $light-gray;
      }

      // dropdown arrow
      &:before {
        top: 44%;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid $black;
        margin: 0px 20px 0 0;
      }
      &:after {
        border: none;
      }

      img {
        max-height: 18px;
      }
    }

    .dk-select-options {
      padding: 0;
      max-height: 350px;
      .dk-option-highlight {
        color: $black;
        background-color: transparent;
      }
      img {
        pointer-events: none;
      }
    }

    .dk-option {
      box-sizing: border-box;
      line-height: 18px;
      max-height: 62px;
      padding: 5px 10px;
      word-wrap:break-word;
      &:hover {
        position: relative;
      }
    }
    @media (min-width: $screen-md-min) {
      .dk-option {
        &.color-palette {
          float: left;
        }
      }
    }

    // Hide configurable labels in menus.
    .dk-option-selected,
    .dk-option[data-value=''] {
      display: none;
    }

    // Menu is open.
    .dk-select-open-down,
    .dk-select-open-up {

      .dk-selected {
        //background: $light-gray;
        background-color: $grey;
        &:before {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid $black;
          border-top: none;
        }
        &:after {
          border: none;
        }
      }

      .dk-select-options {
        background: $light-gray;
        border-radius: 0;
        border: none;
      }

      .dk-selected:hover,
      .dk-option:hover {
        color: $text-gray;
        background-color: $grey;
      }
    }

    // Menu is disabled.
    .dk-select-disabled {
      opacity: 1;

      .dk-selected {
        color: $inbetween-gray;
        &:hover {
          background-color: $white;
        }


      }

      img {
        opacity: .6;
      }
    }

    // Option is disabled.
    .dk-option-disabled {
      display: none;
    }

  }










  .product-hero-buy-button {
    @include label-small-uppercase;
    @include btn-util;

    // Fix alignment issues caused by btn-util.
    padding-top: 16px;
    padding-bottom: 16px;
    line-height: 18px;
    height: 50px;

    cursor: pointer;
    display: block;
    width: 100%;
    text-decoration: none;
    transition: transform 150ms;
    &.loading {
      transform: rotate(180deg);
    }

    @media (min-width: $screen-md-min) {
      display: inline-block;
      float: right;
      margin: 3px 0 3px 20px; // 3px aligns with invalid-sku glow on menus
      width: auto;
    }

    &[href="#"] {
      cursor: default;
      opacity: .2;
      @media (min-width: $screen-md-min) {
        margin: 3px 0 3px 20px; // 3px aligns with invalid-sku glow on menus
      }
    }
  }

  .color-swatch {
    display: block;
    overflow: hidden;
    width: 23px;
    height: 23px;
	img {
		width: 51px;
		height: 51px;
		margin-top: -14px;
		margin-left: -14px;
	}    
    
  }

  .product-title {
    //@include points("font-size", 32);
    font-family: "Brown-Pro-Bold";
    margin: 0;
  }

	.product-description-alignment {
		.name {
			span {
				display: block;
				@include points("font-size", 12);
			}
		}
	}

  @media (min-width: $screen-md-min) {

    .product-view > .row {
      position: relative;
    }

    .product-description-alignment {
      position: relative;
      @media (min-width: $screen-lg-min) {}
    }
  }


  .product-description {
	.force-white-text { color: $white;
	}
	.force-dark-text { color: $dark-gray;}	  
    text-align: center;
    margin: 0 0 20px 0;
    @media (min-width: $screen-md-min) {
      text-align: left;
      margin: 0;
    }

    .price {
      @include points("font-size", 26);
      font-family: "Brown-Pro-Light";
      text-align: center;
      @media (min-width: $screen-md-min) {
        text-align: left;
      }
    }

    .original-price {
      position: relative;
      margin-right: .5em;
      color: $faded !important;
      &.price-range {
      	.price {
      		display: inline-block;
      		height: 32px;
      	}
      	 & + .current-price {
      		display: inline-block;
      		.price {
      			display: inline-block;
      			height: 32px;
      		}
      	}  

      }
    }

    .original-price:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      //top: .7em;
      top: .3em;
      width: 100%;
      height: 0;
      border-top: 1px solid $faded !important;
    }
    
    .product-description-text {
      font-size: 14px;
      line-height: 24px;
      clear: both;
      a.buy-link:hover{
      	color: #aaaaaa;
      }
      &.disclaimer {
	      font-size: 0.625em;
	      font-style: italic;
	      display: block;
      }
    }
    
    
	.ext-buy {   
		.hero-action {
			padding-top: 15px;
			font-weight: bold;
			text-transform: uppercase;
			a {
				float: none;
				&:hover {
					color: #fff;
				}	 
				@media(max-width: $screen-sm-min) { 
					float: none;
				}      
			}
			a + a {
				display: block;
				padding-top: 5px;
			}
			.svg-rarr {
				float: none;
				display: inline-block;      
			}
		}
	}    
    
    

    .product-view {
      @include clearfix;
      @include label-small-uppercase;
      @include points("font-size", 14);
      margin: 30px 0 0;
      padding: 0;

      li {
        display: inline-block;
        float: left;
        margin: 0 30px 10px 0;

        &:last-child {
          margin: 0;
        }

        a {
          display: block;
          color: $white;
          cursor: pointer;
          text-decoration: none;
        }

        icon {
          margin: 0 10px 0 0;
        }
      }
    }
  }
  em.shipping-info.disclaimer span sup {
	line-height: 0;
	top: -4px;
	vertical-align: baseline;
  }
}