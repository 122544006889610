#category-filter-sorter-mobile {
	.dropdowns {
		&.short {
			height: 200px;
			border-bottom: 20px solid #ededed;
			overflow: auto;
		}

 	}



	#category-tab-mobile {
		@include points("font-size", 14);
		background-color: $white;
		height: 65px;
		display: block;
		margin: 0;
		padding: 25px 10px;
		&:hover {
			cursor: pointer;
		}
		.tab-contents {
			text-align: center;
			@media(max-width: $screen-sm-min) {
				padding-left: 0;
			}

		}
		.tab-label {
			text-transform: uppercase;
			padding: 0 10px;
		}
		.triangle-down,.triangle-up {
			padding: 0 10px;
		}
	}

	#next-filter, #prev-filter {
		div{
			@include vertically-centered();
		}
		display: block;
		height: 65px;
		background-color:$light-gray;
		text-align: center;
	}

	nav#category-list-mobile{
		position: absolute;
		z-index: 1000;
		top: 65px;
		margin: 0;
		padding: 0;
		background-color: white;
		border-left: 2px solid $light-gray;
		border-right: 2px solid $light-gray;
		border-bottom: 20px solid $light-gray;
		ul {
			padding: 0;
			margin: 0;
			width: 100%;
			list-style: none;
			li {
				&.active {
					background-color: $light-gray !important;
				}
				@include points("font-size", 14);
				background-color: $white;
				text-transform: uppercase;
				color: #2f3132;
				display: block;
				width: 100%;
				text-align: center;
				padding: 20px;
				&:hover {
					background-color: $yellow;
					cursor: pointer;
				}
			}
		}
	}

	#filter-by-filter-mobile {

		color: $text-gray;

		#filter-by-tab-mobile {
			text-transform: uppercase;
			@include points("font-size", 14);
			text-align: center;
			background-color: $light-gray;
			height: 65px;
			display: block;
			margin: 0;
			padding: 25px 0 0;
			&:hover {
				cursor: pointer;
			}
			.tab-label {
				padding: 0 10px;
			}
			&.active {
				background-color: $white;
			}
		}

		.filter-list-title {
			text-transform: uppercase;
			font-weight: bold;
			padding: 0 35px;
				@media(max-width: 680px) {
					padding: 0 15px;
				}

		}


		.clear-all-section {
			@media(max-width: $screen-sm-min) {
				padding-left: 0;
				margin-left: 0;
			}
			#clear-all-mobile {
				text-transform: uppercase;
				font-weight: bold;
				padding: 0 23px 0 14px;
				color: $blue;
				.glyphicon {
					color: $blue !important;
					padding-left: 0px !important;
					padding-right: 8px;
				}
				@media(max-width: $screen-sm-min) {
					padding-left: 0;
					margin-left: 0;
				}

			}
		}

		.glyphicon {
			padding: 0 10px;
		}

		ul#filter-by-list-mobile {
			position: absolute;
			z-index: 1000;
			list-style: none;
			margin: 0;
			padding: 0;
			background-color: $white;
			border-left: 2px solid $light-gray;
			border-right: 2px solid $light-gray;
			border-bottom: 20px solid $light-gray;
			width: 200%;
			ul {
				padding-left: 40px;

				li {
					@include points("font-size", 14);
					background-color: $white;
					white-space: nowrap;
					color: #2f3132;
					display: block;
					width: 100%;
					text-align: left;
					padding: 10px 0 10px 0;
					position: relative;

					.glyphicon {
						padding-left: 10px;
						top: 3px;
						color: $gray;
					}

					@media(max-width: $screen-sm-min) {
					}
					@media(max-width: 680px) {
						max-width: 225px;
						white-space: normal;
						input {
							display: none;

						}
						label {
							&::before {
								margin-left: -32px;
							}
						}
					}

				}
			}


			.filter-list-title {
				padding-top: 10px;
				padding-bottom: 10px;
				@media(max-width: $screen-sm-min) {
				}

			}

		}
	}

	#sort-by-filter-mobile {
		text-transform: uppercase;
		color: $text-gray;
		background-color: $white;
		margin: 0;
		padding: 0;

		#sort-by-tab-mobile {
			@include points("font-size", 14);
			text-align: center;
			background-color: $light-gray;
			height: 65px;
			display: block;
			margin: 0;
			padding: 25px 0;
			&:hover {
				cursor: pointer;
			}
			.tab-label {
				padding: 0 10px;
			}
			&.active {
				background-color: $white;
			}
			.triangle-down,.triangle-up {
				padding: 0 10px;
			}
		}

		&.col-xs-10 {
			#sort-by-list-mobile {
				width:100%;
			}
		}

		ul#sort-by-list-mobile {
			position: absolute;
			z-index: 1000;
			list-style: none;
			margin: 0;
			padding: 0;
			right: 0;
			border-left: 2px solid $light-gray;
			border-right: 2px solid $light-gray;
			border-bottom: 20px solid $light-gray;
			width: 200%;
			li {
				@include points("font-size", 14);
				background-color: $white;
				text-transform: uppercase;
				white-space:normal;
				color: #2f3132;
				display: block;
				text-align: center;
				padding: 20px;
				&:hover {
					background-color: $yellow;
					cursor: pointer;
				}
			}
		}
	}
}
.no-touch #filter-by-filter-mobile{

	ul#filter-by-list-mobile li ul:not(.clear-all-section){
		-webkit-padding-start: 0;
		padding-left: 59px !important;
	}
	#clear-all-mobile{
		-webkit-padding-start: 20px;
	}
}
