//Style for the global icons
.icon-360 {
	display: inline-block;
	background: url('/images/icons/icon-360.png') no-repeat;
	width: 16px;
	height: 14px;
}

.icon-plus {
	display: inline-block;
	background: url('/images/icons/icon-plus.png') no-repeat;
	width: 12px;
	height: 12px;
}

.icon-close-x {
	display: inline-block;
	background: url('/images/icons/icon-close.png') no-repeat;
	width: 11px;
	height: 11px;
}


.icon-circle {
	display:block;
	border-radius:100%;
	height:80px;
	width:80px;
	position: relative;

	> * {
		@include vertically-horizontally-centered;
		width:40%;
		height:auto;
	}

	&.center {
		margin-left:auto;
		margin-right:auto;
	}

}

