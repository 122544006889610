.products-container {
  position: relative;
}

#products-list {
  margin-bottom: 80px;
  position: relative;
}

.searchresults-nav {	// results page nav (arrows and numbers)
	text-align: center;
	border-top: 2px;
	border-top-color: #ededed;
	border-top-style: solid;
	padding-top: 30px;
	div {
		display: inline-block;
		.link {
			font-family: "Brown-Pro-Bold";
			@include points("font-size", 16);
			float: left;
			a {
				display: block;
				color: #c2c6ca;
				text-align: center;
				min-width: 24px;
				padding: 2px;
				margin: 5px;
			}
			&:hover a, &.active a {
				background-color: $yellow;
				color: #252a2c;
				text-decoration: none;
				cursor: pointer;
				&.arrow-prev, &.arrow-next {
					background: $white;
				}
			}
		}
		.arrow-prev:before {
		    @include points("font-size", 12);
		    content: "\25C0";
		    color: #2f3132
		}
		.arrow-next:before {
		    @include points("font-size", 12);
		    content: "\25B6";
		    color: #2f3132
		}
	}
}
.touch .searchresults-nav {
	div {
		.link {
			&:hover a {
				background-color: $white;
				color: #c2c6ca;
			}
		}
	}
}

.searchresult-items {
	margin-top: 5%;
	@media (max-width: $screen-xs-max) {
		margin-top: 46px;//94px;//30%;
		text-align: center;
	}
	.searchresult-header {
		@media (max-width: $screen-xs-max) {
			padding-bottom: 20px;
			border-bottom: 2px solid #ededed;
		}
		.searchresultTitle {
			font-size: 2.25em;
			font-family: "Brown-Pro-Bold";
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 500;
			color: inherit;			
		    line-height: 1.1;
			margin-top: 0px;
			@media (max-width: $screen-xs-max) {
				@include points("font-size", 28);
				text-align: center;
			}
		}
		h1.searchresultTitle {
			margin-top: 0px;
			@media (max-width: $screen-xs-max) {
				@include points("font-size", 28);
				text-align: center;
			}
		}
		.msg-support {
			font-style: italic;
			color: #999999;
			display: inline-block;
			@media (max-width: $screen-xs-max) {
				@include points("font-size", 16);
			}
		}
		.link-support a {
			@include points("font-size", 12);
		    text-transform: uppercase;
		    // Styleguide styling?
		    // color: #1194f6;
		    // cursor: pointer;
		    // &:hover {
		    // 	text-decoration: none;
		    // }
		}
	}
	.result-count {
		color: #999999;
		text-align: right;
	}
}


.searchresult-item {
	color: $dark-gray;
	z-index: 2;
	background-color: $white;
	display: inline;
	width: 100%;
	@media(max-width: $screen-xs-max) {
		margin-bottom: 10px;
	}
	@media(min-width: $screen-sm-min) {
		min-height: 220px;
	}

	&:hover {
		background-color: #ededed;
		cursor: pointer;
		color: $dark-gray;
		text-decoration: none;
		.product-meta .links {
			opacity: 1;
		}
	}

	.product-image {
		position: relative;
		width: 220px;
		height: 220px;
		text-align: center;
		margin-top: 0;
		float: left;
		@media (max-width: $screen-sm-max) {
			width: 100%;
		}
		img {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			max-width: 200px;
			max-height: 150px;
			@media (max-width: $screen-sm-max) {
				max-height: 200px;
			}
		}
	}
    @media (max-width: $screen-sm-max) {
		.product-image {
			padding: 20px;
		}
    }

	.product-meta {
		height: 220px;
		white-space: normal;
		@media(max-width: $screen-sm-max) {
			padding: 30px;
			height: auto;
			margin-bottom: 90px;
			text-align: center;
		}

		.product-info {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			max-width: 400px;
			@media(max-width: $screen-sm-max){
				top: auto;
				transform: none;
				max-width: 768px;
			}
			@media(min-width: $screen-md-min) {
				position: absolute;
			}
			padding: 0;
			margin: 0;

			.product-name,
			.product-description {
				display: block;
			}

			.bundle {
				font-family: "Brown-Pro-Bold";
				@include points("font-size", 10);
				background-color: #ff7075;
				color: $white;
				text-transform: uppercase;
				padding: 3px 5px;
				//position: absolute;
				//top: -25px;
				@media(max-width: $screen-sm-max) {
				    width: 52px;
				    margin: 0 auto;
				    left: 0;
				    right: 0;
				}
			}
				
			.kirsche {
				background-color: #f93248;
			}
			.neo {
				color: $black;
				background-color: #ff7075;
			}
			.electrico {
				color: $black;
				background-color: #dbfd01;
			}
			.ciano {
				color: $black;
				background-color: #00ead0;
			}
			.blue {
				color: $black;
				background-color: #1194f6;
			}
			.lila {
				background-color: #a401ff;
			}
			.khole {
				background-color: #252a2c;
			}
			
				
			.product-name {
				font-weight: bold;
				@include points("font-size", 18);
				color: #333333;
			}

			.price {
				display: none;		// Not part of search results design.
				font-family: "Brown-Pro-Light";
			}

			.disclaimer {			// Not part of search results design.
				display: none;
				margin-top: -8px;
				min-width: 100%;

				&:before{
					content: " ";
					display: block;
					clear: both;
				}
			}

			.product-description {
				color: #777777 !important;
				font-style: italic;
				@include points("font-size", 14);
				@media(min-width: $screen-md-min) {
					@include points("font-size", 16);
				}
				@media(max-width: $screen-xs-max) {
					// font-family: "Brown-Pro-Italic";
				}
			}

		}

		.links {
			@include points("font-size", 12);
			text-transform: uppercase;
			opacity: 0;
			position: absolute;
			padding-top: 10px;
			//bottom: 40px;
			.link-learn, .link-support {
				color: #1194f6;
				padding: 0 10px;
			}
			.link-support {
			}
			.link-learn {
				padding-left: 0;
				@media(max-width: $screen-sm-max) {
					padding-left: 10px;
				}
			}
			@media(max-width: $screen-sm-max) {
				opacity: 1;
				position: relative;
				bottom: auto;
				padding-top: 20px;
			}
		}
	}
}

.no-touch {
	.product-description{
		@media(max-width: $screen-sm-max){
			display: block !important;
		}
	}

	.force-white-text:not(.strikethrough) *{
		@media (max-width: $screen-xs-max){
			color: $dark-gray !important;
		}
	}
}

.no-touch .searchresult-item:hover {
	.product-image {
	}
	.product-description {
		display: block !important;
	}
}

.touch .searchresult-item{
	background: none;
	.product-meta{

		.product-info{
		}
		.links {
			opacity: 1;
		}
		.product-description{
			display: block !important;

			.no-touch .product-description{
				@media(max-width: $screen-xs-max){
					display: block !important;
				}
			}
		}
	}
}