//default type styles
h1{
	font-family: "Brown-Pro-Bold";
	text-transform: uppercase;

}

h2, h3{
  font-family: "Brown-Pro-Light";
}
/*
h1{
	@include points("font-size", 64);

	&.h1-main{
		@include points("font-size", 120);
	}
}
*/
h2{
	@include points("font-size", 32);
}


h1.light, h2.light, h3.light, h4.light, h5.light {
	font-family: "Brown-Pro-Light";
}

h1.regular, h2.regular, h3.regular, h4.regular, h5.regular {
	font-family: "Brown-Pro-Regular";
}

.strikethrough {
  //color: $faded;
  margin-right: 0.4em;
  position: relative;

  >:last-child:after{
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 1rem;
  border-top: 1px solid $faded;
  }
}

//fixes strikethrough in forced color situations
.force-white-text * >.strikethrough >:last-child:after{
    border-color: rgba(255,255,255,0.7) !important;
}

.force-dark-text * >.strikethrough * {
  color: $faded !important;
}

.product-tile, .product-tile:hover {
	&.force-white-text .strikethrough {
		* {
			color: rgba(255,255,255,0.7) !important;
			&:hover{
				color: rgba(255,255,255,0.7) !important;
			}
		}

		>:last-child:after {
			border-color: rgba(255,255,255,0.7) !important;
		}
	}

	&.force-dark-text .strikethrough {
		* {
			color: rgba(0,0,0,0.4) !important;
			&:hover{
				color: rgba(0,0,0,0.4) !important;
			}
		}

		>:last-child:after {
			border-color: rgba(0,0,0,0.4) !important;
		}
	}
}
.promo-tile, .promo-tile:hover {
	&.force-white-text .strikethrough {
		* {
			color: rgba(255,255,255,0.7) !important;
			&:hover{
				color: rgba(255,255,255,0.7) !important;
			}
		}

		>:last-child:after {
			border-color: rgba(255,255,255,0.7) !important;
		}
	}

	&.force-dark-text .strikethrough {
		* {
			color: rgba(255,255,255,0.7) !important;
			&:hover{
				color: rgba(255,255,255,0.7) !important;
			}
		}

		>:last-child:after {
			border-color: rgba(255,255,255,0.7) !important;
		}
	}
}


@media(max-width: $screen-xs-max) {
	.no-touch {
		.promo-tile, .product-tile {
			&.force-white-text .strikethrough {
				* {
					color: rgba(0,0,0,0.4) !important;
					&:hover{
						color: rgba(0,0,0,0.4) !important;
					}
				}

				>:last-child:after, >:last-child::after {
					border-color: rgba(0,0,0,0.4) !important;
				}

			}

			&.force-dark-text .strikethrough {
				* {
				color: rgba(0,0,0,0.4) !important;
				}
				>:last-child:after, >:last-child::after {
					border-color: rgba(0,0,0,0.4) !important;
				}
			}
		}
	}
}