@mixin footer-text {
		color: $white;

		&:hover {
			text-decoration: none;
			color: $white;
		}
}
@mixin footer-column {
		@include points("font-size", 14);
		display: inline-block;
		margin-bottom: 10px;
		padding-left: 0;
		@media (min-width: $screen-sm-max) {
			@include points("font-size", 16);
			margin-bottom: 30px;
		}
		padding-left: 0;
		&::after {
			color: $medium-gray;
			content: "|";
			padding: 0 0 0 10px;
			@media (min-width: $screen-sm-max) {
				display: none;
			}
		}
}
footer {
    @include styled-global;
	width: 100%;
	display: block;
	color: $white;
	background-color: $dark-gray;
	padding-top: 30px;

	@media (min-width: $screen-sm-max) {
		padding-top: 50px;
	}

	.col-sm-10 {
		@media (min-width: $screen-sm-max) {
			@include remove-padding;
		}
	}

	a {
		@include footer-text;
	}

	#footer-links {
		border-bottom: 1px solid $medium-gray;
		@media (min-width: $screen-sm-max) {
			padding-bottom: 40px;
		}

		.col-md-3 {
			@media (min-width: $screen-sm-max) {
				@include remove-padding;
			}
		}

		.col-md-3 {
			@include footer-column;
		}
	}

	.social {
		margin-top: 30px;
		@media (min-width: $screen-sm-max) {
			margin-top: 50px;
		}
	}

	.social-newsletter-container {
		min-height: 60px;
		overflow: hidden;
		position: relative;
		@media (max-width: $screen-sm-max) {
			min-height: 140px;
		}
	}

	ul.social-links {
		display: block;
		list-style: none;
		margin: 0 0 10px 0;
		padding: 0;
		height: 30px;
		@media (min-width: $screen-sm-max) {
			display: inline-block;
			float: left;
			margin: 15px 0 0 0;
		}

		li {
			float: left;
			padding: 0 30px 0 0;
			position: relative;
			height: 27px;
			width: 27px;
			margin: 14px 15px 0 0;
			@media (max-width: $screen-sm-max) {
				display: inline-block;
				float: left;
				margin: 0 15px 0 0;
			}


			img {
				@include vertically-centered;
				position: absolute;
			}
		}
	}

	#sign-up-form{
		margin-top: 1em;
		@media (min-width: $screen-sm-max){
			margin-top: 0;
		}
	}

	.newsletter-component {
		position: relative;
		display: block;
		max-width: 370px;
		width: 100%;
		@media (min-width: $screen-sm-max) {
			display: block;
			float: right;
		}

		.newsletter-inputs{
			display: block;
			white-space: normal;
			min-height: 45px;
		}

		#newsletter-email {
			color: $input-color-placeholder;
			display: block;
			border: none;
			border-radius: 0;
			appearance: none;
			padding: 10px 20px;
			float: left;
			height: 45px;
			max-width: 325px;
			width: 100%;
			z-index: 0;
			@media (min-width: $screen-sm-max) {
				float: left;
			}
			@media (max-width: $screen-xs-max) {
				max-width: 230px;
			}

		}

		#newsletter-email-error{
			font-size: 13px;
			position: relative;
			font-family: "Brown-Pro-italic";
			color: $red;
			max-width: 265px;
			top: 1px;
			opacity:1;
			transition: all .3s ease-in;

			@media (min-width: $screen-sm-max){
				left: 0;
				text-align: left;
			}
			@media (max-width: $screen-xs-max) {
				max-width: 265px !important;
			}
		}

		#submit-button {
			cursor: pointer;
			display: inline-block;
			float: left;
			border-radius: 0;
			appearance: none;
			height: 45px;
			padding: 0;
			background-color: transparent;
			border: none;
			border-radius: 0;
			width: 45px;

			@media (min-width: $screen-sm-max) {
				float: left;
			}
		}

		form {
			display: block;
		}

		#newsletter-email-label {
			@include points("font-size", 14);
			display: block;
			letter-spacing: 1px;
			margin-right: 15px;
			text-transform: uppercase;
			@media (min-width: $screen-sm-max) {
				display: block;
			}
		}

		.newsletter-message {
			display: none;
			opacity: 0;
			text-transform: uppercase;
			padding-right:3px;
			font-style: italic;
			@media (max-width: $screen-md-max){
				display: block !important;
			}
		}
	}

	.copyright {
		@include points("font-size", 12);
		margin: 10px 0;
		@media (min-width: $screen-sm-max) {
			margin: 30px 0;
		}

		p {
			display: block;
			margin-top: 11px;

			@media (max-width: $screen-sm-max) {
				display: inline-block;
				margin: 10px 0 0 0;
			}
		}

	}

	.language {
		display: block;
		margin: 0 0 30px 0;
		@media (min-width: $screen-xs-max) {
			display: inline-block;
			float: right;
			margin: 0;
		}
		@media (max-width: $screen-xs-max) {
			margin: 0 0 10px 0;
		}


		div {
			display: inline-block;
			z-index: 0;
		}
		.flag {
			img {
				position: relative;
				margin-bottom: 2px;
			}
		}

		.country, .footer-select {
			vertical-align: middle;
		}

		.country a{
			color: $white;
			margin-right: 8px;
			outline: 0;
			text-decoration: none;

		}
		.name {
			text-transform: uppercase;
			padding: 0 8px 0 5px;
		}
		.arrow {
			border-top: 4px solid transparent;
			border-bottom: 4px solid transparent;
			border-left: 6px solid $white;
			margin: 0 20px 0 0;
			transition: all .1s ease-in;
		}

		a:hover > .arrow {
			transform: translateX(6px);
		}
	}

	.chosen-container-active {
		&.chosen-with-drop {
			.chosen-single {
				background: $medium-gray;
				border: none;
				box-shadow: none;
			}
		}
	}
	.chosen-container {
		.chosen-search {
			display: none;
		}
		.chosen-drop {
			top: auto !important;
			bottom: 40px;
			background: $medium-gray;
			border: none;
			border-radius: 0;
			box-shadow: none;
			padding: 10px 15px;
			height: auto;
			span {
				overflow: visible;
			}
			margin-top: -1px;
			padding: 0;
		}
		&.chosen-with-drop {
			.chosen-single {
				div {
					b {
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
						border-bottom: 6px solid $white;
						border-top: none;
					}
				}
			}
		}
		.chosen-single {
			color: $white;
			background: $medium-gray;
			border: none;
			border-radius: 0;
			box-shadow: none;
			padding: 8px 15px;
			height: auto;
			span {
				overflow: visible;
			}
			div {
				width: 28px;
				padding: 0 0 0 10px;
				b {
					@include vertically-centered;
					width: 0;
					height: 0;
					border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-top: 6px solid $white;
					font-size: 0;
					line-height: 0;
					transition: border 0.5 linear;
				}
			}
		}
		.chosen-results {
			color: $white;
			margin: 0;
			padding: 0;
			li {
				padding: 10px 15px;
				&.highlighted {
					background: $black;
				}
			}
		}
	}

}
