.single-column {
	position: relative;
	@media (min-width: $screen-sm-min) {
		margin-bottom: 2%;
		margin-top: 1%;
		padding-left: 0;
	}

		a {
			color: #428bca;
			text-decoration: none;
			padding: 10px 0;			
			@media(min-width: $screen-lg-min) { 
				opacity: .9;
				&:hover {
					opacity: 1;
					text-decoration: underline;
				}
			}
		}


}
