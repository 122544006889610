/*
.reverse-colors {
	@extend .force-white-text;
}
*/
a.more-content {
	display: block;
}

/* ==change location */
.location {
	.location-title {
		line-height: normal !important;
	}
	.country {
	    margin: 0;
	    padding: 0 0 0 26px;
	    overflow: hidden;
	    _height: 1%;
	    _font-size: 1.0em;
	    li {
		    list-style: none;
		    background: none;
		    
			img {
			    margin: -4px 7px 0 -26px;
			    width: 16px;
			    height: 11px;
			}
			a {
				font-size: .9em;
			}
	    }
	}
}


.businessSubCat {
    font-size: 0.875em;
    float: left;
    margin-top: -40px;
}


/* ==sitemap */
.sitemap {
	li {
		list-style-type: square;
		color: $medium-gray;		 
		&.no-link, &.sub, &.link3 {			
			margin-bottom: 4px;
		}		
	}
}

/* ==newsletter */
form {
	.btn {
		width: 100%;
		max-width: 400px;
		&#delBtn {
			background-color: $medium-gray;
			border-color:  $text-gray;
		}
	}
}

/* ==press asset*/
.asset {
	.subTwo {
		font-weight: bold;
	}
	.productCell {
		min-height: 340px;
		@media(max-width: 480px) { 
			width: 100% !important;
		}
	}
	.prodNav .prembedDisp,
	.companyLogo,
	.prodNav .categoryLink,
	.noProd > p,
	.noLogo > p {
		display:none;
	}
	.prodNav >label{
		display: inline-block;
	}
	.noProd p {
	  color: red;
	}
	h6.panel-title {
	    min-height: 25px;
	    line-height: 21px;
	    font-size: 1em;
	    margin-left: 10px;
	}
	.imgWrapper img {
		width: 100%;
	}
	
	.addContent {		
		& > div {
			padding: 0 15px;
		}
		& > div:nth-child(even) {
			padding-right: 0;
		}		
		& > div:nth-child(odd) {
			padding-left: 0;
		}	}
	
}

.prembedActive{
	display:block !important;
}


// press releases 
.release-archive {
	margin: 0;
	li {
		list-style: none;
		background: transparent none repeat scroll 0 0 !important;
		display: inline;
		margin: 0;
		padding: 0 10px;
		border-left: 1px solid #7e868d;
		&.first {
		    padding: 0 10px 0 0;
		    border: none;
		}
	}
}

.press-releases {
	clear: both;
	margin-top: 10px;
	li {
		list-style: none;
		padding: 0 0 10px 11em;
		strong {
			float: left; 
			margin-left: -12em;
		}
		a {
			strong {
				float: none;
				margin-left: 0;
			}
		}
	}
}



// articles
body.article {
	.content.container {
		div {
			line-height: 1.4;
		}
		h4 {
			padding-bottom: 5px;
			margin-top: 40px;
			margin-bottom: 10px;
		}
	}
}


.multiGrid {
	padding-left: 0;
}

.impactLayout {
	.multiGrid {
		& > div {
			width: 23%;
			min-width: 150px;
			text-align: left;
			margin: 0 15px 30px 10px;
			display: inline-block;
			padding: 0;
			background: transparent;
			vertical-align: top;				
		}
		&.wide {
			h5 {
				margin-left: 10px;
			}			
			& > div {
				width: 30%;
				min-width: 150px;			
			}
			& > .three {
				margin-bottom: 40px;
				width: 32%;
				max-width: 325px;
				margin-right: 25px;
				&:nth-of-type(3n) {
					margin-right: 25px;
				}
			}
			
		}
		
		// max width: 961
		@media(max-width: $screen-sm-max) { 
			padding-left: 10px;
			padding-right: 10px;
			margin-left: -10px;
			&.wide {
				& > div {
					width: 30%;
					min-width: 150px;			
				}
				& > .three {
					margin-bottom: 20px;
					min-width: 250px;

				}
				
			}		  
		  
		}
			
		
		
	}
}



.formGrid {
	form {
		.radio-inline, .radio {
			input[type="radio"] {
				margin-right: 5px;
				-webkit-appearance: radio;	
			}
		}
		
		input[type="checkbox"] {
			-webkit-appearance: checkbox;	
			height: 20px;
			margin-right: 5px;
			margin-bottom: 3px;
		}
		.radio-inline + .radio-inline, 
		input[type="checkbox"] + input[type="checkbox"] {
		    margin-top: 0;
		    margin-left: 0;
		    display: block;
		}	
		textarea, select, input[type="text"] {
			max-width: 400px;
		}
		@media(max-width: $screen-xs-max) { 
			.text-right {
				text-align: left;
			}
		}	
		& > .row:after {
		    border-bottom: 1px dotted #ccc;
		    display: block;
		    padding-bottom: 13px;
		}		
		& > .row:last-child:after{
		    border-bottom: none;
		}			
	}

}

.sidebar .more ul {
	list-style: none;
	padding-left: 0;
} 

/* containing float for press */
.press .firstCol {
	overflow: hidden;
}
/* promotions page footer included from renga */
body.promotion.showcase {
	footer {
 	  font-size: 1.20em !important;
	}
	#subnav .links > li > a {
		font-size: 13.65px;
	}
	#subnav .links > li > a + .sub a {
		font-size: 13.3087px;
	}
}
/* instagram icons */
.ig-b- {
	display:inline-block;
}

.ig-b- img {
	visibility:hidden;
}

.ig-b-:hover {
	background-position:0 -60px;
}

.ig-b-:active {
	background-position:0 -120px;
}

.ig-b-32 {
	width:32px;
	height:32px;
	background:url(http://badges.instagram.com/static/images/ig-badge-sprite-32.png) no-repeat 0 0;
}

@media only screen and (webkit-min-device-pixel-ratio: 2), only screen and (min-moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx){
	.ig-b-32 {
		background-image:url(http://badges.instagram.com/static/images/ig-badge-sprite-32@2x.png);
		background-size:60px 178px;
	}
}
/* bynder-widget issue */
@media only screen and (max-device-width: 568px) 
and (min-device-width: 320px) and (orientation: portrait) {
	.companyLogo .bynder-widget-2-col ul.bynder-widget-media-items li{
		width: 100%;
	}
}
