// immersion guides
#immersion-guides{
	@font-face {
	  font-family: "Helvetica Nueu Cond";
	  src: url("/asset/fonts/102ab74c-0e84-4fe5-a17a-b20fb643591a.woff") format("woff");
	}
	
	@-ms-viewport {
	  width: device-width;
	}
	
	* {
		font-family: "Helvetica Nueu Cond", Helvetica, Arial, sans-serif !important;
	}	
	body {
		color: #000;
		padding-top: 60px;
		padding-bottom: 40px;
		line-height: 22px;
		overflow-x: hidden;
		width: 100%;
		a {
			color: red;
			&:hover, &:visited {
				color: red;
			}
		}	  
	}
	
	ol, ul {
		list-style-position:inherit;
		padding-left: 30px;
	}
	
	.container img {
	  max-width: 100%;
	  height: auto;
	}
	
	th img {
	  width: auto;
	  height: auto;
	}
	
	h2 {
	  text-transform:none;
	}
	#desktopTopHeading {
		padding-left: 0;
		font-weight: 500;
	}
	
	.spacer {
	  clear: both;
	  height: 1px;
	  margin: 15px 0;
	}
	
	.hide-text {
	  font: 0/0 a;
	  color: transparent;
	  text-shadow: none;
	  background-color: transparent;
	  border: 0;
	}
	
	.input-block-level {
	  display: block;
	  width: 100%;
	  min-height: 30px;
	  -webkit-box-sizing: border-box;
	  -moz-box-sizing: border-box;
	  box-sizing: border-box;
	}
	
	.hidden {
	  display: none;
	  visibility: hidden;
	}
	.navbar {
		min-height: 40px;
		.navbar-inner {
			min-height: 40px;
		}		
	}
	
	
	.sectionImage {
	  position: relative;
	  margin: 0;
	  img {
	    margin-left: -20px;
	    &.moreLeft {
	      margin-left: -30px;
	    }
	    &.lessLeft {
	      margin-left: -10px;
	    }
	  }
	}
	
	.sectionDesc {
	  fieldset {
	    .row {
	      margin-left: 0;
	    }
	    p {
	      width: 90%;
	    }
	  }
	  .col-md-2.col-sm-2.col-xs-2 {
	    padding-left: 0;
	    padding-right: 0;
	  }
	}
	
	.sectionName {
	  color: #000 !important;
	  font-weight: normal;
	  /*padding: 0 0 3px 0;*/
	  vertical-align: top;
	  white-space: normal;
	}
	
	.table {
	  margin: 20px 0 10px;
	  > {
	    thead > tr > {
	      th, td {
	        vertical-align: top;
	      }
	      th {
	        border-top: none;
	        vertical-align: middle;
	      }
	    }
	    tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
	      border-top: none;
	      vertical-align: middle;
	    }
	  }
	  tr td {
	    text-align: left;
	  }
	}
	
	.table-striped > tbody > tr:nth-child(odd) > {
	  td, th {
	    background-color: #eee;
	  }
	}
	
	.table th {
	  img {
	    max-width: 15px;
	  }
	  &:first-child {
	    width: 30px;
	  }
	  &:nth-child(2) {
	    max-width: 200px;
	  }
	}
	
	.tableGrid {
	  vertical-align: top;
	  font-size: 10px;
	}
	
	.shortHeights {
	  font-size: 14px;
	  list-style: none outside none;
	  margin: 0;
	  max-width: 250px;
	  width: 100%;
	  padding: 0;
	  background-color: #fff;
	  max-height: 320px;
	  overflow-y: auto;
	  li {
	    height: auto;
	    min-height: 42px;
	    width: 100%;
	  }
	}
	
	.padImgRight {
	  margin-right: 40px;
	  margin-left: -25px;
	}
	
	.immersionGuide {
	  color: #fff;
	  position: fixed;
	  top: 10px;
	  left: 5px;
	  width: 100%;
	  margin: 0;
	  z-index: 2000;
	}
	
	.homepageButton, .homepageButton2 {
	  cursor: pointer;
	  width: 150px;
	  height: 159px;
	  margin: 0;
	  margin-right: 65px;
	  background: #fff;
	  color: #999;
	  text-align: center;
	  line-height: 1em;
	  float: left;
	  padding: 5px;
	  font-size: .8em;
	  text-transform: uppercase;
	}
	
	.homepageButton img, .homepageButton2 img {
	  padding-top: 15px;
	  padding-bottom: 15px;
	  max-width: 77px;
	  width: 100%;
	  height: auto;
	}
	
	.homeButton {
	  max-width: 100px;
	}
	
	.line2 {
	  margin-top: -5px;
	}
	
	.line22 {
	  margin-top: -5px;
	  line-height: 1.0em;
	  padding-bottom: 1px;
	}
	
	.dropdown-backdrop {
	  position: static;
	}
	
	#homeButton {
	  max-width: 100px;
	  padding-bottom: 5px;
	}
	
	#troubleshooting {
	  input {
	    vertical-align: top;
	    margin-right: 5px;
	  }
	  label {
	    width: 90%;
	  }
	}
	
	/* pending style */
	#section2 img.med {
	  max-width: 350px;
	  border: 1px solid #bcbcbc;
	}
	
	img {
	  &.med {
	    max-width: 350px;
	    border: 1px solid #bcbcbc;
	  }
	  &.med-noborder {
	    max-width: 350px;
	    border: 1px solid #bcbcbc;
	    border: none;
	  }
	  &.noscale {
	    max-width: auto;
	    border: none;
	  }
	}
	
	/* CALLOUTS */
	.callout {
	  white-space: nowrap;
	  font-size: 1em;
	}
	
	.imageCaption {
	  font-size: 0.85em;
	  font-weight: bold;
	  color: #666;
	  margin-bottom: 1em;
	  &.callout {
	    position: absolute;
	    line-height: 1em;
	    margin-top: 8px;
	    width: 135px;
	    text-align: right;
	  }
	  &.callout-right {
	    position: absolute;
	    line-height: 1em;
	    margin-top: 8px;
	    width: 135px;
	    text-align: right;
	    text-align: left;
	    margin-left: 165px;
	    width: 165px;
	  }
	}
	
	#btButton.imageCaption {
	  margin-top: 18px;
	}
	
	#powerButton.imageCaption {
	  margin-top: 55px;
	}
	
	#volButtons.imageCaption {
	  margin-top: 154px;
	}
	
	#auxJack.imageCaption {
	  margin-top: 67px;
	}
	
	#dRing.imageCaption {
	  margin-top: 104px;
	}
	
	#chargeLED.imageCaption {
	  margin-top: 128px;
	}
	
	#usbConnector.imageCaption {
	  margin-top: 142px;
	}
	
	#pressToPower.imageCaption {
	  margin-top: 14px;
	  text-align: left;
	  margin-left: 42px;
	}
	
	#btButton2.imageCaption {
	  margin-top: 197px;
	  width: 123px;
	}
	
	#volButtons2.imageCaption {
	  margin-top: 40px;
	  width: 188px;
	}
	
	#volButtons3.imageCaption {
	  margin-top: 22px;
	  width: 175px;
	  line-height: 14px;
	}
	
	#troubleshooting li {
	  margin-bottom: 10px;
	  label {
	    margin-bottom: 0;
	    vertical-align: top;
	  }
	}
	
	/* LEFT NAV */
	
	.phoneNav {
	  margin-left: 0;
	  div {
	    float: left;
	    width: 105px;
	    margin-left: 0;
	    margin-right: 5px;
	    margin-bottom: 13px;
	    text-align: center;
	    background: #fff;
	    a div {
	      margin: 0;
	    }
	    div {
	      background: transparent;
	    }
	    a {
	      padding-bottom: 18px;
	      color: #999;
	      font-family: "Helvetica Nueu Cond";
	      text-transform: uppercase;
	      &:hover, &:visited {
	        color: #999;
	        font-family: "Helvetica Nueu Cond";
	        text-transform: uppercase;
	      }
	      div {
		      font-size: 14px;
	      }
	    }
	  }
	}
	
	#leftNavBar .nav li a {
	  &:hover, &:visited {
	    color: #999;
	    text-transform: uppercase;
	  }
	}
	
	.phoneNavContainer {
	  padding-left: 0;
	  margin-left: -4px;
	  min-width: 300px;
	}
	
	.phoneNavButton {
	  width: 95px;
	  /*height: 120px;*/
	  min-height: 120px;
	}
	
	.phoneNav div a img {
	  max-width: 70px;
	  padding-top: 10px;
	  padding-bottom: 5px;
	}
	
	h3 {
	  &.sectionHeading, &.sectionHeading-no {
	    font-size: 1.3em;
	    padding-left: 2em;
	  }
	  &.sectionHeading {
	    line-height: 40px;
	  }
	}
	
	.sectionHeading {
	  color: white;
	  border: 0;
	  background-color: #00b4ff;
	}
	
	.sectionHeading-no {
	  color: black;
	  border-style: solid;
	  border-color: white;
	}
	
	h4 {
	  &.sectionTitle, &.sectionTitle-no {
	    font-size: 1.15em;
	    padding-left: 1em;
	  }
	  &.sectionTitle {
	    line-height: 30px;
	  }
	}
	
	.sectionTitle {
	  color: white;
	  border: 0;
	  background-color: #00b4ff;
	  margin-top: 40px;
	  margin-bottom: 20px;
	}
	
	.sectionTitle-no {
	  color: black;
	  border-style: solid;
	  border-color: white;
	}
	
	.sectionSubHeading {
	  color: #999;
	  text-transform: uppercase;
	}
	
	h5.sectionSubHeading img {
	  max-width: 34px;
	  margin-top: -2px;
	}
	
	.topBorder {
	  border-style: solid;
	  border-color: white;
	  border-left-width: 0;
	  border-right-width: 0;
	  border-top-color: #999999;
	  border-top-width: 1px;
	  margin-top: 20px;
	}
	
	.sectionSubtitle {
	  font-weight: bold;
	  margin-bottom: 5px;
	  color: #000;
	}
	
	.upper-copy {
	  text-transform: uppercase;
	}
	
	.primary-copy {
	  color: #0a69a9;
	}
	
	.sectionDesc {
	  .col-md-4, .col-sm-4, .col-xs-4 {
	    padding-left: 0;
	    font-size: 90%;
	  }
	}
	
	.troubleshootingIcon {
	  width: 36px;
	  height: 36px;
	  background-color: black;
	  margin: 0;
	  padding: 0;
	}
	
	.troubleshootingIconLower {
	  width: 36px;
	  height: 36px;
	  background-color: black;
	  margin: 0;
	  padding: 0;
	  &.blue {
	    background-color: #3b5998;
	  }
	  &.white {
	    background-color: #fff;
	  }
	}
	
	.prod-left-nav {
	  left: -150px;
	  top: 40px;
	  padding-left: 5px;
	  background-color: white;
	}
	
	.theme {
	  border-radius: 24px;
	  display: block;
	  margin: 5px auto 0;
	  padding-top: 0;
	  width: 100%;
	}
	
	.prod-left-nav {
	  .theme {
	    height: 45px;
	    max-width: 45px;
	    padding-top: 0;
	  }
	  li a {
	    img {
	      height: 45px;
	      max-width: 45px;
	      padding-top: 0;
	    }
	    div {
	      padding-top: 2px;
	    }
	  }
	  .sectionName {
	    /*padding-top: 2px;*/
	  }
	}
	
	.phoneNavButton div, .homepageButton > div {
	  padding-top: 10px;
	  line-height: 0.95em;
	}
	
	#topNav .theme, .phoneNav .theme {
	  border-radius: 40px;
	  max-width: 77px;
	}
	
	#topNav .theme, .phoneNav .theme {
	  max-width: 77px;
	  height: 77px;
	  padding: 0;
	  img {
	    max-width: 77px;
	    height: 77px;
	    padding: 0;
	  }
	}
	
	#leftNavBar .nav li {
	  text-align: center;
	  line-height: .8em;
	  margin-bottom: 1px;
	  max-width: 110px;
	  max-height: 70px;
	  width: 100%;
	  height: auto;
	  &.active {
	    background-color: #fff;
	  }
	  a {
	    text-transform: uppercase;
	    font-size: .6em;
	    padding: 0;
	    max-width: 105px;
	    margin: 0;
	    padding-bottom: 0px;
	    color: #999;
	    max-height: 90px;
	    width: 100%;
	    height: auto;
	  }
	}
	
	#hiddenScrollLNav {
	  display: none;
	}
	
	#desktopTopHeading {
	  font-size: 2.1em;
	  margin-top: 0;
	}
	
	#leftNavBarUL .backtoProduct {
	  margin-top: 5px;
	}
	
	/* ==Theming options */
	
	.default {
	  .theme, .sectionHeading, .sectionTitle {
	    background-color: #00b4ff;
	  }
	  a {
	    color: #ff0000;
	    &:hover, &:visited {
	      color: #ff0000;
	    }
	  }
	}
	
	.darkGray {
	  .theme, .sectionHeading, .sectionTitle {
	    background-color: #323232;
	  }
	  a {
	    color: #428bca;
	    &:hover, &:visited {
	      color: #428bca;
	    }
	  }
	}
	
	/*** specific sections ***/
	
	.eq-list {
	  list-style-type: none;
	  margin-left: 0;
	}
	
	/***  general tools ****/
	
	.floatL {
	  float: left;
	}
	
	.clearFloatOnly {
	  clear: both;
	  height: 0;
	  padding: 0;
	  margin: 0;
	  width: 0;
	}
	
	.padTop {
	  margin-top: 40px;
	  margin-bottom: 20px;
	}
	
	.padTopCover {
	  /* used on port cover only */
	  margin-top: 40px;
	  margin-bottom: 20px;
	}
	
	.smallPadTop {
	  margin-top: 20px;
	}
	
	.logo {
	  position: fixed;
	  top: 2px;
	  left: 5px;
	  z-index: 2000;
	}
	
	.nav-list > .active > a {
	  color: #ffffff;
	  text-shadow: none;
	  background-color: #fff;
	  &:hover {
	    color: #ffffff;
	    text-shadow: none;
	    background-color: #fff;
	  }
	}
	
	.nav > li > a:hover {
	  text-decoration: underline;
	  background-color: #fff;
	}
	
	.navbar .btn-navbar {
	  margin-right: 15px;
	}
	
	h5 {
	  img {
	    margin-left: 2px;
	    max-width: 30px;
	    max-height: 30px;
	  }
	  .midHeading {
	    margin-top: 20px;
	  }
	}
	
	.affix.footer {
	  background: none repeat scroll 0 0 #fff;
	  bottom: 0;
	  display: block;
	  font-size: 11px;
	  height: 25px;
	  left: 0;
	  text-align: center;
	  vertical-align: middle;
	  width: 100%;
	}
	
	#hiddenScrollTrigger, #hiddenScrollLNav {
	  height: 0;
	}
	
	/* ==diagram layout */
	
	.diagram {
	  h5 {
	    margin-top: 0;
	    margin-bottom: 5px;
	    color: #000;
	  }
	  p {
	    font-size: 12px;
	    line-height: 18px;
	    margin-bottom: 0;
	  }
	  .col-md-6, .col-sm-6 {
	    padding-left: 7px;
	    padding-right: 7px;
	  }
	  > .row > div > .col-md-6 {
	    padding-left: 0;
	    padding-right: 8px;
	  }
	  .disclaimer {
	    display: none;
	  }
	}
	
	.colRight {
	  margin: 25px 0;
	  padding-left: 0;
	  padding-right: 0;
	  .row {
	    min-height: 65px;
	    display: block;
	    margin: 0;
	  }
	}
	
	div.homepageButton.backtoProduct > div > a {
	  color: #999;
	  text-decoration: none;
	}
	
	.phoneNav div a span img {
	  max-width: 70px;
	  padding-top: 10px;
	  padding-bottom: 5px;
	}
	
	.shortHeights > li.backtoProduct {
	  height: 45px;
	}
	
	.cookie-disclaimer .disclaimer-message {
	    padding: 1.25em;
	}	
	
	.table-overflow {
		overflow-x: auto;
		.table-responsive {
			width: 708px !important;
			border: none;
			tbody {
				border: none;
			}
			td img {
				padding: 0;
				margin: 0;
			}
		}
	}

	@media (max-width: 1024px) {
		#desktopTopHeading {
			padding-left: 1em;
		}
		.navbar-inverse {
		    background-color: transparent;
		    border: none;
		}		
		h3 {
		  &.sectionHeading, &.sectionHeading-no {
		      margin-left: -30px;
		      margin-right: -30px;	    
		  }
		 }
		
		
	  #mobileNavWrapper .container, .navbar-static-top .container, .navbar-fixed-top .container, .navbar-fixed-bottom .container {
	    max-width: 1024px;
	    width: 100%;
	    padding-left: 0;
	    padding-right: 0;
	  }
	  .nav-collapse {
	    &.in .btn-group {
	      margin-top: 5px;
	      padding: 0;
	    }
	    .dropdown-menu {
	      position: static;
	      top: auto;
	      left: auto;
	      float: none;
	      display: none;
	      max-width: none;
	      margin: 0 15px;
	      padding: 0;
	      background-color: transparent;
	      border: none;
	      -webkit-border-radius: 0;
	      -moz-border-radius: 0;
	      border-radius: 0;
	      -webkit-box-shadow: none;
	      -moz-box-shadow: none;
	      box-shadow: none;
	    }
	    .open > .dropdown-menu {
	      display: block;
	    }
	    .dropdown-menu {
	      &:before, &:after, .divider {
	        display: none;
	      }
	    }
	    .nav > li > .dropdown-menu {
	      &:before, &:after {
	        display: none;
	      }
	    }
	    .navbar-form, .navbar-search {
	      float: none;
	      padding: 10px 15px;
	      margin: 10px 0;
	      border-top: 1px solid #f2f2f2;
	      border-bottom: 1px solid #f2f2f2;
	      -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	      -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
	    }
	  }
	  .navbar-inverse .nav-collapse {
	    .navbar-form, .navbar-search {
	      border-top-color: #111111;
	      border-bottom-color: #111111;
	    }
	  }
	  .navbar .nav-collapse .nav.pull-right {
	    float: none;
	    margin-left: 0;
	  }
	  .nav-collapse {
	    overflow: hidden;
	    height: 0;
	    &.collapse {
	      overflow: hidden;
	      height: 0;
	    }
	  }
	  .shortHeights {
	    .theme {
	      height: 30px;
	      width: 30px;
	      display: inline-block;
	      border-radius: 15px;
	      float: left;
	      margin-right: 5px;
	    }
	    > li > a > {
	      img {
	        float: left;
	        height: auto;
	        margin-left: 0px;
	        margin-right: 0px;
	        max-width: 30px;
	        width: 100%;
	      }
	      span {
	        color: #000 !important;
	        float: left;
	        font-weight: normal;
	        line-height: 15px;
	        max-width: 80%;
	        padding: 0 0 3px 0;
	        vertical-align: top;
	        white-space: normal;
	      }
	    }
	  }
	  .navbar-fixed-top.navbar-inverse .navbar-inner {
	    background-color: transparent;
	    background-image: none;
	    border: none;
	    box-shadow: none;
	    -webkit-box-shadow: none;
	    -moz-box-shadow: none;
	    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0);
	    /* IE6-9 */
	    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0)";
	  }
	  .navbar-inverse {
	    .navbar-toggle {
	      background-color: #000;
	    }
	    #mobileNav.navbar-collapse {
	      max-height: initial;
	      top: 0%;
	      right: 14px;
	      margin-right: 0;
	      padding: 0;
	      background-color: #fff;
	      border: 1px solid rgba(0, 0, 0, 0.15);
	      border-radius: 4px;
	      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
	      float: right;
	      position: relative;
	      z-index: 1000;
	      clear: both;
	    }
	  }
	  .navbar-nav {
	    .open .dropdown-menu {
	      position: static;
	      float: none;
	      width: auto;
	      margin-top: 0;
	      background-color: transparent;
	      border: 0;
	      box-shadow: none;
	      float: right;
	      right: 14px;
	      > li > a, .dropdown-header {
	        padding: 5px 15px 5px 25px;
	      }
	      > li > a {
	        line-height: 20px;
	        &:hover, &:focus {
	          background-image: none;
	        }
	      }
	    }
	    > li > a {
	      min-height: 42px;
	    }
	  }
	  .navbar-toggle {
	    display: block;
	  }
	  .navbar-header {
	    float: right;
	  }
	  .immersionGuide.hidden-xs {
	    display: none !important;
	  }
	  .navbar-collapse.collapse {
	    display: none !important;
	    &.in {
	      display: block !important;
	    }
	  }
	  #mobileNavWrapper {
	    &.visible-xs {
	      display: block !important;
	    }
	    .navbar-toggle {
	      margin-bottom: 3px;
	    }
	    .navbar-collapse.collapsing {
	      overflow: inherit;
	    }
	  }
	  #leftNavBar.hidden-xs {
	    display: none !important;
	  }
	}
	

	
	@media (max-width: 980px) {
	  .homepageButton img, .homepageButton2 img {
	    padding-bottom: 0;
	  }
	  .homepageButton, .homepageButton2 {
	    margin-right: 20px;
	  }
	  .table th {
	    width: 75px;
	  }
	}
	
	@media (max-width: 800px) {
	  .col-md-6.sectionImage img, .col-sm-6.sectionImage img, .col-xs-6.sectionImage img {
	    width: 100%;
	  }
	}
	
	@media (min-width: 768px) {
	  .container {
	    max-width: 1024px;
	    width: 100%;
	  }
	}
	
	@media (max-width: 767px) {
	  body {
	    padding-left: 20px;
	    padding-right: 20px;
	  }
	  .shortHeights {
	    width: 250px;
	  }
	  .container > .navbar-header, .container-fluid > .navbar-header {
	    margin-right: 20px;
	  }
	  .container {
	    padding-left: 0;
	    padding-right: 0;
	  }
	  .padImgRight {
	    margin-right: 0;
	  }
	  .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
	    margin-left: -20px;
	    margin-right: -20px;
	  }
	  .container-fluid {
	    padding: 0;
	  }
	  .troubleshootingIcon, .troubleshootingIconLower {
	    width: 30px;
	    height: 30px;
	    background-color: black;
	    margin: 0;
	    padding: 0;
	  }
	  .row div {
	    &.troubleshootingIcon img {
	      max-width: 36px;
	      max-width: 36px;
	      margin-top: -3px;
	      margin-left: -3px;
	    }
	    &.troubleshootingIconLower img {
	      max-width: 36px;
	      max-width: 36px;
	      margin-top: -2px;
	      margin-left: -3px;
	    }
	  }
	  .navbar-inverse #mobileNav.navbar-collapse {
	    margin-right: 20px;
	  }
	  .sectionImage img {
	    margin: 0;
	    padding: 0 10px;
	  }
	  .sectionDesc > .sectionDesc {
	    padding: 0;
	  }
	  .shortHeights > li {
	    height: 45px;
	  }
	}
	
	@media only screen and (min-width: 360px) and (max-width: 640px) {
	  .shortHeights {
	    max-height: 220px;
	  }
	}
	
	@media (max-height: 650px) {
	  .prod-left-nav li a img {
	    max-height: 48px;
	  }
	}
	
	@media (max-width: 599px) {
	  .shortHeights {
	    max-height: 215px;
	  }
	  #mobileNavWrapper {
	    .navbar-toggle {
	      margin-top: 8px;
	    }
	    .navbar-header {
	      margin-bottom: 0;
	      top: 0;
	    }
	  }
	}
	
	@media (min-width: 590px) {
	  .nav-collapse.collapse {
	    height: auto !important;
	    overflow: visible !important;
	  }
	  .phoneNavContainer.visible-xs {
	    display: none !important;
	  }
	}
	
	@media (max-width: 589px) {
	  h3 {
	    &.sectionHeading, &.sectionHeading-no {
	      padding-left: 1.5em;
	    }
	    &.sectionHeadingTight {
	      color: black;
	      border-bottom-width: 1px;
	      margin-top: -6px;
	      margin-bottom: -1px;
	    }
	  }
	  .immersionGuide {
	    color: white;
	    position: fixed;
	    top: 0;
	    left: 15px;
	    width: 275px;
	    margin: 0;
	    z-index: 2000;
	    padding: 0;
	  }
	  .navbar-fixed-top {
	    margin-bottom: 20px;
	  }
	  .navbar-fixed-bottom {
	    margin-top: 20px;
	  }
	  .navbar {
	    .container {
	      width: auto;
	      padding: 0;
	    }
	    .brand {
	      padding-left: 10px;
	      padding-right: 10px;
	      margin: 0 0 0 -5px;
	    }
	  }
	  .nav-collapse {
	    clear: both;
	    .nav {
	      float: none;
	      margin: 0 0 10px;
	      > {
	        li {
	          float: none;
	          > a {
	            margin-bottom: 2px;
	          }
	        }
	        .divider-vertical {
	          display: none;
	        }
	      }
	      .nav-header {
	        color: #777777;
	        text-shadow: none;
	      }
	      > li > a {
	        padding: 9px 15px;
	        font-weight: bold;
	        color: #777777;
	        -webkit-border-radius: 3px;
	        -moz-border-radius: 3px;
	        border-radius: 3px;
	      }
	    }
	    .dropdown-menu a {
	      padding: 9px 15px;
	      font-weight: bold;
	      color: #777777;
	      -webkit-border-radius: 3px;
	      -moz-border-radius: 3px;
	      border-radius: 3px;
	    }
	    .btn {
	      padding: 4px 10px 4px;
	      font-weight: normal;
	      -webkit-border-radius: 4px;
	      -moz-border-radius: 4px;
	      border-radius: 4px;
	    }
	    .dropdown-menu li + li a {
	      margin-bottom: 2px;
	    }
	    .nav > li > a:hover, .dropdown-menu a:hover {
	      background-color: #f2f2f2;
	    }
	  }
	  .navbar-inverse .nav-collapse {
	    .nav > li > a, .dropdown-menu a {
	      color: #999999;
	    }
	    .nav > li > a:hover, .dropdown-menu a:hover {
	      background-color: #111111;
	    }
	  }
	  .navbar .btn-navbar {
	    display: block;
	  }
	  .navbar-static .navbar-inner {
	    padding-left: 10px;
	    padding-right: 10px;
	  }
	  .navbar-inner .dropdown-menu {
	    top: 35px;
	    right: 20px;
	    left: auto;
	    img {
	      margin-right: 5px;
	    }
	  }
	  .phoneNavContainer.visible-xs {
	    display: block !important;
	  }
	  .diagram .disclaimer {
	    color: #6e6e6e;
	    margin-bottom: 15px;
	    display: block;
	  }
	  #mobileNav {
	    border: none;
	  }
	  #mobileNavWrapper {
	    .container {
	      padding: 5px;
	    }
	    .navbar-toggle {
	      margin-right: 10px;
	    }
	  }
	}
	
	@media (max-width: 979px) and (min-width: 589px){
		#topNav .hidden-xs {
		    display: block !important;
		}	
	}
	
	@media only screen and (min-width: 480px) and (max-width: 980px) {
	  .desktopHome div div div {
	    padding-top: 10px;
	    line-height: 1.1em;
	  }
	}
	
	@media only screen and (min-width: 430px) and (max-width: 550px) {
	  .phoneNavContainer {
	    background: #fff;
	  }
	  .phoneNav div {
	    float: left;
	    width: 100px;
	    margin-left: 15px;
	    margin-bottom: 13px;
	    text-align: center;
	    background: #fff;
	  }
	}
	
	@media (max-width: 480px) {
	  .nav-collapse {
	    -webkit-transform: translate3d(0, 0, 0);
	  }
	  .page-header h1 small {
	    display: block;
	    line-height: 20px;
	  }
	  input {
	    &[type="checkbox"], &[type="radio"] {
	      border: 1px solid #ccc;
	    }
	  }
	  .form-horizontal {
	    .control-label {
	      float: none;
	      width: auto;
	      padding-top: 0;
	      text-align: left;
	    }
	    .controls {
	      margin-left: 0;
	    }
	    .control-list {
	      padding-top: 0;
	    }
	    .form-actions {
	      padding-left: 10px;
	      padding-right: 10px;
	    }
	  }
	  .media {
	    .pull-left, .pull-right {
	      float: none;
	      display: block;
	      margin-bottom: 10px;
	    }
	  }
	  .media-object {
	    margin-right: 0;
	    margin-left: 0;
	  }
	  .modal {
	    top: 10px;
	    left: 10px;
	    right: 10px;
	  }
	  .modal-header .close {
	    padding: 10px;
	    margin: -10px;
	  }
	  .carousel-caption {
	    position: static;
	  }
	  .product-set.multiGrid > .promoListing {
	    min-height: 350px;
	  }
	  .table {
	    overflow: auto;
	    display: block;
	    border: none !important;
	    width: 300px;
	  }
	  .col-md-6.sectionImage img, .col-sm-6.sectionImage img, .col-xs-6.sectionImage img {
	    width: 100%;
	    padding: 0;
	  }
	}
	
	@media (max-width: 470px) {	
		.row {
			.col-md-10.col-md-offset-2.col-sm-10.col-sm-offset-2 {
				padding: 0;
			}
		}
	  .col-xs-6, .col-xs-4, .col-xs-12 {
	    margin: 20px 0;
	    width: 95%;
	  }
	  .sectionDesc {
	    padding-left: 0;
	    padding-right: 0;
	    margin: 20px 0;
	    .col-md-4, .col-sm-4, .col-xs-4 {
	      padding-left: 15px;
	    }
	    > .sectionDesc {
	      margin: 0;
	    }
	  }
	  .diagram {
	    .col-sm-4, .col-sm-6 {
	      padding-left: 7px;
	      padding-right: 7px;
	    }
	  }
	  .sectionImage {
	    float: none;
	  }
	  .immersionGuide {
	    font-size: 1.4em;
	    color: white;
	    position: fixed;
	    top: 0;
	    /*15px*/
	    left: 20px;
	    width: 250px;
	    margin: 0;
	    z-index: 2000;
	  }
	  h3 {
	    &.sectionHeading, &.sectionHeading-no {
	      left: 0;
	    }
	  }
	  .imageCaption.callout {
	    left: -22px;
	    line-height: 10px;
	  }
	  .padTopCover {
	    margin-top: 0;
	  }
	  .immersionGuide {
	    width: 320px;
	  }
	  .navbar-inner {
	    min-height: 55px;
	  }
	}
	
	@media only screen and (min-width: 200px) and (max-width: 480px) {
	  div.backtoProduct.phoneNavButton div {
	    padding-top: 11px;
	    padding-left: 2px;
	  }
	  .shortHeights > li {
	    &.backtoProduct > a > span.sectionName {
	      padding-top: 2%;
	    }
	    > a > img {
	      margin-right: 5px;
	    }
	  }
	  .phoneNav div {
	    width: 140px;
	  }
	}
	
	@media only screen and (min-width: 480px) and (max-width: 980px) {
	  /*.homepageButton.backtoProduct > div {
	  	padding-top:10px;
	  }*/
	  div.backtoProduct.phoneNavButton div {
	    padding-top: 11px;
	    padding-left: 2px;
	  }
	  .shortHeights > li {
	    &.backtoProduct > a > span.sectionName {
	      padding-top: 2%;
	    }
	    > a > img {
	      margin-right: 5px;
	    }
	  }
	  .phoneNav div {
	    width: 140px;
	  }
	}
	
	@media (max-width: 980px) {
	  div.homepageButton.backtoProduct > span.theme {
	    margin-bottom: 0px;
	  }
	}
	
	@media only screen and (min-width: 981px) and (max-width: 1024px) {
	  .shortHeights > li {
	    &.backtoProduct > a > span.sectionName {
	      padding-top: 2%;
	    }
	    > a > img {
	      margin-right: 5px;
	    }
	  }
	}
	
	
	.long-languages {
		.homepageButton, .homepageButton2 {
		  width: 200px;
		  min-height: 160px;
		  height: auto;
		}
		
		h3.sectionHeadingTight {
		  text-align: left;
		  margin-left: 0;
		  margin-right: 0;
		}
		
		#leftNavBar .nav li a {
		  min-height: 65px;
		  width: 200px;
		}
		
		.prod-left-nav li a div {
		  display: none;
		}
		
		/* mobile dropdown nav */
		.shortHeights {
		  top: 100%;
		  li {
		    height: auto;
		    min-height: 43px;
		    margin-bottom: 6px;
		  }
		  > li > a {
		    white-space: normal;
		  }
		}
		
		@media only screen and (min-width: 430px) and (max-width: 550px) {
		  .phoneNav div {
		    float: left;
		    width: 140px;
		    margin-left: 15px;
		    margin-bottom: 13px;
		    text-align: center;
		    background: #fff;
		  }
		}
		
		@media (max-width: 470px) {
		  .phoneNav div {
		    width: 152px;
		    height: 130px;
		  }
		}
	}	
		
	
}






