.products-container {
  position: relative;
}

#products-grid {
  margin-bottom: 80px;
  position: relative;



 	@media(min-width: $screen-md-min) {
		&.uncategorized.unsorted {
			.product-tile {
				z-index: 3;
				&:nth-child(2), &:nth-child(3) {
					z-index: 0;
				}
			}
		}
	}

  &.uncategorized.unsorted.featured {
		.product-tile {
			z-index: 3;
		 	@media(min-width: $screen-sm-min) {
				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 370px; /* height of feature-tile */
					z-index: 1;
				}
		 	}
		 	@media(min-width: $screen-md-min) {
				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 460px; /* height of feature-tile */
					z-index: 1;
				}
		 	}
		 	@media(min-width: $screen-lg-min) {
				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 520px; /* height of feature-tile */
				}
				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					z-index: 1;
				}
			}
			@media (min-width: 1260px) {
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4),
				&.featuredProd:nth-child(4) {
					z-index: 1;
				}
				&:nth-child(2),
				&:nth-child(3){
					/* height of feature-tile */
					margin-bottom: 520px;
				}

			}

		}
	}

 	@media(min-width: $screen-lg-min) {
		&.uncategorized.unsorted {
			.product-tile {
				z-index: 3;
				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					z-index: 1;
				}
			}
		}
	}


	&.show-all {
		.product-tile {
			display: block;
		}
	}
}


.product-tile {
	color: $dark-gray;
	z-index: 2;
	background-color: $white;
	display: none;
	@media(max-width: $screen-xs-max) {
		&:nth-child(4) {
			//margin-bottom: 565px;
		}
	}
	@media(max-width: $screen-xs-max) {
		height: 525px;
		margin-bottom: 10px;
	}
	@media(min-width: $screen-sm-min) {
		height: 460px;
	}

	&:hover {
		color: $dark-gray;
		text-decoration: none;
	}

	.product-image {
		position: relative;
		width: 100%;
		height: 460px;
		text-align: center;
		margin-top: 0;
		img {
			margin-top: 50px;
			max-width: 280px;
			max-height: 280px;
		}
	}
    @media (max-width: $screen-xs-max) {
      .product-image {
        height: 390px;
      }
    }

	.product-meta {
		@media(max-width: $screen-xs-max) {
			padding: 20px;
		}

		ul.color-swatches {
			margin: 0;
			padding: 0;
				//display: none;
				list-style: none;
				position: absolute;
				top: 30px;
				right: 30px;
				bottom: auto;
				left: auto;
				@media(max-width: $screen-xs-max) {
					//display: block;
				}

				li.color-swatch {
					display: block;
					overflow: hidden;
					width: 18px;
					height: 18px;
					margin: 3px 0;
					border: 1px solid #565656;
					img {
						width: 40px;
						height: 40px;
						margin-top: -11px;
						margin-left: -11px;
					}
					&.disabled {
						opacity: 0.35;
						filter: alpha(opacity=35);
						cursor: not-allowed;
					}
				}
			}

			.product-info {
				top: 350px;
				right: 30px;
				left: 30px;
				@media(max-width: $screen-xs-max){
					top: 330px;
					left: 20px;
				}
				@media(min-width: $screen-sm-min) {
					position: absolute;
				}
				padding: 0;
				margin: 0;

				.product-name,
				.product-description,
				.price,
				.strikethrough,
				.disclaimer{
					display: block;
				}

				.price,
				.strikethrough,
				.disclaimer{
					float: left;
				}

				.product-name {
					@include styled-copy;
					@include points("font-size", 18);
				}

				.price {
					font-family: "Brown-Pro-Light";
				}

				.disclaimer {
					margin-top: -8px;
					min-width: 100%;

					&:before{
						content: " ";
						display: block;
						clear: both;
					}
				}

				.product-description {
					font-style: italic;
					color: $inbetween-gray !important;
					display: none;
					@include points("font-size", 14);
					@media(min-width: $screen-md-min) {
						@include points("font-size", 16);
					}
					@media(max-width: $screen-xs-max) {
					font-style: italic;
						color: $inbetween-gray !important;
					}
				}
			}

			.compare-checkbox-group {
				position: absolute;
				left: 30px;
				top: 15px;
			}

			.compare-checkbox-label {
				&.maxCompare {
					color: #f93248 !important;
				}
			}

			ul.color-swatches-mobile {
				margin: 0;
				padding: 0;
				display: block;
				list-style: none;
				bottom: 0;
				@media (min-width: 768px){
					display: none;
				}

				&:before{
					content: " ";
					clear: both;
					display: block;
				}

				li.color-swatch {
					display: block;
					overflow: hidden;
					width: 18px;
					height: 18px;
					float: left;
					margin: 2px;
					border: 1px solid #565656;
					img {
						width: 40px;
						height: 40px;
						margin-top: -11px;
						margin-left: -11px;
					}

				}
			}
		}

		.pre-order {
			color: $white !important;
			background-color: black;
			width: 100px;
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 10px;
			@include points("font-size", 10);
			text-align: center;
		}
	}

.no-touch {
	.product-description{
		@media(max-width: $screen-xs-max){
			display: block !important;
		}
	}
	.color-swatches{
		display: none;
	}

	.force-white-text:not(.strikethrough) *{
		@media (max-width: $screen-xs-max){
			color: $dark-gray !important;
		}
	}
	#product-hero {
		.force-white-text:not(.strikethrough) *{
			@media (max-width: $screen-xs-max){
				color: $white !important;
			}
		}
	}


}

.no-touch .product-tile:hover {

	.product-image {
		transition: background-color $hover-delay, color $hover-delay;
		background-color: $white !important;
	}
	.product-description {
		display: block !important;
	}
	.disclaimer {
		display: none;
	}
	.color-swatches{
		display: block;
	}


}

.compare-choices-btn,  a.compare-choices-btn{
  @include btn-util;
  @include label-small-uppercase;
  position: fixed;
  bottom: 25%;
  right: -1000px;
  z-index: 4;
  transition: all 0.5s cubic-bezier(.34,.8,.81,.99);
  &.active {
    right: 0;
  }
  :hover {
	  color: #dbfd01;
  }
  &.near-footer {
    top: auto;
    bottom: 0;
    position: absolute;
  }
}


.touch .product-tile{
	height: 620px;
	background: none;
	.product-meta{

		.product-info{
			top:472px;
			left: 8px;
		}
		.color-swatches{display: none;}
		.color-swatches-mobile{display: block;}

		.product-description{
			display: block !important;

			.no-touch .product-description{
				@media(max-width: $screen-xs-max){
					display: block !important;
				}
			}

			.no-touch .product-tile:hover {
				.product-image {
					transition: background-color $hover-delay, color $hover-delay;
					@media(min-width: $screen-sm-min) {
						background-color: $white;
					}
					cursor: pointer;
				}
			}
		}
	}

	.pre-order{
		top: 356px;
		bottom: auto;
		@media (min-width: $screen-sm-min){
			top:426px;
		}
	}
}
@media (max-width: $screen-xs-max) {
  .touch {
    .product-tile {
      height: 550px;

      &:nth-child(4){
        margin-bottom: 0;
      }
    }
    .featured {
      .product-tile {
        &:nth-child(4){
          //margin-bottom: 545px;
        }
      }
    }
  }
}
