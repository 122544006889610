#global-navbar {
    @include styled-global;
	padding-top: 15px;
	display: block;
	position: fixed;
	width: 100%;
	min-height: 60px;
	@media(min-width: $screen-sm-min) {
		height: 75px;
		padding-top: 20px;
	}
	color: white;
	z-index: 2000;
	top: 0;
	right: 0;
	left: 0;

	//clear background image for IE9/10 hack to allow buttons to be clickable
	background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQImWNgYGBgAAAABQABh6FO1AAAAABJRU5ErkJggg==');

	#nav-icon {
		margin-left: 20px;
		position: absolute;
		top: -35px;
		opacity: .5;
		&:hover {
			cursor: pointer;
		}
	}
	#logo-container {
		position: absolute;
		margin: 0 auto;
		@include horizontally-centered;
		@media(max-width: $screen-xs-max) {
			img {
				width: 100px;
			}
		}
	}
	.utility-nav {
		margin-right: 20px;
		padding-top: 8px;
		ul{
			list-style-type: none;
			li {
				display: block;
				float: left;
				@include points("padding-left", 20);
			}
		}

		svg {
			vertical-align:middle;
		}
		.open .search-icon {
			vertical-align: top;
			top: 8px;
		}
	}
}

// Logo animation
#logo-static{
	display: block;
	@media (min-width:$screen-sm-min){
		display: none;
	}
}

.animating{
	display: none;
	@media(min-width: $screen-sm-min){
		display: block;
		&.logo-ani{
			height: 40px;
			width: 132px;
			background: url('/images/log-logo-sprite-r.png') top center;
			animation: play 1.4s steps(37) 1s  forwards;

			&.hoverPlay{
				cursor:pointer;
				animation: hoverPlay 1.4s steps(37) forwards;
			}

			&.stoppedAnimation{
				background-position:0 -1440px;
				animation-play-state: paused;
			}

		}
	}
}
@keyframes play {
  100% { background-position: 0 -1480px;}
}
// Required to repeat animation on hover (w/o JS help)
// @see https://css-tricks.com/restart-css-animation/
@keyframes hoverPlay {
  100% { background-position: 0 -1480px;}
}


.search-widget {
	display:none;
	// padding:7px;
	margin-top:-12px;
	margin-right:-8px;
	line-height:34px;
	width:410px;
	position:relative;
	border: 1px solid #ededed;

	&.open {
		background:$white;
		display: inline-block;

		svg {
			position:relative;
			// top:12px;
			margin-right:8px;
			vertical-align: top;
		}
	}
	.form {
		padding: 7px;
	}

	.search-icon {
		// top:8px;
		left:5px;
		path {
			fill:$grey;
		}
	}

	.close-icon {
		top:12px;
		float:right;
		line {
			stroke:$grey;
		}
	}

	form {
		display: inline-block;
		width:85%;

		hr {
			border:none;
			height:1px;
			background-color:$light-gray;
			position:absolute;
			top:47px;//26px;
			left:0;
			right:0;
			margin:0;
		}

		input {
			color:#000000;
			background:none;
			border:none;
			line-height: 1;//34px;
			text-transform: uppercase;
			width:100%;
			outline: 0;

			&:focus {
			    outline: 0;
			}
			&.tt-input {
				vertical-align: middle!important;
			}
			&.tt-hint {
				color:$grey;
			}
			&::-ms-clear {
				display: none;
			}
		}

		.twitter-typeahead
		{
			width:100%;
			.tt-menu {
				color:$grey;
				width:100%;
				left:0;
				right:0;
				position:relative !important;
				margin-top:20px;
				margin-bottom:12px;

				.tt-suggestion, .tt-suggestion a, .tt-hint {
					color:$grey;
				}

				.tt-highlight {
					color:$black;
				}
			}
		}
	}

	.results {
		color:#000000;
		// margin-left:-7px;
		// margin-right:-8px;
		// margin-top: 6px;
		overflow-x: hidden;
		overflow-y: auto;
		max-width: 410px;
		border: 1px solid #ededed;

		&.show {
			// padding-top:25px;
		}

		.result {
			width:100%;
			// height: 100px;
			max-width: 410px;
			top:0;
			bottom:0;
			left:1px;
			right:0;
    		padding: 10px 0px 10px 0px;
			position: relative;
			border-bottom: 1px solid #ededed;
		    &:hover {
			 cursor: pointer;
		    }

			p {
				margin: -8px 0 0 0;
			}

			&:hover {
				background-color:$light-gray;
				.right p {
					opacity: 1;
				}
			}

			.left {
				width:95px;
				display: inline-block;
				margin-left: 32px;
				margin-top: 3px;

				img {
					max-width: 95px;//100%;
					max-height: 95px;
					height:auto;
				}
			}

			.right {
				display: inline-block;
				vertical-align: middle;
				width: 249px;
				//width:75%;
				padding-left: 20px;

				.bundle {
					font-family: "Brown-Pro-Bold";
					@include points("font-size", 10);
					background-color: #ff7075;
					color: $white;
					text-transform: uppercase;
					line-height: 10px;
					padding: 3px 5px;
					margin-bottom: 5px;
					display: inline-block;
					@media(max-width: $screen-sm-max) {
					    width: 52px;
					    margin: 0 auto;
					    left: 0;
					    right: 0;
					}
				}

				.kirsche {
					background-color: #f93248;
				}
				.neo {
					color: $black;
					background-color: #ff7075;
				}
				.electrico {
					color: $black;
					background-color: #dbfd01;
				}
				.ciano {
					color: $black;
					background-color: #00ead0;
				}
				.blue {
					color: $black;
					background-color: #1194f6;
				}
				.lila {
					background-color: #a401ff;
				}
				.khole {
					background-color: #252a2c;
				}


				.product-name {
					@include points("font-size", 15);//font-size:15px;
					font-weight: 500;
					line-height: 20px;
					margin:0;
					letter-spacing: normal;
					text-transform: none;
				}

				p {
					color:$gray;
					@include points("font-size", 15);//font-size:15px;
					line-height: 20px;
					opacity: 0;
				}

				a {
					text-transform: uppercase;
					@include points("font-size", 11);//font-size:11px;
					display: inline-block;
					margin-top:10px;
					color:$blue;
				}
			}

		}

		.see-all {
			border-top:1px solid $light-gray;
			padding:20px 20px 13px 20px;
			text-align: center;

			a {
				text-transform:uppercase;
				color:$blue;
			}
		}
	}
}

#subnav {
	&.initial-transparency {
		background-color: transparent !important; //keep important. it overrrides default at 0 scroll
	}

	&.down {
		top:75px;
	}

	position: fixed;

	//overflow: hidden; turn this off for now

	height: 35px;
	width: 100%;
	text-transform: uppercase;
	white-space: nowrap;
	z-index: 1999;
	@include points("font-size", 14);
	margin-bottom: 20px;
	text-align: center;
	.links {
		list-style:none;
		margin:0;
		padding:0;

		> li {
			@include styled-global;
			display: inline-block;
			position:relative;
			vertical-align: top;
			z-index: 1;

			> a {

				@media(min-width: $screen-lg-min) {
					opacity: .9;
					&:hover {
						opacity: 1;
						cursor: pointer;
						color: $black;
					}
				}
				color: white;
				text-decoration: none;
				padding: 10px 5px;
				margin: 10px;
			}

			&.open {
				z-index: 2;
				div.sub {
					@include transition(all 250ms ease-in-out 150ms);
					height: auto;
					// max-height:200px;
				}
			}

			// &.last li {
			// 	@media(max-device-width : 1024px) {
			// 		white-space: normal;
			// 		min-width: 130px;
			// 	}
			// }

			li {
				width: 100%;
				display: block;
				text-align: left;
				margin: 8px 0;
				white-space: normal;
				word-wrap: break-word;
				// min-width: 130px;
				// max-width: 200px;
			}
		}



		div.sub {
			display: block;
			position:absolute;
			left:0;
			top:100%;
			overflow: hidden;
			margin-top:7px;
			height:0;
			width: 200px;
			// max-height:0px;
			@include transition(all 250ms ease-in-out);

			ul {
				position:relative;
				list-style:none;
				margin: 0;
				padding:8px 16px;
				background-color: $dark-gray;
				@include box-shadow(0, 0, 5px, rgba(120, 120, 120, 0.35));

				li {
					@include styled-global;
					width:100%;
					display: block;
					text-align: left;
					margin: 8px 0;
					a {
						color:$white;

						&:hover {
							color: $gray;
							text-decoration: none;
						}
					}

				}
			}
		}
	}
}

.touch {
	.search-widget {
		.results {
			.result {
				max-width: 100%;
				.right p {
					opacity: 1;
				}
			}
		}
	}
}

// #global-navbar.fixfixed,
// #subnav.fixfixed {
//     position: absolute;
// }

// //override the motion on home page. this is always visible.
// body.home.scrolljacked #subnav {
// 	top: 105px !important;
// }

