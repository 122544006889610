/* global tags */
html, body {
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;

  &.scroll-lock {
  	overflow-y: hidden;
  	height:100vh;
  }

}

img {
  max-size: 100%;
}

a:focus {
	outline: none;
}

a:focus, a:hover {
	&.reverse-colors {
		color: #000;
	}
}


p, li {
      @include body-copy;
}

.reverse-colors {
	a {
		color: #fff;
		text-decoration: underline;
	}

	a:focus, a:hover {
		color: #000;
	}
	a:visited {
		color: #fff;
	}
}

/* ==ie9 message */
#ieMessage {
	overflow: visible;
	width: 100%;
	.modal-dialog {
		max-width: 600px;
	}
}



main {
	margin: 0 auto;
}

b, strong {
	font-family: "Brown-Pro-Bold";
	font-weight: normal;
}

ul, ol {
    list-style-position: inside;
    padding-left: 15px;
}

.error {
	color: $red;
}

.responsiveWrapper {
    position: relative;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    padding-top: 9%;
    height: 0;
    overflow: hidden;
	iframe {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	}
}
.fit-vid-wrapper {
	overflow: hidden;
	.fit-vid  {
	    position: relative;
	    overflow: hidden;
		iframe {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		}
	}
}

/* H* hierachy */

// header title block
.header {
	h1 {
		@media(min-width: $screen-sm-max) {
		  margin-top: 60px;
		}
	}
}

// short-header title block
.short-header {
	h1 {
		// min width: 768
		@media(min-width: $screen-sm-min) {
		  margin-top: 8px;
		  padding-left: 0;
		}
		// max width: 961
		@media(max-width: $screen-sm-max) {
		  margin-top: 0;
		}
		.hero-title {
			line-height: 66px;
			// max width: 961
			@media(max-width: $screen-sm-max) {
			  line-height: 50px;
			}

		}
	}
}

// subheader-header title block
.subheader, .sub-header {
	h1 {
		@media(min-width: $screen-sm-min) {
		  margin-top: 130px;
		}
	}
}
.subheader {
	h1 {
		margin-top: -100px;
		@media(min-width: $screen-sm-min) {
			 margin-top: -60px;
		}
	}
}

// default
h1, h2 {
	@include tracking("letter-spacing", 40);
	text-transform: uppercase;
	font-family: "Brown-Pro-Bold";
    width: 100%;
    margin: 0 auto;
	line-height: normal;
}


h1 {
	// controls max-width: 991px
	position: relative;
	margin-top: 25px;
	white-space:normal;
	&.noheaderTitle {
		margin-top: 35px;
		span {
			@media(max-width: $screen-md-min) {
				font-size: 40px;
			}
			// controls min-width: 992px;
			@media (min-width: $screen-md-min) {
				font-size: 40px;
			}
		}
	}
	@media(min-width: $screen-sm-min) {
		@include headline-uppercase;

	}

	span {
		font-size: 40px;
		line-height: 50px;
		@media(min-width: $screen-sm-min) {
			font-size: 3.8vw;
		}
		// controls min-width: 992px;
		@media (min-width: $screen-md-min) {
			font-size: 4vw;
		}
		// min-width: 1260px;
		@media (min-width: $screen-lg-min) {
			font-size: 3vw;
		}
		// min-width: 1600px;
		@media (min-width: 1600px) {
			font-size: 65px;
		}

		&.long  {
			font-size: 23px;
			line-height: 0;
			@media(min-width: $screen-sm-min) {
				font-size: 3.8vw;
			}
			// controls min-width: 992px;
			@media (min-width: $screen-md-min) {
				font-size: 3.1vw;
			}
			// min-width: 1260px;
			@media (min-width: $screen-lg-min) {
				font-size: 2.5vw;
			}
			// min-width: 1600px;
			@media (min-width: 1600px) {
				font-size: 40px;
			}

		}

		&.longWord  {
			font-size: 33px;
			line-height: 0;
			// controls min-width: 992px;
			@media (min-width: $screen-md-min) {
				font-size: 4vw;
			}
			// min-width: 1260px;
			@media (min-width: $screen-lg-min) {
				font-size: 3vw;
			}
			// min-width: 1600px;
			@media (min-width: 1600px) {
				font-size: 65px;
			}

		}

	}
}

h2 {
	font-size: 34px;
	// min-width: 1260px;
	@media (min-width: $screen-sm-min) and (min-width: $screen-lg-min) {
		font-size: 2.6vw;
	}
	// min-width: 1600px;
	@media (min-width: 1600px) {
		font-size: 34px;
	}
}




h3 {
	line-height: 1.2;
	font-size: 28px;
	// min-width: 1260px;
	@media (min-width: $screen-sm-min) and (min-width: $screen-lg-min) {
		font-size: 2vw;
	}
	// min-width: 1600px;
	@media (min-width: 1600px) {
		font-size: 28px;
	}
}

h4 {
	line-height: 1.4;
	font-size: 22px;
	// min-width: 1260px;
	@media (min-width: $screen-sm-min) and (min-width: $screen-lg-min) {
		font-size: 1.8vw;
	}
	// min-width: 1600px;
	@media (min-width: 1600px) {
		font-size: 22px;
	}
}



h5 {
	font-size: 20px;
	// min-width: 1260px;
	@media (min-width: $screen-sm-min) and (min-width: $screen-lg-min) {
		font-size: 1.6vw;
	}
	// min-width: 1600px;
	@media (min-width: 1600px) {
		font-size: 20px;
	}
}


/* text variations */
h6, .h6 {
	font-size: 18px;
	// min-width: 1260px;
	@media (min-width: $screen-sm-min) and (min-width: $screen-lg-min) {
		font-size: 1.35vw;
	}
	// min-width: 1600px;
	@media (min-width: 1600px) {
		font-size: 18px;
	}

	&.long  {
		@media (max-width: 990px) {
			padding-top: 0;
			top: 0;
		}
		@media (max-width: 480px) {
		}
	}
	span.hero-title  {
		&.long  {
			@media (max-width: 990px) {
				padding-top: 0;
				top: 0;
			}
			@media (max-width: 480px) {
			}
		}
	}
}
/*
h1, h2, h3, h4, h5, h6 {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-o-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
*/


/* section specific overrides */
body.article {
	.col-md-12.col-sm-12.col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}
}


/* element spacing */
.paddedT {
	margin-top: 20px;
}
.paddedB {
	margin-bottom: 20px;
}
.paddedR {
	margin-right: 10px;
}
.paddedL {
	margin-left: 10px;
}
.paddedTops {
	padding-top: 20px;
	padding-bottom: 20px;
}
.paddedSides {
	padding-left: 10px;
	padding-right: 10px;
}

.float-none {
  float: none !important;
}
.no-scroll {
  @media (max-width: $screen-sm-min){
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

.forceWrap {
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-o-hyphens: auto;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
.clear-all {
	clear: both;
}



/* column structure */
.firstCol {
	margin-left: 0 !important;
	padding-left: 0 !important;
	padding-right: 30px;
	@media (max-width: $screen-sm-min){
		padding-right: 10px;
	}
}
.secondCol {
	margin-right: 0 !important;
	&.sidenav{
		background-color: $gray;
		-webkit-transition: background 0.5s linear;
        -moz-transition: background 0.5s linear;
        -ms-transition: background 0.5s linear;
        -o-transition: background 0.5s linear;
        transition: background 0.5s linear;
		a {
			@include points("font-size", 13);
			color: $white;
			text-transform: uppercase;
			position: relative;
			overflow: hidden;
			display: block;
			transition: all 0.5s linear;
			padding-left: 10px;
			margin-bottom: 9px;
		}
	    &a:hover {background-color: #888888; }
		.separator {
		  border-bottom: 1px solid rgba(120, 120, 120, 1);
		}
	}
}


/* global colors */
.hidden { display: none;}
.pink-bg { background-color: $pink; } //neo
.yellow-green-bg { background-color: $yellow-green; } //electrico
.purple-bg { background-color: $purple; } // lila
.blue-bg { background-color: $blue; } //
.red-bg { background-color: $red; } //kirsche
.light-blue-bg { background-color: $light-blue; } //azzurro
.teal-bg{background: $teal;} //ciano
.gray-bg{background: $gray;}
.medium-gray-bg{background: $medium-gray;}
//.medium-blue-bg { background-color: $medium-blue; }
//.gaming-gray-bg{background: $gaming-gray ;}



/*
.force-white-text * { color: $white !important;}
.force-dark-text * { color: $dark-gray !important;}
*/


/* main page elements */
.content.container {
	padding-bottom: 30px;
	li { margin: 0 0 10px; }
	li:last-child { margin-bottom: 20px; }

}

body.global-elements {
  .content {
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 500px;
  }
}

.separator,
.dotted_separator {
  clear: both;
  margin: 10px 0;
  padding: 0;
  line-height: 1px;
  height: 1px;
}

.separator {
  border-bottom: 1px solid #d1d1d1;
}


.hero-action {
	@include points("font-size", 14);
	padding-top: 0;
	@media(min-width: $screen-sm-min) {
		padding-top: 38px;
	}
	@media(max-width: $screen-sm-min) {
		padding-top: 0;
	}
	a {
	  float: right;
	  color: $white;
	  text-decoration: none;
		&:hover > .svg-rarr{
			transform: translateX(6px);
		}
		@media(max-width: $screen-sm-min) {
			float: left;
		}
	}

	.svg-rarr {
		float: right;
		margin: 2px 0 0 5px;
		transition: all .1s ease-in;
		@media(max-width: $screen-sm-min) {
			float: none;
			display: inline-block;
		}
	}
}


.grouping {
  margin-top: 0;
  margin-bottom: 40px;

  a, span {
	  display: table;
	}

}

.row {
	margin-left: 0;
	margin-right: 0;
	clear: both;
	&.paddedT {
		@media (max-width: 1024px) {
			padding: 0;
		}

	}
}

.row + .row {
	margin-top: 3%;
}

//this allows gutterless columns and rows where required
//using this for the category navigation fluid container
.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child),

.container.no-gutter, .no-gutter {
  padding-right: 0;
  padding-left: 0;
}


/* global checkbox styles */
input[type="checkbox"] {
  vertical-align:middle;
  margin: 0 0 0;
}

input[type="checkbox"]+label {
  cursor:pointer;
}

input[type="checkbox"]+label:before {
  content:"";
  display:inline-block;
  height:20px;
  margin: -3px 10px 0 -22px;
  vertical-align:middle;
  width:20px;
  cursor: pointer;
}

input[type="checkbox"] + label:before {
  background: $sprite-sheet-checkbox left top no-repeat;
}

input[type="checkbox"]:checked + label:before {
  background: $sprite-sheet-checkbox -20px top no-repeat;
}

//IE8 Browser Upgrade notification
.browserupgrade {
  position: fixed;
  text-align:center;
  width:100%;
  border-bottom:2px solid $dark-gray;
  top:0;
  background: $white;
  z-index: 3000;
  display: none;

  &.upgrade {
      display: block;
  }
}
