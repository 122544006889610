//Style for the learn more type buttons.
.btn {
	@include label-small-uppercase;
    @include btn-util;
    line-height: 18px;
    //height: 50px;
    float: left;
	width: 160px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    white-space: normal;

    transition: transform 150ms;
    &.loading {
      transform: rotate(180deg);
    }
    &:hover {
	    color: $gray;
    }


    @media (min-width: $screen-md-min) {
      display: inline-block;
	   &.left {
		    float: left;
		    margin: 3px 20px 3px 0; // 3px aligns with invalid-sku glow on menus
	    }
	    &.right {
		    float: right;
		    margin: 3px 0 3px 20px; // 3px aligns with invalid-sku glow on menus
	    }
      width: auto;
    }

    &[href="#"] {
	  //color: $white;
	  //background-color: $grey;
	  border: none;
	  padding: 17px 10px;
	  text-align: center;
      line-height: 18px;
      height: 50px;
      width: 160px;
	  margin: 15px 20px 15px 0; // 3px aligns with invalid-sku glow on menus
      @media (min-width: $screen-md-min) {
	   &.left {
		    float: left;
	    }
	    &.right {
		    float: right;
	    }
      }
      @media (max-width: $screen-md-min) {
		margin:	7px 20px 7px 0;
      }
    }
}
.learn-more, .watch-vid, .cta {
	@include label-small-uppercase;
	@include btn-util;
	margin-top: 10px;

	&.medium-gray-bg{
		color: $white;
	}
	&.blue {
	    background: #1194f6;
	    color: #fff;
	}

	&:hover {
		color:inherit;
		text-decoration: none;
	}
}



