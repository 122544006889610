	#circle-get-to-know {
		padding: 0;
		position: relative;
		background-color: $light-gray;
		height: auto;
		
		.activity-light {
				background: url(http://www.logitech.com/assets/64228/12/circle-pdp-2016.jpg) bottom no-repeat;
				background-size: cover;
			}
			.portable-rech {
				background: url(http://www.logitech.com/assets/64228/16/circle-pdp-2016.jpg) center no-repeat;
				background-size: cover;
			}
			.speakers {
				background: url(http://www.logitech.com/assets/64228/18/circle-pdp-2016.jpg) center no-repeat;
				background-size: cover;
			}
			.wall-mount {
				background: url(http://www.logitech.com/assets/64228/20/circle-pdp-2016.jpg) no-repeat;
				background-size: cover;
			}
			.storage-opt {
				background: url(http://www.logitech.com/assets/64228/14/circle-pdp-2016.jpg) no-repeat;
				background-size: cover;
			}
			.mobile-app {
				background: url(http://www.logitech.com/assets/64228/22/circle-pdp-2016.jpg) no-repeat;
				background-size: cover;
			}
			.night-vision {
				background: url(http://www.logitech.com/assets/64228/26/circle-pdp-2016.jpg) bottom no-repeat;
				background-size: cover;
			}
			.owl-carousel .item-video{height:300px}
			
			
		.desktop-carousel {
			z-index: 1;
			position: relative;

			h2 {
				color: black;
			    text-align: left;
	   			width: 400px;
			    margin: 0;
			    padding: 20px 20px 20px 40px;
				
			   /*  @media (min-width: 1600px) {
			    	top: 150px !important;
			    }
			    @media (min-width: 1440px) {
			    	top: 100px;
			    }
			    @media (max-width: 1400px) {
			    	top: 100px;
			    }
			   @media (max-width: 1300px) {
			    	top: 50px;
			    } */
			}
			.info {
					text-align: left;
					height: auto;
					position: relative;
					span {
						display:inline-block;
						width:$pillRadius;
						height:$pillRadius;
						margin: 23px 0 10px 0;
						vertical-align: top;
						cursor:pointer;
						-moz-border-radius:  50%;
						border-radius:  50%;
						z-index: 2;
						position: relative;
						background-color:$pillBgColor;					
						
					}
					a {
						width: 350px;
						display: inline-block;
						padding-left: 20px;
						color: black;
						
						h3, p {
							text-align: left;
						}
						h3 {
							font-size: 20px;
							letter-spacing: -1px;
							text-transform: uppercase;
    						font-family: 'Brown-Pro-Bold';
    						margin-bottom: 5px;
						}
						p {
							font-family: "Helvetica", Arial, sans-serif;
							font-size: 14px;
							line-height: 16px;
						}
					}
					
					&:after {
						content: " ";
						border-left: 1px solid $pillBgColor;
						height: 100%;
						position: absolute;
						z-index: 1;
						left: $pillRadius / 2;
						top: 0; /* margin top 10px + pill radius 20px*/
					}
					&.checked {
						a, span {
							pointer-events: none;
						}
						span {
							background-color: $pillFgColor;						
							-webkit-transition:background-color 0.4s linear;
							-o-transition:background-color 0.4s linear;
							-moz-transition:background-color 0.4s linear;
							transition:background-color 0.4s linear;
						}
						.pill-wrap:hover span{
							background-color: $pillFgColor;	
						}
					}
					.pill-wrap{
						display: inline-block;
						&:hover {
							span {
								background-color: #80ECE0;						
							}
						}	
					}
					
					
					@media (min-width:992px) and (max-height:900px) {
						width: 500px;
						padding-bottom: 15px;
						&:first-of-type {
							padding-top: 15px;
						}
						span {
							margin: 0;
						}
						a {
							width: 450px; 
							h3 {
								margin: 0;
								padding: 0 0 7px 0;
							}
							p {
								padding: 0 0 10px 0;
								line-height: 20px;
							}
						}
						
					}				
					
				}
									
				.pill-1 {
					 &:before {
						content: " ";
						border-left: 1px solid $pillBgColor;
						height: 100%;
						position: absolute;
						z-index: 1;
						left: $pillRadius / 2;
						top: -100%;
					}
				}
		}

		
		.background {
			position: absolute;
			width: 100%;
			height: 100%;
			display: none;
			.gradient {
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 1;
				top: 0;
				background: url(http://www.logitech.com/assets/64228/5/circle-pdp-2016.png) left top no-repeat;
				background-size: 45% 100%;

			}
			div {
				width: 100%;
				height: 100%;
				overflow: hidden;
				display: block;
				transition: opacity 1s ease-in-out;
				position: absolute;
				opacity: 0;
				top:0;
				&.visible{		
					
					opacity: 1;
					height: 100%;
					position: relative
				}
				
			}
			
			.activity-light.default {
				opacity: 1;
				height: 100%;
				position: relative;
			}
			
			@media (min-width:992px) {
				display: block;
			}
		}
		
		.foreground {
			position: relative;
			top: 0;
		}
		
		.container {
			height: 100%;
			position: relative;
			left: 0;
   			right: 0;
   			top: 0;
   			@media (min-width: 1440px) {
   				padding: 100px 0;
   				min-height: 800px;
   			}
   			@media (min-width: 992px) {
   				padding: 100px 0;
   			}
   			
		}
		
		.carousel {
			top: 0;
			z-index:-1;
			&.owl-carousel {
				z-index: 1;
			}
			
			.slide {
				h2 {
					color: black;
				    font-size: 48px;
				    font-family: "Brown-Pro-Bold";
				    z-index: 2;
				    text-align: left;
				    width: 400px;
				    margin: 0;
				    padding: 10px 20px 20px;
				    top: 50px;
				    @media (max-width:560px){
				    	font-size: 28px;
				    	width: 250px;
				    	padding: 10px 5px; 
				    	top: 20px;
				    }
				}
				img {
					top: 0;
					left:0;
					transform: initial;	
					display:none;					
				}
			}
		}
	}
	
	@media (max-width: 767px) {
		#circle-get-to-know {
			h2 {
				font-size: 2.5em !important;
				text-align: center !important;
				left:0;
				right:0;
				margin:auto !important;
				line-height: 34px;

			}
			.container .slide div.info {
				position: absolute;
				top: auto !important;
				bottom: 66px !important;
			}
			.owl-dots {
				position:absolute;
				left:0;
				right:0;
				bottom: 30px;
			}
		}
		
	
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:landscape) {
		.activity-light {
			background: url(http://www.logitech.com/assets/64228/12/circle-pdp-2016.jpg) bottom no-repeat;
			background-size: cover;
		}
		.portable-rech {
			background: url(http://www.logitech.com/assets/64228/16/circle-pdp-2016.jpg) center center no-repeat;
			background-size: cover;
		}
		.speakers {
			background: url(http://www.logitech.com/assets/64228/18/circle-pdp-2016.jpg) center center no-repeat;
			background-size: cover;
		}
		.wall-mount {
			background: url(http://www.logitech.com/assets/64228/20/circle-pdp-2016.jpg) left center no-repeat;
			background-size: cover;
		}
		.storage-opt {
			background: url(http://www.logitech.com/assets/64228/14/circle-pdp-2016.jpg) center center no-repeat;
			background-size: cover;
		}
		.mobile-app {
			background: url(http://www.logitech.com/assets/64228/22/circle-pdp-2016.jpg) center center no-repeat;
			background-size: cover;
		}
	}	
	
			
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:portrait){
		#circle-get-to-know {
			.carousel .owl-item {
				&:nth-of-type(1) .slide .crop-wrap img{
					content: url(http://www.logitech.com/assets/64228/29/circle-pdp-2016.jpg);
				}
				&:nth-of-type(2) .slide .crop-wrap img{
					content: url(http://www.logitech.com/assets/64228/30/circle-pdp-2016.jpg);
				}
				&:nth-of-type(3) .slide .crop-wrap img{
					content: url(http://www.logitech.com/assets/64228/31/circle-pdp-2016.jpg);
				}
				&:nth-of-type(4) .slide .crop-wrap img{
					content: url(http://www.logitech.com/assets/64228/32/circle-pdp-2016.jpg);
				}
				&:nth-of-type(5) .slide .crop-wrap img{
					content: url(http://www.logitech.com/assets/64228/33/circle-pdp-2016.jpg);
				}
			} 
				
			.carousel .slide h2 {
				left:0;
				right:0;
				margin:auto !important;
				width: 100%;
				text-align: center;

			}

			.container .slide {
				 div.info {
					position: absolute;
					top: auto !important;
					bottom: 7% !important;
				}
				img {
					height: auto !important;
				}
			}
			.owl-dots {
				position:absolute;
				left:0;
				right:0;
				bottom: 5%;
			}
				.crop-wrap {
					width: 768px;
				    height: 948px;
				    overflow: hidden;
				}
		}
		
	}