.product-features-legacy {
  height: auto;
  padding:20px 10px 0;
  .headline {
    margin-bottom: 39px;
    text-align:center;
    z-index: 1;
    @media (min-width: $screen-md-min) {
      margin-bottom: 63px;
    }
  }
  .headline,
  .features-list {
    position: relative;
    z-index: 10;
  }
  .features-list {
    padding-bottom: 40px;
  }
  .feature-title {
    font-size: 1.5em;
  }
  .feature-description {
    margin-bottom: 20px;
  }
  .more-content {
    display:block;
    margin-top: 20px;
    font-family: "Brown-Pro-Bold", Helvetica, sans-serif;
    font-size: 0.75em;
    text-transform: uppercase;
    color: $blue;
    @media (max-width: $screen-md-min) {
      margin-left: 24px;
    }
  }
  .splash-image {
    img {
      display: block;
      margin: 0 auto;
      width: 90%;
      height: auto;
      max-width: 500px;
      z-index:1;
    }
    @media (min-width: $screen-md-min) {
      position: absolute;
      right: 30px;
      top: 220px;
      //right: 0;
      //top: 190px;
      margin-right: 3%;
      img {
        width: 100%;
      }
    }
    @media (min-width: $screen-lg-min) {
      margin-right: 6.85483871%;
    }
  }
  @media (min-width: $screen-md-min) {
    padding: 65px 10px 50px;
    &.initialized {
      .feature-title,
      .feature-content {
       // width: 35%;
      }
      .more-content {
        display: none;
      }
      .features-list-item.active {
        .more-content {
          display: block;
        }
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    margin: 50px auto;
    .features-list-item {
      margin-bottom: 34px;
    }
  }
}

.product-features-legacy-container {
  .container {
    padding: 0;
    @media (min-width: $screen-md-min) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
