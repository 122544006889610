header {
  .header {
  	width: 100%;
  	min-height: 230px;
  	color: white;
  	display: block;
    position: relative;
    height: 230px;
    @media(min-width: $screen-sm-min) { 
      height: 500px;
    }

    &:hover #inner{
      @media(min-width: $screen-sm-min) { 
         background-position: 78% 100%;
      }
    }

    #inner {
      height: 230px;
      background-repeat: no-repeat;
      @media(max-width: $screen-xs-max) {
        background-size: 75%;
        background-position: bottom right;
      }
      @media(min-width: $screen-sm-min) { 
        height: 500px;
        background-position: 82% 104%;
        transition: background-position 0.5s cubic-bezier(0.02, 0.36, 0.52, 0.99);
      }
      
      h1 {
        position: relative;
        top: 50px;
        @media(min-width: $screen-sm-min) { 
          top: 160px;
        }
        .prefix {
          font-size: 2rem;
          display: block;
          text-transform: none;

          @media(min-width: $screen-lg-min) {
            @include points("font-size", 14);
          }
          font-family: "Brown-Pro-Light";
        }
        .h1-main {
          font-size: 4rem;
          display: block;
          @media(min-width: $screen-lg-min) {
            @include points("font-size", 44);
          }
        }
      }
    }
  }
}
