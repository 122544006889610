$pillBgColor: #b2b0b4;
$pillFgColor: #00ead0;
$Orange: #CC3300;
$pillContainerHeight: 100px;
$pillRadius: 20px;
$pillNavTop : 200;

/* mixins circle-pdp*/
@mixin pill-style ($top) {	
	@for $i from 1 through 5{
		.info {
			&.pill-#{$i} {
				$res: $top;
				top: unquote($res + 'px');
				$top: $res+100; 
			}
		}
	
	}
}
/* reuse */
%circle-get-toknow {
	background-color: $teal;
	
	.icon-daily-summary-gray {
	    	width: 34px;
	    	height: 37px;
	    	background-position: -49px -5px;
	}
	.icon-download-btn-gray {
	       width: 22px;
	  	   height: 27px;
	       background-position: -93px -42px;
	}
	.icon-hd-gray {
	   	 width: 28px;
	   	 height: 30px;
	     background-position: -43px -79px;
	}
	
	.icon-night-vision-gray {
	   		width: 24px;
	    	height: 24px;
	    	background-position: -125px -5px;
	}

	.icon-smart-filter-gray {
	    	width: 32px;
	    	height: 40px;
	    	background-position: -115px -89px;
	}
	.icon-two-way-gray {
	    	width: 30px;
	    	height: 26px;
	    	background-position: -45px -139px;
	}
}
body.circle-pdp.circle-landing{
	
	#global-navbar, #subnav {
		background-color: transparent;
		@media (max-width: 767px) {
			background-color: #814efa;
		}
	}
	.navbar a.buy-now {
		color: #dcfd00;
		&:hover{
			padding: 14px 15px;
			background-color: #252a2c;
			color: white;
		}
		&:focus{
			background-color: #252a2c;
		}
		@media (max-width: 767px){
			font-size: 12px;
			height: 30px;
			padding: 6px 10px;
			&:hover{
				padding: 6px 10px;
				color: #dcfd00;
			}

		}
	}
	.desktop-only {
		display: block;
	}
	.mobile-only {
		display: none;
	}
	.top-panel {
		overflow:visible;
		height: 100%;
		@media (max-width: 767px){
				z-index: 1000;
		}
		&.lock-nav-bar {
			#circle-phone {
				padding-top: 0;
				height:100%;
				@media (max-width: 767px){
					height: auto;
				}
			}
		}
	}
	.tech-specs, #downloads, .product-support {
		background-color: white;
		margin: 0 !important;
	}
	.tech-specs,#downloads {
		padding-left: 0;
		padding-right: 0;
		
	}
	 .product-support {
	 	padding-bottom: 40px;
	 }
	#downloads {
		padding-top: 10px;
	}
	.tech-specs {
		padding-top: 40px;
		padding-bottom: 20px;
		&.section-faqs {
			padding-top: 20px;
			padding-bottom: 40px;
			.spec-label {
				margin: 0;
			}
			.details-description {
				margin-left: 0;
			}
		}
	}
	//header fonts
	#circle-shelf .container div.content h1,
	#circle-phone h2,
	#circle-get-the-app .container h2,
	#circle-get-to-know .desktop-carousel h2,
	#product-hero h2 {
		font-size: 3em;
		line-height: 44px;
		letter-spacing: -2px;
		font-family: "Brown-Pro-Bold";
		margin-bottom: 11px;
	}
	
	//First module
	@import 'v2/circle-hero';

	//Module 2
	/* video section sub nav*/
	@import 'v2/circle-via-phone';
	
	//Module 3
	@import 'v2/circle-get-the-app';
	//Module 4
	@import 'v2/circle-get-to-know';

@media (max-width: 991px) {
	#circle-shelf {
		div.watch-video {
			a.action + a.action{
				position: relative;
				right: 0;
			
			}			
		}
	}
	#circle-get-to-know {
		.col-md-12 > h2 {
			display: none;
		}
		h2 {
			position: absolute;
			padding-left: 5px;
		}
		.background {
			img {
				display: none;
			}
		}
		.container {
			position: relative;
			min-height: auto;
			padding: 0;
			
			.col-md-12 {
				padding: 0;
			}
			.slide {
				h2 {
					display: block;
				}
				img {
					width:100%;
					height: 100%;
					display: block;
				}			
				
				 div.info {
					text-align: center;
					margin-left: auto;
					margin-right: auto;
					p {
						padding: 5px;
					}
					h3, p {
						text-align: center;
					}		
				 	
				 	&:before,&:after, span {
				 		display: none;
				 	}
				 }
			}
		}
	}
	
	#circle-phone {
		 div.phone-video div.video-wrapper {
		    width: 69%;
		    height: auto;
		    top: 50%; 
		    video {
		    	width: 100%;
		    	height: 100%;
		    }
		}
	}
}

@media (max-width: 767px) {
	#circle-shelf .container div.content h1,
	#circle-phone h2,
	#circle-get-the-app .container h2,
	#circle-get-to-know .desktop-carousel h2,
	#product-hero h2 {
		font-size: 2.5em;
		line-height: 34px;
		letter-spacing: -2px;
		font-family: "Brown-Pro-Bold";
		margin-bottom: 11px;
	}
	.desktop-only {
		display: none;
	}
	.mobile-only {
		display: block;
	}
	p {
		line-height: 18px;
	}
	.top-panel {
		margin-top: 0;
		&.lock-nav-bar {
			padding-top:0;
		}
		.navbar {
			display: none;
		}
	}
	


	#circle-get-to-know {
		h2 {
			padding-left: 10px;
		}
	}
}
//ipad
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:portrait){
	#circle-shelf {
		div.background {
		
			div.center {
				background: url(/images/circle/v2/mod1-back.jpg) no-repeat;
				background-size: cover;
				    background-position: 34% 0;
			}
		}
	}

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:landscape){
		#circle-shelf {
			div.background div.center {
				    background: url(/images/circle/v2/mod1-back.jpg) center bottom no-repeat;
					   background-position: 45% 20%
			}
		}

		#circle-get-to-know {
			min-height: 700px;
			background-color: #EBE7EC;
			.container {
				padding: 100px 15px;
			}
			.desktop-carousel .info a {
				width: 400px;
			}
			.desktop-carousel .info:first-of-type {
				 padding-top: 15px;
			}
			h2 {
				top: 20px;
			}
		}
}
//android
@media only screen and (min-width: 375px) and (max-height: 667px) and (orientation: landscape) {
	#circle-shelf {
		h1 {
			font-size: 36px;
			letter-spacing: -1px;
		}
	}
}
//ipad 3
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
		#circle-shelf {
		div.content {
			top: 75%;
		}
	}
}
// laptop
@media only screen and (min-width: 992px) and (max-height: 692px) {
	 #circle-shelf h1 {
	 	font-size: 50px;
	 }
	 #circle-phone{
		min-height: 692px;
		 div.phone-video {
		 	margin-bottom: 10px;
		 }
	 }
}
// mobile maxwidth 400px
@media (max-width: 400px) {
	
	body.circle-landing #circle-phone div.phone-video div.video-wrapper {
		width: 233px;
    	height: 131px;
	}
}
	//iphone 5
	@media only screen and (min-device-width: 320px) 
	and (max-device-height: 568px) 
	and (orientation: portrait) 
	and (-webkit-device-pixel-ratio: 2) {
	
		#circle-shelf .container div.content h1,
		#circle-phone h2,
		#circle-get-the-app .container h2,
		#circle-get-to-know .desktop-carousel h2,
		#product-hero h2 {
			font-size: 2.4em;
			line-height: 30px;
			letter-spacing: -2px;
			font-family: "Brown-Pro-Bold";
			margin-bottom: 11px;
		}
	
		#circle-shelf {
			min-height: auto;
			.container div.content {
			h1{
				font-size: 2.4em;
				letter-spacing: -1px;
			}
			a.action, .buy-now {
				top: 0;
				padding:0;
			}
			
			div.watch-video {
				a.action {
					span {
						margin: 0;
					}
					&+a.action {
						span + span {
							padding-left: 5px;
						}
					}
				}
			}

		}
		}
				
		div.phone-video div.video-wrapper {
			  width: 75%;
			  height: auto;
		}
		#circle-get-to-know .carousel .slide h2 {
			   width: 280px;
			   top: 10px;
		}
	}
	@media only screen and (min-device-width: 320px) 
	and (max-device-height: 568px) 
	and (orientation: landscape) 
	and (-webkit-device-pixel-ratio: 2) {
		#circle-shelf .container div.content {
			a.action, .buy-now {
				top: 0;
				padding: 0;
			}

		}
		#circle-get-to-know .container .slide h2 {
			font-size: 32px;
			width: 300px;
		}
	}

	@import 'v2/circle-owl-video';
}